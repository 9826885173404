import React from 'react';
import Layouts from './Layouts';
import Detail from '../components/buduk/Detail';

const DetailRombelBuduk = ({ url }) => {
	return (
		<div>
			<Layouts url={url}>
				<Detail url={url} />
			</Layouts>
		</div>
	);
};

export default DetailRombelBuduk;
