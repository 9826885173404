import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

const Welcome = ({ url }) => {
  const [user, setUser] = useState("");
  const [transaksi, setTransaksi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const URL = url;

  const fetchUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(URL + "me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data.user);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        console.log(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTransaksi = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        URL + "trx",
        {
          nsm: localStorage.getItem("nsm"),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setTransaksi(response.data.data);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        console.log(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
    fetchTransaksi();
  }, []);

  const getPackageName = (grossAmount) => {
    switch (grossAmount) {
      case 150000:
        return "Professional";
      case 75000:
        return "Premium";
      case 50000:
        return "Standart";
      default:
        return "Unknown";
    }
  };
  function formatDateTime(dateTime) {
    return new Intl.DateTimeFormat("id-ID", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(new Date(dateTime));
  }

  function formatRupiah(amount) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(amount);
  }

  useEffect(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div
                className="card-body"
                style={{
                  backgroundImage: "url(/assets/img/landing.jpg)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <h5 className="card-title">
                  Selamat Datang, <br />
                  <h2>
                    <b> {isLoading ? "Loading..." : user && user.name}!</b>
                  </h2>
                </h5>
                <p className="card-text"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4 col-sm-6 col-12 d-flex">
          <div className="card bg-comman w-100">
            <div className="card-body">
              <div className="db-widgets d-flex justify-content-between align-items-center">
                <div className="db-info">
                  <h6>Students</h6>
                  <h3>NaN</h3>
                </div>
                <div className="db-icon">
                  <img
                    src="assets/img/icons/dash-icon-01.svg"
                    alt="Dashboard Icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-6 col-12 d-flex">
          <div className="card bg-comman w-100">
            <div className="card-body">
              <div className="db-widgets d-flex justify-content-between align-items-center">
                <div className="db-info">
                  <h6>Awards</h6>
                  <h3>NaN</h3>
                </div>
                <div className="db-icon">
                  <img
                    src="assets/img/icons/dash-icon-02.svg"
                    alt="Dashboard Icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-6 col-12 d-flex">
          <div className="card bg-comman w-100">
            <div className="card-body">
              <div className="db-widgets d-flex justify-content-between align-items-center">
                <div className="db-info">
                  <h6>Department</h6>
                  <h3>NaN</h3>
                </div>
                <div className="db-icon">
                  <img
                    src="assets/img/icons/dash-icon-03.svg"
                    alt="Dashboard Icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h6 className="card-title">Riwayat Transaksi Membership</h6>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Tanggal</th>
                      <th>NSM</th>
                      <th>Paket</th>
                      <th>Jumlah</th>
                      <th>Status</th>
                      <th>Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="2">Loading...</td>
                      </tr>
                    ) : transaksi.length > 0 ? (
                      transaksi.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{formatDateTime(item.transaction_time)}</td>
                          <td>{item.customer_name}</td>
                          <td>{getPackageName(item.gross_amount)}</td>
                          <td>{formatRupiah(item.gross_amount)}</td>
                          <td>{item.transaction_status}</td>
                          <td>
                            {" "}
                            <Link
                              to={`/invoice/${item.order_id}`}
                              className="btn btn-warning btn-sm"
                            >
                              Cetak
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal show={isModalOpen} onHide={closeModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Syarat dan Ketentuan Member</Modal.Title>
          </Modal.Header>

          <Modal.Body
            style={{ textAlign: "justify", backgroundColor: "#961249" }}
          >
            <ul style={{ textAlign: "justify", color: "white" }}>
              <li>
                • Harus komitmen tidak menyebarluaskan link atau video tutorial
                kepada siapapun
              </li>
              <li>
                • Tidak menyebarluaskan sebagian tampilan dari BIMA SAKTI dalam
                bentuk screenshot/foto, lewat story WA, shared group atau media
                sosial yang lain
              </li>
              <li>
                • Tetap menjaga kerahasiaan terkait proses updating data
                menggunakan aplikasi BIMA SAKTI ini
              </li>
              <li>
                • Dengan menggunakan BIMA SAKTI, artinya Anda sanggup mematuhi
                segala aturan yang telah kami tetapkan
              </li>
              <li>
                • Apabila di kemudian hari, ditemukan adanya pelanggaran, maka
                akun Anda akan kami blacklist selamanya!
              </li>
            </ul>
          </Modal.Body>

          <Modal.Footer>
            <button onClick={closeModal} className="btn btn-primary">
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Welcome;
