import React from "react";

const Top_auth = () => {
  return (
    <div>
      <section className="wrapper dz-mode">
        <div className="menu">
          <button className="toggle-btn"></button>
          <div className="btn-grp d-flex align-items-center gap-16">
            <label
              htmlFor="mode-change"
              className="mode-change d-flex align-items-center justify-content-center"
            ></label>
          </div>
        </div>
        <div className="m-menu__overlay"></div>
      </section>
    </div>
  );
};

export default Top_auth;
