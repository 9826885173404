import React from "react";
import Layouts from "./Layouts";
import DataSiswaEdit from "../../Mobile/DataSiswaEdit";

const DataSiswa = ({ url }) => {
  return (
    <div>
      <Layouts>
        <DataSiswaEdit url={url} />
      </Layouts>
    </div>
  );
};

export default DataSiswa;
