import React, { useEffect, useState } from "react";
import Auth from "../templatesMobile/Auth";

const LoginMobile = ({ url }) => {
  const [buttonContent, setButtonContent] = useState("");

  return (
    <div>
      <Auth url={url} />
    </div>
  );
};

export default LoginMobile;
