import React, { useState } from "react";
import DataTable from "react-data-table-component";

const HasilAlumni = ({ datanisnemis }) => {
  const [search, setSearch] = useState("");
  const dataArray = Array.isArray(datanisnemis?.siswas)
    ? datanisnemis.siswas
    : [];

  // Filter data berdasarkan nilai pencarian
  const filteredData = dataArray.filter((item) => {
    const fullName = item.full_name ? item.full_name.toLowerCase() : "";
    const ay = item.ay ? item.ay.toLowerCase() : "";
    const pusdatin = item.pusdatin ? item.pusdatin.toLowerCase() : "";

    return (
      fullName.includes(search.toLowerCase()) ||
      ay.includes(search.toLowerCase()) ||
      pusdatin.includes(search.toLowerCase())
    );
  });

  // console.log("jalan " + datanisnemis.lembaga && datanisnemis.lembaga);

  const columns = [
    {
      name: "NISN",
      selector: (row) => row.nisn,
      sortable: true,
      minWidth: "80px",
    },
    {
      name: "NAMA",
      selector: (row) => row.full_name,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "TEMPAT LAHIR",
      selector: (row) => row.birth_place,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "TANGGAL LAHIR",
      selector: (row) => row.birth_date,
      sortable: true,
      minWidth: "80px",
    },

    {
      name: "THN PELAJARAN",
      selector: (row) => row.ay,
      sortable: true,
      minWidth: "100px",
    },

    {
      name: "PUSDATIN",
      selector: (row) => row.pusdatin,
      sortable: true,
      minWidth: "200px",
    },
  ];

  return (
    <div className="card-body">
      {datanisnemis.lembaga && datanisnemis.lembaga && (
        <div
          className="card-header"
          style={{
            background: "linear-gradient(to right, #6ccc1d, #87ff25)", // Add gradient background
            textAlign: "center", // Center text
          }}
        >
          <h5 style={{ textAlign: "center" }}>Data Alumni 2 Tahun Terakhir</h5>
          <h5 style={{ textAlign: "center" }}>
            {datanisnemis.lembaga.nsm && datanisnemis.lembaga.nsm} -{" "}
            {datanisnemis.lembaga.lembaga && datanisnemis.lembaga.lembaga}
          </h5>
          <p style={{ textAlign: "center" }}>
            {datanisnemis.lembaga.alamat && datanisnemis.lembaga.alamat}
          </p>
        </div>
      )}
      {datanisnemis.siswas && datanisnemis.siswas && (
        <div className="table-responsive table-striped">
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            paginationPerPage={10}
            subHeader
            subHeaderComponent={
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="form-control"
                  style={{ width: "200px" }} // Menyesuaikan ukuran input
                />
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

export default HasilAlumni;
