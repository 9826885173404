import React, { useState, useEffect } from "react";
import Head from "./t_Mobile/Head";
import Top_auth from "./t_Mobile/Top_auth";
import Foot from "./t_Mobile/Foot";
import axios from "axios";
import Swal from "sweetalert2";
import { Eye, EyeOff } from "react-feather";
import { useNavigate } from "react-router-dom";

const Auth = ({ url }) => {
  const URL = url;
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [dataSiswa, setDataSiswa] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const Login = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${URL}login_siswa`, {
        username: username,
        password: password,
      });
      setDataSiswa(response.data);
      localStorage.setItem("dataSiswa", JSON.stringify(response.data.data));
      setIsLoading(false);
      navigate("/mobile/home");
    } catch (error) {
      console.error("Error fetching data:", error.response.data.message);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    Login();
  };

  return (
    <>
      <Head />
      <div className="scrollbar-hidden">
        {/* <Splash /> */}

        <main className="auth-main">
          <Top_auth />

          <section className="auth signin">
            <div
              className="logo-container"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                // backgroundColor: "transparent",
                // stroke: "white",
              }}
            >
              <img src="/assets/img/logo.png" alt="logo" width={150} />
            </div>
            <div className="heading">
              <h2>Selamat Datang</h2>
              <p>Sistem Informasi Akademik Terintegrasi</p>
            </div>

            <div className="form-area auth-form">
              <form onSubmit={handleLogin}>
                <div>
                  <label htmlFor="user">Username</label>
                  <input
                    type="number"
                    id="user"
                    placeholder="Masukkan Username"
                    className="input-field"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="pass">Password</label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={showPassword ? "text" : "password"} // Toggle input type
                      id="pass"
                      placeholder="Masukkan Password"
                      className="input-field"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)} // Toggle visibility
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
                    </button>
                  </div>
                </div>
                <button
                  className="btn-primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Login"}
                </button>
              </form>
            </div>
          </section>
        </main>

        <Foot />
      </div>
    </>
  );
};

export default Auth;
