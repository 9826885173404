import React, { useState } from "react";
import axios from "axios";
import HasilTools from "../HasilTools";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CariNikEmis = ({ url }) => {
  const URL = url && url;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tdkDitemukan, setTdkDitemukan] = useState(false);
  const [skeleton, setSkeleton] = useState("");
  const [nikemis, setNikEmis] = useState("");
  const [datanikemis, setDataNikEmis] = useState([]);

  const getNikEmis = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const bear = localStorage.getItem("bear");
    setLoading(true);

    if (bear === "undefined") {
      setError("Belum terkoneksi dengan EMIS");
      return;
    }

    const response = await axios.post(
      URL + "nikemis",
      {
        bear: bear,
        nik: nikemis,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.success === true) {
      setDataNikEmis(response.data);
      setTdkDitemukan(false);
      setError("");
      if (response.data.results.length === 0) {
        setTdkDitemukan(true);
        setError("");
      }
    } else {
      setError(response.data.errors.message.fnik);
      setTdkDitemukan(false);
      setDataNikEmis([]);
    }
    setLoading(false);
  };

  return (
    <div className="tab-pane" id="nik-emis">
      Digunakan untuk melakukan pencarian siswa di EMIS menggunakan NIK
      <div className="form-group mt-4 row">
        <label className="col-form-label col-md-2">Masukkan NIK</label>
        <div className="col-md-6">
          <form onSubmit={getNikEmis}>
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                value={nikemis}
                onChange={(e) => setNikEmis(e.target.value)}
                required
              />

              {loading === true ? (
                <button className="btn btn-danger me-2" type="button" disabled>
                  <span
                    className={`${
                      skeleton ? "spinner-border spinner-border-sm" : ""
                    }`}
                  ></span>
                  <span className="text-dark"> Loading...</span>
                </button>
              ) : (
                <button className="btn btn-primary me-2" type="submit">
                  Cari
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      {datanikemis.results && datanikemis.results.length > 0 ? (
        <HasilTools datanisnemis={datanikemis.results && datanikemis.results} />
      ) : (
        ""
      )}
      {(datanikemis.results && datanikemis.results === null) || error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        ""
      )}
      {tdkDitemukan ? (
        <div className="alert alert-danger">Data tidak ditemukan</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CariNikEmis;
