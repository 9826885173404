import React from 'react';
import Layouts from './Layouts';
import Mutasi from '../components/buduk/Mutasi';

const MutasiBuduk = ({ url }) => {
	return (
		<div>
			<Layouts url={url}>
				<Mutasi url={url} />
			</Layouts>
		</div>
	);
};

export default MutasiBuduk;
