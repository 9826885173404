import React from "react";
import UploadDataSiswa from "./UploadData/UploadDataSiswa";
import UploadDataOrtu from "./UploadData/UploadDataOrtu";
import UploadDataAlamat from "./UploadData/UploadDataAlamat";
import UploadDataRombel from "./UploadData/UploadDataRombel";

const UploadDataEmis = ({ content, url, title }) => {
  const isi = content;

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card bg-white">
          <div className="card-header">
            <h5 className="card-title">{title}</h5>
          </div>
          <div className="card-body">
            {isi === "SISWA" ? (
              <UploadDataSiswa url={url} />
            ) : isi === "ORTU" ? (
              <UploadDataOrtu url={url} />
            ) : isi === "ALAMAT" ? (
              <UploadDataAlamat url={url} />
            ) : (
              <UploadDataRombel url={url} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDataEmis;
