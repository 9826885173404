import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
//   for (let [key, value] of Object.entries(
//     window.__REACT_DEVTOOLS_GLOBAL_HOOK__
//   )) {
//     window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] =
//       typeof value === "function" ? () => {} : null;
//   }
// }
