import React from 'react';
import Layouts from './Layouts';
import ProsesSyncRdm from '../components/buduk/ProsesSyncRdm';

const SyncRdm = ({ url }) => {
	return (
		<div>
			<Layouts url={url}>
				<ProsesSyncRdm url={url} />
			</Layouts>
		</div>
	);
};

export default SyncRdm;
