import React from "react";
import Layouts from "./Layouts";
import Dashboard from "../../Mobile/Dashboard";

const Home = ({ url }) => {
  return (
    <div>
      <Layouts>
        <Dashboard url={url} />
      </Layouts>
    </div>
  );
};

export default Home;
