import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";

const UploadDataRombel = ({ url }) => {
  const [dataRuang, setDataRuang] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);

  const [ambilRuang, setAmbilRuang] = useState([]);

  const URL = url;

  const getRuang = async (e) => {
    setLoading(true);
    setProgress(0); // Reset progress

    e.preventDefault();
    const token = localStorage.getItem("token");
    const bear = localStorage.getItem("bear");

    if (bear === "undefined") {
      setError("Belum terkoneksi dengan EMIS");
      return;
    }
    const institutionArray = JSON.parse(localStorage.getItem("institution"));
    const insId = institutionArray[0].id;

    const response = await axios.post(
      URL + "getrooms",
      {
        bear: bear,
        nsm: localStorage.getItem("nsm"),
        institutionid: insId,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(response);
    const length = response && response.data.length;
    setDataRuang(JSON.stringify(response && response.data));

    for (var i = 0; i < length; i++) {
      const post = await axios.post(
        URL + "postruangkelas",
        {
          id: response.data[i].id,
          id_lembaga: localStorage.getItem("nsm"),
          name: response.data[i].name,
          student_capacity: response.data[i].student_capacity,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
    setLoading(false);
    Swal.fire({
      title: "Berhasil",
      text: "Tarik Data Ruang Kelas Berhasil",
      icon: "success",
    });
    listDataRuang();
  };

  // Fetch Data
  const listDataRuang = async (e) => {
    const token = localStorage.getItem("token");

    try {
      const getlistRuang = await axios.post(
        URL + "getlistruangan",
        {
          nsm: localStorage.getItem("nsm"),
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(getlistRuang.data.data);
      if (getlistRuang.data.status === true) {
        setAmbilRuang(getlistRuang.data.data);
      } else {
        setError(getlistRuang.data.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("Data Ruang Kelas tidak ditemukan");
      } else {
        setError("Terjadi kesalahan saat mengambil data");
      }
    }
  };

  useEffect(() => {
    listDataRuang();
  }, []);

  // DOWNLOAD TEMPLATE
  const downloadTemplate = async () => {
    setLoading(true);

    const token = localStorage.getItem("token");
    try {
      const response = await axios({
        url: URL + "download_template_rombel", // Adjust the URL as needed
        method: "POST",
        data: {
          nsm: localStorage.getItem("nsm"),
        },
        responseType: "blob", // Important for file download
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1]
        : "template_rombel.xlsx";
      const validExtensions = [".xlsx", ".xls"];
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));

      if (!validExtensions.includes(fileExtension)) {
        throw new Error("Invalid file extension");
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChangeRombel = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadTemplateRombel = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const validExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name.slice(file.name.lastIndexOf("."));
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validExtensions.includes(fileExtension)) {
      alert("Invalid file type. Please upload an Excel file.");
      return;
    }

    if (file.size > maxSize) {
      alert("File size exceeds the limit of 5MB.");
      return;
    }

    setLoadingUpload(true);

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("bear", localStorage.getItem("bear"));

    try {
      setShowModal(false);
      const response = await axios.post(
        URL + "upload_template_rombel",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response);

      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Buat Rombel dan Masukkan Siswa Berhasil! Silahkan cek di EMIS!",
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file.");
    } finally {
      setLoadingUpload(false);
    }
  };

  return (
    <div>
      <div className="btn-group">
        {loading ? (
          <button
            className="btn btn-danger"
            onClick={getRuang}
            type="button"
            disabled
          >
            <i className="fa fa-spinner fa-spin"></i> Loading...
          </button>
        ) : (
          <button className="btn btn-primary" onClick={getRuang} type="button">
            Tarik Data Ruangan
          </button>
        )}

        {/* Download Template */}
        {loading === true ? (
          <button className="btn btn-danger" type="button" disabled>
            <i className="fa fa-spinner fa-spin"></i> Loading...
          </button>
        ) : (
          <button
            className="btn btn-info"
            onClick={downloadTemplate}
            type="button"
          >
            Download Template
          </button>
        )}

        {/* Upload Template */}
        {loadingUpload === true ? (
          <button className="btn btn-danger" type="button" disabled>
            <i className="fa fa-spinner fa-spin"></i> Loading...
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => setShowModal(true)}
          >
            Upload Template
          </button>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Template Rombel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-danger">
            Pastikan Template yang diupload hanya berisi 1 rombel, jika lebih
            dari 1 rombel pisahkan menjadi beberapa file template!{" "}
          </p>
          <input
            type="file"
            onChange={handleFileChangeRombel}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={uploadTemplateRombel}>
            Kirim ke EMIS
          </Button>
        </Modal.Footer>
      </Modal>

      {/* {error && <p className="text-danger">{error}</p>} */}

      <table className="table table-bordered mt-3">
        <thead>
          <tr>
            <th>ROOM ID</th>
            <th>Nama Ruangan</th>
            <th>Kapasitas</th>
          </tr>
        </thead>
        <tbody>
          {ambilRuang.length > 0 ? (
            ambilRuang.map((ruang) => (
              <tr key={ruang.id}>
                <td>{ruang.id}</td>
                <td>{ruang.name}</td>
                <td>{ruang.student_capacity}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="text-center">
                Tidak ada data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UploadDataRombel;
