import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BlockUIku from '../../BlockUI/BlockUIku';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

const PengelolaanBuduk = ({ url }) => {
	const URL = url;

	const [ isLoading, setIsLoading ] = useState(false);
	const [ isLoadingGetSiswa, setIsLoadingGetSiswa ] = useState(false);
	const [ isLoadingSync, setIsLoadingSync ] = useState(false);
	const [ academicYear, setAcademicYear ] = useState([]);
	const [ selectedYear, setSelectedYear ] = useState('');
	const [ progress, setProgress ] = useState(0);
	const [ rombel, setRombel ] = useState([]);
	const [ title, setTitle ] = useState('');
	const [ jmlSiswaSync, setJmlSiswaSync ] = useState({});
	const [ rombelLength, setRombelLength ] = useState(0);

	const fetchAcademicYear = async () => {
		const token = localStorage.getItem('token');
		try {
			setIsLoading(true);
			const response = await axios.get(`${URL}academicyear`, {
				headers: { Authorization: `Bearer ${token}` }
			});
			setAcademicYear(response.data.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchRombel = async () => {
		const token = localStorage.getItem('token');
		try {
			setIsLoading(true);
			const response = await axios.post(
				`${URL}fetchrombel`,
				{
					ay: selectedYear,
					nsm: localStorage.getItem('nsm')
				},
				{
					headers: { Authorization: `Bearer ${token}` }
				}
			);
			setRombel(response.data.data);
			setRombelLength(response.data.length);
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const getRombelEmis = async () => {
		const token = localStorage.getItem('token');
		try {
			setIsLoading(true);
			const response = await axios.post(
				`${URL}getrombelemis`,
				{
					bear: localStorage.getItem('bear'),
					ay: selectedYear
				},
				{
					headers: { Authorization: `Bearer ${token}` }
				}
			);

			const length = response && response.data.length;
			for (var i = 0; i < length; i++) {
				setProgress((i + 1) / length * 100);

				const rombel = await axios.post(
					URL + 'postrombel',
					{
						id: response.data[i].id,
						id_lembaga: localStorage.getItem('nsm'),
						name: response.data[i].name,
						level: response.data[i].level,
						teacher_name: response.data[i].teacher_name,
						room_name: response.data[i].room_name,
						student_capacity: response.data[i].student_capacity,
						total_student: response.data[i].total_student,
						m_curriculum_id: response.data[i].m_curriculum_id,
						academic_year_id: response.data[i].academic_year_id,
						over_capacity: response.data[i].over_capacity
					},
					{
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
							Authorization: `Bearer ${token}`
						}
					}
				);

				setTitle(response.data[i].name);

				const length_learning = response && response.data[i].learning_activity.length;
				for (var j = 0; j < length_learning; j++) {
					setProgress((j + 1) / length_learning * 100);
					const postlearning = await axios.post(
						URL + 'postlearningbuduk',
						{
							id: response.data[i].learning_activity[j].id,
							student_id: response.data[i].learning_activity[j].student_id,
							absent_number: response.data[i].learning_activity[j].absent_number,
							institution_id: response.data[i].learning_activity[j].institution_id,
							institution_category_id: response.data[i].learning_activity[j].institution_category_id,
							institution_province_id: response.data[i].learning_activity[j].institution_province_id,
							academic_year_id: response.data[i].learning_activity[j].academic_year_id,
							m_level_id: response.data[i].learning_activity[j].m_level_id,
							m_major_id: response.data[i].learning_activity[j].m_major_id,
							start_date: response.data[i].learning_activity[j].start_date,
							admission_date: response.data[i].learning_activity[j].admission_date,
							study_group_id: response.data[i].learning_activity[j].study_group_id,
							student_status_id: response.data[i].learning_activity[j].student_status_id,
							status_description_id: response.data[i].learning_activity[j].status_description_id,
							created_at: response.data[i].learning_activity[j].created_at,
							created_from_id: response.data[i].learning_activity[j].created_from_id,
							updated_from_id: response.data[i].learning_activity[j].updated_from_id,
							updated_at: response.data[i].learning_activity[j].updated_at,
							deleted_by: response.data[i].learning_activity[j].deleted_by,
							deleted_at: response.data[i].learning_activity[j].deleted_at
						},
						{
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded',
								Authorization: `Bearer ${token}`
							}
						}
					);
				}
			}

			Swal.fire({
				title: 'Berhasil',
				text: 'Data berhasil di sync',
				icon: 'success'
			});

			fetchRombel();
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const getDataSiswa = async (id) => {
		setIsLoadingGetSiswa(true);
		setIsLoadingSync(true);
		const token = localStorage.getItem('token');
		const response = await axios.post(
			`${URL}getlearningactivitybyid`,
			{
				id: id
			},
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);

		const length = response && response.data.length;

		const cryptoKey = 'a2c36eb2w1em50d6665dc5d61a68b400';
		const Base64IVkeys = 'emisBase64IVkeys';
		const fkey = CryptoJS.enc.Utf8.parse(cryptoKey);

		// security config
		const securityConfig = () => {
			const fiv = CryptoJS.enc.Utf8.parse(Base64IVkeys);
			return {
				iv: fiv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7
			};
		};

		for (var i = 0; i < length; i++) {
			setProgress((i + 1) / length * 100);
			//  console.log(response.data[i]);
			const id = response.data.data[i].student_id;

			// Ensure the ID is a string before encryption
			const idString = id.toString();

			const encrypted = CryptoJS.AES
				.encrypt(
					idString, // Ensure this is a string
					fkey,
					securityConfig()
				)
				.toString();
			const base64encoded = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));

			const siswa = await axios.post(
				`${URL}getsiswabyid`,
				{
					enc: base64encoded,
					bear: localStorage.getItem('bear')
				},
				{
					headers: { Authorization: `Bearer ${token}` }
				}
			);

			// console.log(siswa);

			if (!siswa.data.errors) {
				// DATA SISWA
				const post = await axios.post(
					URL + 'postsiswabuduk',
					{
						// id: response.data[i].id,
						uuid: uuidv4(),
						id_lembaga: localStorage.getItem('nsm'),
						id: siswa.data.id,
						full_name: siswa.data.full_name,
						nisn: siswa.data.nisn,
						birth_place: siswa.data.birth_place,
						birth_date: siswa.data.birth_date,
						nomor_kip: siswa.data.nomor_kip,
						learning_activity_id: siswa.data.learning_activity_id,
						institution_category_id: siswa.data.institution_category_id,
						institution_province_id: siswa.data.institution_province_id,
						student_status_id: siswa.data.student_status_id,
						tingkat: siswa.data.tingkat,
						age: siswa.data.age,
						level_name: siswa.data.level_name,
						study_group_name: siswa.data.study_group_name,
						academic_year_status: siswa.data.academic_year_status,
						is_draftable: siswa.data.is_draftable,
						kode_enkripsi: base64encoded
					},
					{
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
							Authorization: `Bearer ${token}`
						}
					}
				);

				// LEARNING ACTIVITY
				const postlearning = await axios.post(
					URL + 'postlearningbuduk',
					{
						id: siswa.data.learning_activity.id,
						student_id: siswa.data.learning_activity.student_id,
						absent_number: siswa.data.learning_activity.absent_number,
						institution_id: siswa.data.learning_activity.institution_id,
						institution_category_id: siswa.data.learning_activity.institution_category_id,
						institution_province_id: siswa.data.learning_activity.institution_province_id,
						academic_year_id: siswa.data.learning_activity.academic_year_id,
						m_level_id: siswa.data.learning_activity.m_level_id,
						m_major_id: siswa.data.learning_activity.m_major_id,
						start_date: siswa.data.learning_activity.start_date,
						admission_date: siswa.data.learning_activity.admission_date,
						study_group_id: siswa.data.learning_activity.study_group_id,
						student_status_id: siswa.data.learning_activity.student_status_id,
						status_description_id: siswa.data.learning_activity.status_description_id,
						created_at: siswa.data.learning_activity.created_at,
						created_from_id: siswa.data.learning_activity.created_from_id,
						updated_from_id: siswa.data.learning_activity.updated_from_id,
						updated_at: siswa.data.learning_activity.updated_at,
						deleted_by: siswa.data.learning_activity.deleted_by,
						deleted_at: siswa.data.learning_activity.deleted_at
					},
					{
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
							Authorization: `Bearer ${token}`
						}
					}
				);

				// STATUS SISWA
				const studentstatus = await axios.post(
					URL + 'poststudentstatus',
					{
						id: siswa.data.learning_activity.student_status.id,
						name: siswa.data.learning_activity.student_status.name
					},
					{
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
							Authorization: `Bearer ${token}`
						}
					}
				);

				// STATUS DESCRIPTION
				const statusdesc = await axios.post(
					URL + 'poststatusdescription',
					{
						id: siswa.data.learning_activity.status_description.id,
						name: siswa.data.learning_activity.status_description.name,
						student_status_id: siswa.data.learning_activity.status_description.student_status_id,
						created_at: siswa.data.learning_activity.status_description.created_at,
						updated_at: siswa.data.learning_activity.status_description.updated_at
					},
					{
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
							Authorization: `Bearer ${token}`
						}
					}
				);

				// PARENTS
				const parents = await axios.post(
					URL + 'postparentsbuduk',
					{
						id: siswa.data.parents.id,
						student_id: siswa.data.parents.student_id,
						father_full_name: siswa.data.parents.father_full_name,
						father_m_life_status_id: siswa.data.parents.father_m_life_status_id,
						father_nik: siswa.data.parents.father_nik,
						father_birth_place: siswa.data.parents.father_birth_place,
						father_birth_date: siswa.data.parents.father_birth_date,
						father_m_last_education_id: siswa.data.parents.father_m_last_education_id,
						father_m_job_id: siswa.data.parents.father_m_job_id,
						father_m_average_income_per_month_id: siswa.data.parents.father_m_average_income_per_month_id,
						father_phone_number: siswa.data.parents.father_phone_number,
						is_father_phone_null: siswa.data.parents.is_father_phone_null,
						father_domicile: siswa.data.parents.father_domicile,
						father_m_residence_status_id: siswa.data.parents.father_m_residence_status_id,
						father_m_province_id: siswa.data.parents.father_m_province_id,
						father_m_city_id: siswa.data.parents.father_m_city_id,
						father_m_district_id: siswa.data.parents.father_m_district_id,
						father_m_sub_district_id: siswa.data.parents.father_m_sub_district_id,
						father_address: siswa.data.parents.father_address,
						father_rt: siswa.data.parents.father_rt,
						father_rw: siswa.data.parents.father_rw,
						father_postal_code: siswa.data.parents.father_postal_code,
						father_kk_file: siswa.data.parents.father_kk_file,
						mother_full_name: siswa.data.parents.mother_full_name,
						mother_m_life_status_id: siswa.data.parents.mother_m_life_status_id,
						mother_nik: siswa.data.parents.mother_nik,
						mother_birth_place: siswa.data.parents.mother_birth_place,
						mother_birth_date: siswa.data.parents.mother_birth_date,
						mother_m_last_education_id: siswa.data.parents.mother_m_last_education_id,
						mother_m_job_id: siswa.data.parents.mother_m_job_id,
						mother_m_average_income_per_month_id: siswa.data.parents.mother_m_average_income_per_month_id,
						mother_phone_number: siswa.data.parents.mother_phone_number,
						is_mother_phone_null: siswa.data.parents.is_mother_phone_null,
						mother_residence: siswa.data.parents.mother_residence,
						mother_domicile: siswa.data.parents.mother_domicile,
						mother_m_residence_status_id: siswa.data.parents.mother_m_residence_status_id,
						mother_m_province_id: siswa.data.parents.mother_m_province_id,
						mother_m_city_id: siswa.data.parents.mother_m_city_id,
						mother_m_district_id: siswa.data.parents.mother_m_district_id,
						mother_m_sub_district_id: siswa.data.parents.mother_m_sub_district_id,
						mother_address: siswa.data.parents.mother_address,
						mother_rt: siswa.data.parents.mother_rt,
						mother_rw: siswa.data.parents.mother_rw,
						mother_postal_code: siswa.data.parents.mother_postal_code,
						mother_kk_file: siswa.data.parents.mother_kk_file,
						is_kk_same_father: siswa.data.parents.is_kk_same_father,
						wali: siswa.data.parents.wali,
						wali_full_name: siswa.data.parents.wali_full_name,
						wali_nik: siswa.data.parents.wali_nik,
						wali_birth_place: siswa.data.parents.wali_birth_place,
						wali_birth_date: siswa.data.parents.wali_birth_date,
						wali_m_last_education_id: siswa.data.parents.wali_m_last_education_id,
						wali_m_job_id: siswa.data.parents.wali_m_job_id,
						wali_m_average_income_per_month_id: siswa.data.parents.wali_m_average_income_per_month_id,
						wali_phone_number: siswa.data.parents.wali_phone_number,
						is_wali_phone_null: siswa.data.parents.is_wali_phone_null,
						wali_domicile: siswa.data.parents.wali_domicile,
						wali_m_residence_status_id: siswa.data.parents.wali_m_residence_status_id,
						wali_m_province_id: siswa.data.parents.wali_m_province_id,
						wali_m_city_id: siswa.data.parents.wali_m_city_id,
						wali_m_district_id: siswa.data.parents.wali_m_district_id,
						wali_m_sub_district_id: siswa.data.parents.wali_m_sub_district_id,
						wali_address: siswa.data.parents.wali_address,
						wali_rt: siswa.data.parents.wali_rt,
						wali_rw: siswa.data.parents.wali_rw,
						wali_postal_code: siswa.data.parents.wali_postal_code,
						wali_kk_file: siswa.data.parents.wali_kk_file,
						wali_kks_number: siswa.data.parents.wali_kks_number,
						wali_pkh_number: siswa.data.parents.wali_pkh_number,
						request_kemensos_date: siswa.data.parents.request_kemensos_date,
						wali_kks_file: siswa.data.parents.wali_kks_file,
						wali_pkh_file: siswa.data.parents.wali_pkh_file,
						created_by: siswa.data.parents.created_by,
						updated_by: siswa.data.parents.updated_by,
						draft_history_id: siswa.data.parents.draft_history_id,
						created_at: siswa.data.parents.created_at,
						updated_at: siswa.data.parents.updated_at,
						father_nationality: siswa.data.parents.father_nationality,
						mother_nationality: siswa.data.parents.mother_nationality,
						wali_nationality: siswa.data.parents.wali_nationality,
						father_country_of_origin: siswa.data.parents.father_country_of_origin,
						mother_country_of_origin: siswa.data.parents.mother_country_of_origin,
						wali_country_of_origin: siswa.data.parents.wali_country_of_origin,
						father_kitas: siswa.data.parents.father_kitas,
						mother_kitas: siswa.data.parents.mother_kitas,
						wali_kitas: siswa.data.parents.wali_kitas,
						father_have_phone_number: siswa.data.parents.father_have_phone_number,
						mother_have_phone_number: siswa.data.parents.mother_have_phone_number,
						wali_have_phone_number: siswa.data.parents.wali_have_phone_number,
						deleted_at: siswa.data.parents.deleted_at,
						if_draftable: siswa.data.parents.if_draftable,
						father_latest_education: siswa.data.parents.father_latest_education,
						mother_latest_education: siswa.data.parents.mother_latest_education
					},
					{
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
							Authorization: `Bearer ${token}`
						}
					}
				);

				// DATA DETAIL SISWA
				const postds = await axios.post(
					URL + 'postdetailsiswabuduk',
					{
						id_emis: siswa.data.id,
						nik: siswa.data.nik,
						local_nis: siswa.data.local_nis,
						m_gender_id: siswa.data.m_gender_id,
						child_of_num: siswa.data.child_of_num,
						siblings_num: siswa.data.siblings_num,
						m_religion_id: siswa.data.m_religion_id,
						m_life_goal_id: siswa.data.m_life_goal_id,
						m_hobby_id: siswa.data.m_hobby_id,
						m_residence_status_id: siswa.data.m_residence_status_id,
						m_province_id: siswa.data.m_province_id,
						m_city_id: siswa.data.m_city_id,
						m_district_id: siswa.data.m_district_id,
						m_subdistrict_id: siswa.data.m_subdistrict_id,
						address: siswa.data.address,
						rt: siswa.data.rt,
						rw: siswa.data.rw,
						postal_code_num: siswa.data.postal_code_num,
						handphone: siswa.data.handphone,
						is_handphone_null: siswa.data.is_handphone_null,
						email: siswa.data.email,
						m_residence_distance_id: siswa.data.m_residence_distance_id,
						m_interval_time_id: siswa.data.m_interval_time_id,
						m_transportation_id: siswa.data.m_transportation_id,
						is_kip_sync: siswa.data.is_kip_sync,
						upload_kip: siswa.data.upload_kip,
						m_fund_source_id: siswa.data.m_fund_source_id,
						m_special_need_id: siswa.data.m_special_need_id,
						m_disability_id: siswa.data.m_disability_id,
						entered_tk_ra: siswa.data.entered_tk_ra,
						entered_paud: siswa.data.entered_paud,
						latitude: siswa.data.latitude,
						longitude: siswa.data.longitude,
						kk_num: siswa.data.kk_num,
						family_head_name: siswa.data.family_head_name,
						admission_date: siswa.data.admission_date,
						vaccine_type_ids: siswa.data.vaccine_type_ids,
						pusdatin_sync_date: siswa.data.pusdatin_sync_date,
						integration_id: siswa.data.integration_id,
						siswa_id: siswa.data.siswa_id,
						pusdatin_update_date: siswa.data.pusdatin_update_date,
						created_by: siswa.data.created_by,
						updated_by: siswa.data.updated_by,
						created_from_id: siswa.data.created_from_id,
						updated_from_id: siswa.data.updated_from_id,
						created_at: siswa.data.created_at,
						updated_at: siswa.data.updated_at,
						deleted_at: siswa.data.deleted_at,
						draft_history_id: siswa.data.draft_history_id,
						upload_kk: siswa.data.upload_kk,
						upload_photo: siswa.data.upload_photo,
						nationality: siswa.data.nationality,
						country_of_origin: siswa.data.country_of_origin,
						kitas: siswa.data.kitas,
						have_nik: siswa.data.have_nik,
						have_nisn: siswa.data.have_nisn,
						have_handphone: siswa.data.have_handphone,
						dukcapil_verification_status_id: siswa.data.dukcapil_verification_status_id,
						dukcapil_verification_date: siswa.data.dukcapil_verification_date,
						pkh_family: siswa.data.pkh_family,
						bpnt_family: siswa.data.bpnt_family,
						pbi_member: siswa.data.pbi_member,
						dtks_is_active: siswa.data.dtks_is_active,
						pkh_is_active: siswa.data.pkh_is_active,
						sk_period: siswa.data.sk_period,
						valid_nisn: siswa.data.valid_nisn,
						last_sync_date_kemensos: siswa.data.last_sync_date_kemensos,
						is_draftable: siswa.data.is_draftable,
						gender: siswa.data.gender,
						institution: siswa.data.institution,
						full_address: siswa.data.full_address,
						student_vaccine_types: [ siswa.data.student_vaccine_types ],
						student_disabilities: [
							siswa.data.student_disabilities.id,
							siswa.data.student_disabilities.name
						],
						scholarships: [ siswa.data.scholarships ],
						achievements: [ siswa.data.achievements ]
					},
					{
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
							Authorization: `Bearer ${token}`
						}
					}
				);
			}
		}
		setIsLoadingGetSiswa(false);
		setIsLoadingSync(false);
		fetchRombel();

		Swal.fire({
			title: 'Berhasil',
			text: 'Data Berhasil Ditarik',
			icon: 'success'
		});
	};

	const getJmlSiswaSync = async (id) => {
		const token = localStorage.getItem('token');
		const response = await axios.post(
			URL + 'getjmlsiswabuduk',
			{
				id: id
			},
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return response.data.data;
	};

	useEffect(
		() => {
			const fetchJmlSiswaSync = async () => {
				const syncData = {};
				for (const item of rombel) {
					const data = await getJmlSiswaSync(item.id);
					syncData[item.id] = data;
				}
				setJmlSiswaSync(syncData);
			};
			if (rombel.length > 0) {
				fetchJmlSiswaSync();
			}
		},
		[ rombel ]
	);

	useEffect(() => {
		fetchAcademicYear();
	}, []);

	useEffect(
		() => {
			fetchRombel();
		},
		[ selectedYear ]
	);

	return (
		<div>
			<h5>Kelola Buku Induk</h5>

			<div className="form-group row">
				<label className="col-form-label col-md-2">Pilih Tahun Ajaran</label>
				<div className="col-md-4">
					<select
						className="form-control form-select"
						value={selectedYear}
						onChange={(e) => setSelectedYear(e.target.value)}
					>
						<option>-- Pilih Tahun Ajaran --</option>
						{academicYear.map((year, index) => (
							<option key={index} value={year.id}>
								{year.name}
							</option>
						))}
					</select>
				</div>
				<div className="col-md-3">
					{selectedYear && (
						<button className="btn btn-primary btn-md" onClick={getRombelEmis}>
							Tarik Data
						</button>
					)}
				</div>

				<div className="col-md-12 mt-3">
					{isLoadingGetSiswa && <BlockUIku blocking={isLoadingGetSiswa} progress={progress} />}
					{isLoadingSync && <BlockUIku blocking={isLoadingSync} progress={progress} />}
					{isLoading && <BlockUIku blocking={isLoading} progress={progress} title={title} />}

					<div className="card">
						<div className="card-body">
							<table className="table table-striped table-bordered table-hover">
								<thead>
									<tr>
										<th>No</th>
										<th>Nama Rombel</th>
										<th>Tingkat</th>
										<th>Nama Ruang</th>
										<th>Wali Kelas</th>
										<th>Jumlah Siswa</th>
										<th>Jumlah Siswa Sync</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{rombelLength > 0 ? (
										rombel.map((item, index) => (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{item.name}</td>
												<td>{item.level}</td>
												<td>{item.room_name}</td>
												<td>{item.teacher_name}</td>
												<td>{item.total_student}</td>
												<td>
													{jmlSiswaSync[item.id] === 0 ? (
														<span className="badge badge-danger">Belum Sync</span>
													) : (
														jmlSiswaSync[item.id] || 'loading...'
													)}
												</td>
												<td>
													<button
														className="btn btn-primary btn-sm"
														onClick={() => getDataSiswa(item.id)}
													>
														Get Siswa
													</button>
													<Link
														to={`/buduk-rombel/${item.id}`}
														className="btn btn-warning btn-sm"
													>
														Detail
													</Link>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan="8" className="text-center text-danger">
												Data Tidak Ditemukan, Silahkan Tarik Data Terlebih Dahulu!
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PengelolaanBuduk;
