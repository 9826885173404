import React, { useState } from "react";
import axios from "axios";
import HasilToolsPusdatin from "../HasilToolsPusdatin";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import HasilTarikPaksa from "./HasilTarikPaksa";

const TarikPaksa = ({ url, fbd }) => {
  const URL = url && url;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tdkDitemukan, setTdkDitemukan] = useState(false);
  const [skeleton, setSkeleton] = useState("");
  const [nisnpusdatin, setNisnPusdatin] = useState("");
  const [datanisnpusdatin, setDataNisnPusdatin] = useState([]);

  const getNisnPusdatin = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const bear = localStorage.getItem("bear");
    setLoading(true);

    if (bear === "undefined") {
      setError("Belum terkoneksi dengan EMIS");
      return;
    }

    const response = await axios.post(
      URL + "nisnpusdatin",
      {
        bear: bear,
        nisnpusdatin: nisnpusdatin,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("pus" + response.data && response.data);

    if (response.data.success && response.data.success === true) {
      setDataNisnPusdatin(response.data);
      setTdkDitemukan(false);
      setError("");
      if (response.data.results.data === "data tidak ditemukan") {
        setTdkDitemukan(true);
        setError("Data tidak ditemukan");
      }
    } else {
      setError(response.data && response.data.errors.message);
      setTdkDitemukan(false);
      setDataNisnPusdatin([]);
    }
    setLoading(false);
  };

  return (
    <div className="tab-pane" id="tarikpaksa">
      <div class="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>
          HANYA BOLEH DIGUNAKAN SAAT KONDISI DARURAT SAJA!. TIDAK DIANJURKAN
          MENGGUNAKAN FITUR INI JIKA MASIH MEMUNGKINKAN MENAMBAHKAN SISWA SECARA
          BENAR SESUAI PROSEDUR !!! <br />
          RESIKO DITANGGUNG SENDIRI!{fbd}
        </strong>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
      <div className="form-group mt-4 row">
        <label className="col-form-label col-md-2">Masukkan NISN</label>
        <div className="col-md-6">
          <form onSubmit={getNisnPusdatin}>
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                value={nisnpusdatin}
                onChange={(e) => setNisnPusdatin(e.target.value)}
                required
              />

              {loading === true ? (
                <button className="btn btn-danger me-2" type="button" disabled>
                  <span
                    className={`${
                      skeleton ? "spinner-border spinner-border-sm" : ""
                    }`}
                  ></span>
                  <span className="text-dark"> Loading...</span>
                </button>
              ) : (
                <button className="btn btn-primary me-2" type="submit">
                  Cari
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      {datanisnpusdatin.results && datanisnpusdatin.results ? (
        <HasilTarikPaksa
          datanisnemis={datanisnpusdatin.results && datanisnpusdatin.results}
          url={url}
          fbd={fbd}
        />
      ) : (
        ""
      )}
      {(datanisnpusdatin.results && datanisnpusdatin.results === null) ||
      error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        ""
      )}
      {tdkDitemukan ? (
        <div className="alert alert-danger">Data tidak ditemukan</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TarikPaksa;
