import React from "react";
import ListAjuan from "../components/ajuan/ListAjuan";
import Layouts from "./Layouts";

const Ajuan = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <ListAjuan url={url} />
      </Layouts>
    </div>
  );
};

export default Ajuan;
