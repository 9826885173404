import React from "react";

const Head = () => {
  return (
    <div>
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>BIMA SAKTI - MOBILE</title>
        <link rel="stylesheet" href="/assets_mobile/css/bootstrap.min.css" />
        <link
          rel="stylesheet"
          href="/assets_mobile/css/swiper-bundle.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets_mobile/css/jquery.datetimepicker.css"
        />
        <link rel="stylesheet" href="/assets_mobile/css/jquery-ui.min.css" />
        <link rel="stylesheet" href="/assets_mobile/css/common.css" />
        <link rel="stylesheet" href="/assets_mobile/css/animations.css" />
        <link rel="stylesheet" href="/assets_mobile/css/welcome.css" />
        <link rel="stylesheet" href="/assets_mobile/css/auth.css" />
        <link rel="stylesheet" href="/assets_mobile/css/dashboard.css" />
        <link rel="stylesheet" href="/assets_mobile/css/home.css" />
        <link rel="stylesheet" href="/assets_mobile/css/ticket.css" />
        <link rel="stylesheet" href="/assets_mobile/css/profile.css" />
      </head>
    </div>
  );
};

export default Head;
