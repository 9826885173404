import React from "react";

const Footer = () => {
  return (
    <div>
      <footer style={{ backgroundColor: "#eeeeee" }} className="mt-5">
        <p style={{ color: "black" }}>
          COPYRIGHT © 2024{" "}
          <a href="https://mmc.biz.id" className="text-dark">
            MICROMEDIA DEV.
          </a>{" "}
          <span className="text-end text-sm">ver 1.0.0</span>
        </p>
      </footer>
    </div>
  );
};

export default Footer;
