import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Dashboard = ({ url }) => {
  const URL = url;
  const [institution, setInstitution] = useState([]);

  const getInstitution = async () => {
    const response = await axios.post(`${URL}getnsm`, {
      nsm: JSON.parse(localStorage.getItem("dataSiswa")).id_lembaga,
    });

    setInstitution(response.data.data);
    localStorage.setItem("dataLembaga", JSON.stringify(response.data.data));
  };

  useEffect(() => {
    getInstitution();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("id-ID", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  }

  return (
    <>
      {/* INFO */}

      <section className="info d-flex align-items-start justify-content-between pb-12">
        <div className="d-flex align-items-center justify-content-between gap-14">
          <img src="/assets/img/logo.png" alt="logo" width={120} />
        </div>

        <ul className="d-flex align-items-center gap-16">
          <li>
            <a
              href="notification.html"
              className="d-flex align-items-center justify-content-center rounded-full position-relative"
            >
              <img src="/assets_mobile/svg/bell-black.svg" alt="icon" />
              <span className="dot"></span>
            </a>
          </li>
          <li>
            <a
              href="chat/message.html"
              className="d-flex align-items-center justify-content-center rounded-full position-relative"
            >
              <img
                src="/assets_mobile/svg/message-square-dots.svg"
                alt="icon"
              />
              <span class="dot"></span>
            </a>
          </li>
        </ul>
      </section>

      <section>
        <div className="ticket-card radius-8">
          <div className="card-title d-flex align-items-center justify-content-between">
            <h4>Selamat Datang</h4>
            <span>Identitas</span>
          </div>

          <div className="card-item d-flex align-items-center gap-16 w-100">
            <div className="image shrink-0 overflow-hidden radius-8">
              <img
                src="/assets_mobile/images/home/avatar.png"
                alt="Place"
                className="img-fluid w-100 h-100 object-fit-cover"
              />
            </div>

            <div className="content flex-grow">
              <h4>{JSON.parse(localStorage.getItem("dataSiswa")).full_name}</h4>
              <p className="d-flex align-items-center gap-04 location mt-04">
                NISN :{JSON.parse(localStorage.getItem("dataSiswa")).nisn}
              </p>
              <p className="d-flex align-items-center gap-04 location mt-04">
                Tempat Lahir :
                {JSON.parse(localStorage.getItem("dataSiswa")).birth_place}
              </p>
              <p className="d-flex align-items-center gap-04 location mt-04">
                Tanggal Lahir :
                {formatDate(
                  JSON.parse(localStorage.getItem("dataSiswa")).birth_date
                )}
              </p>
              <p className="d-flex align-items-center gap-04 location mt-04">
                Kelas :
                {JSON.parse(localStorage.getItem("dataSiswa")).level_name}
              </p>
            </div>
          </div>

          <div className="card-footer d-flex align-items-center justify-content-between">
            <div>
              <p>Institution : </p>
              <h3>{institution.name}</h3>
              <p>
                {institution.nsm} - {institution.npsn}
              </p>
            </div>

            <a href="">Detail</a>
          </div>
        </div>
      </section>

      {/* MENU */}
      <section class="service py-12">
        <Link to="/mobile/data-siswa">
          <figure class="item text-center">
            <div class="image rounded-full d-flex align-items-center justify-content-center m-auto">
              <img
                src="/assets_mobile/images/home/airport.png"
                alt="airport"
                class="img-fluid backface-hidden"
              />
            </div>
            <figcaption>Data Siswa</figcaption>
          </figure>
        </Link>

        <a href="service-location.html">
          <figure class="item text-center">
            <div class="image rounded-full d-flex align-items-center justify-content-center m-auto">
              <img
                src="/assets_mobile/images/home/car-rental.png"
                alt="car"
                class="img-fluid backface-hidden"
              />
            </div>
            <figcaption>Data Orang Tua</figcaption>
          </figure>
        </a>

        <a href="service-location.html">
          <figure class="item text-center">
            <div class="image rounded-full d-flex align-items-center justify-content-center m-auto">
              <img
                src="/assets_mobile/images/home/hotel.png"
                alt="hotel"
                class="img-fluid backface-hidden"
              />
            </div>
            <figcaption>Data Alamat</figcaption>
          </figure>
        </a>

        <figure
          class="item text-center"
          data-bs-toggle="modal"
          data-bs-target="#serviceModal"
        >
          <div class="image rounded-full d-flex align-items-center justify-content-center m-auto">
            <img
              src="/assets_mobile/images/home/category.png"
              alt="category"
              class="img-fluid backface-hidden"
            />
          </div>
          <figcaption>More</figcaption>
        </figure>
      </section>
    </>
  );
};

export default Dashboard;
