import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const Detail = ({ url }) => {
	const { id } = useParams();
	const [ siswas, setSiswas ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ rombel, setRombel ] = useState([]);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ itemsPerPage ] = useState(10);

	const URL = url;
	const fetchLearningActivity = async () => {
		setIsLoading(true);
		const token = localStorage.getItem('token');
		try {
			const response = await axios.post(
				`${URL}getsiswadetail`,
				{ id: id },
				{ headers: { Authorization: `Bearer ${token}` } }
			);

			if (response.data && response.data.data) {
				setSiswas(response.data.data);
			} else {
				setError('Unexpected API response structure');
			}
		} catch (error) {
			setError('Error fetching data');
		} finally {
			setIsLoading(false);
		}
	};

	const fetchRombelById = async () => {
		setIsLoading(true);
		const token = localStorage.getItem('token');
		try {
			const response = await axios.post(
				`${URL}fetchrombelbyid`,
				{ id: id },
				{ headers: { Authorization: `Bearer ${token}` } }
			);
			setRombel(response.data.data);
			console.log(response.data.data);
		} catch (error) {
			console.error('Error fetching data:', error);
			setError('Error fetching data');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(
		() => {
			fetchLearningActivity();
			fetchRombelById();
		},
		[ id ]
	);

	const handleCellChange = (e, index, field) => {
		const newSiswas = [ ...siswas ];
		newSiswas[index][field] = e.target.innerText;
		setSiswas(newSiswas);
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentSiswas = siswas.slice(indexOfFirstItem, indexOfLastItem);

	const renderPageNumbers = () => {
		const pageNumbers = [];
		for (let i = 1; i <= Math.ceil(siswas.length / itemsPerPage); i++) {
			pageNumbers.push(i);
		}
		return pageNumbers.map((number) => (
			<li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
				<button onClick={() => handlePageChange(number)} className="page-link">
					{number}
				</button>
			</li>
		));
	};

	return (
		<div style={{ marginBottom: '60px' }}>
			<h3>Detail Rombel {rombel.room_name}</h3>
			{isLoading && <p>Loading...</p>}
			{error && <p>{error}</p>}
			{!isLoading &&
			!error && (
				<div className="card">
					<div style={{ overflowX: 'auto' }}>
						<table className="table table-bordered table-striped">
							<thead>
								<tr>
									<th>#</th>
									<th>No Induk</th>
									<th>NISN</th>
									<th>Nama</th>
									<th>JK</th>
									<th>Tempat Lahir</th>
									<th>Tanggal Lahir</th>
									<th>Alamat</th>
									<th>Nama Ayah</th>
									<th>Nama Ibu</th>
									<th>Nama Wali</th>
								</tr>
							</thead>
							<tbody>
								{currentSiswas.length > 0 ? (
									currentSiswas.map((siswa, index) => {
										return (
											<tr key={siswa.uuid}>
												<td>{indexOfFirstItem + index + 1}</td>
												<td
													contentEditable
													onBlur={(e) => handleCellChange(e, index, 'local_nis')}
												>
													{siswa.local_nis}
												</td>
												<td contentEditable onBlur={(e) => handleCellChange(e, index, 'nisn')}>
													{siswa.nisn}
												</td>
												<td
													contentEditable
													onBlur={(e) => handleCellChange(e, index, 'full_name')}
												>
													{siswa.full_name}
												</td>
												<td
													contentEditable
													onBlur={(e) => handleCellChange(e, index, 'gender')}
												>
													{siswa.gender}
												</td>
												<td
													contentEditable
													onBlur={(e) => handleCellChange(e, index, 'birth_place')}
												>
													{siswa.birth_place}
												</td>
												<td
													contentEditable
													onBlur={(e) => handleCellChange(e, index, 'birth_date')}
												>
													{siswa.birth_date}
												</td>
												<td
													contentEditable
													onBlur={(e) => handleCellChange(e, index, 'address')}
												>
													{siswa.address}
												</td>
												<td
													contentEditable
													onBlur={(e) => handleCellChange(e, index, 'father_full_name')}
												>
													{siswa.father_full_name}
												</td>
												<td
													contentEditable
													onBlur={(e) => handleCellChange(e, index, 'mother_full_name')}
												>
													{siswa.mother_full_name}
												</td>
												<td
													contentEditable
													onBlur={(e) => handleCellChange(e, index, 'wali_full_name')}
												>
													{siswa.wali_full_name}
												</td>
											</tr>
										);
									})
								) : (
									<tr>
										<td colSpan="11">No data available</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<nav>
							<ul className="pagination mt-2">{renderPageNumbers()}</ul>
						</nav>
					</div>
				</div>
			)}
		</div>
	);
};

export default Detail;
