import React from "react";
import Layouts from "./Layouts";

const Forbid = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <div className="error-box">
          <h1>403</h1>
          <h3 className="h2 mb-3">
            <i className="fas fa-exclamation-triangle"></i> Oops! Forbidden!
          </h3>
          <p className="h4 font-weight-normal">
            Anda tidak memiliki akses ke halaman ini.
          </p>
          <a href="#" className="btn btn-danger">
            BERTOBATLAH
          </a>
        </div>
      </Layouts>
    </div>
  );
};

export default Forbid;
