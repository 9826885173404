import React from "react";
import Layouts from "./Layouts";
import IsiBuduk from "../components/buduk/IsiBuduk";

const Buduk = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <IsiBuduk url={url} />
      </Layouts>
    </div>
  );
};

export default Buduk;
