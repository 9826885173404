import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteConfirmationToast from '../DeleteConfirmToast';
import Swal from 'sweetalert2';
import { openDB } from 'idb';

const Padankan = ({ url }) => {
	const [ data, setData ] = useState([]);
	const [ error, setError ] = useState(null);
	const URL = url;
	const [ loading, setLoading ] = useState(false);


	const fetchDataSiswa = async () => {
		setLoading(true);
		const token = localStorage.getItem('token');
		const response = await axios.post(
			`${URL}listsiswabuduk`,
			{ nsm: localStorage.getItem('nsm') },
			{ headers: { Authorization: `Bearer ${token}` } }
		);

		if (response.data && response.data.data) {
			const apiData = response.data.data;
			const enrichedData = await Promise.all(
				apiData.map(async (item) => {
					if (item.siswa.nisn) {
						// PAKAI NISN
              try {
                const rdmData = await getRdmDataByNisn(item.siswa.nisn);

                return {
                  ...item,
                  rdm_nis: rdmData.nis || '',
                  rdm_nisn: rdmData.nisn || '',
                  rdm_full_name: rdmData.nama || ''
                };
              } catch (error) {
                return { ...item, rdm_nis: '', rdm_nisn: '', rdm_full_name: '' };
              }
					} else {
						// PAKAI NAMA

						try {
							const rdmData = await getRdmDataByNama(item.siswa.full_name);

							return {
								...item,
								rdm_nis: rdmData.nis || '',
								rdm_nisn: rdmData.nisn || '',
								rdm_full_name: rdmData.nama || ''
							};
						} catch (error) {
							return { ...item, rdm_nis: '', rdm_nisn: '', rdm_full_name: '' };
						}
					}
				})
			);
			// console.log(enrichedData);

			setData(enrichedData);
			setLoading(false);
		} else {
			setError('Unexpected API response structure');
		}
	};

	const getRdmDataByNisn = (nisn) => {
		return new Promise((resolve, reject) => {
			if (!nisn) {
				reject('Invalid NISN');
				return;
			}

			const request = indexedDB.open('RDMSiswa', 1);
			request.onerror = (event) => {
				reject('Database error');
			};
			request.onsuccess = (event) => {
				const db = event.target.result;
				const transaction = db.transaction([ 'siswa' ], 'readonly');
				const objectStore = transaction.objectStore('siswa');
				const index = objectStore.index('nisn');
				const getRequest = index.get(nisn);

				getRequest.onsuccess = (event) => {
					resolve(event.target.result || {});
				};

				getRequest.onerror = (event) => {
					reject('Error fetching data');
				};
			};
		});
	};
	const getRdmDataByNama = (nama) => {
		return new Promise((resolve, reject) => {
			if (!nama) {
				reject('Invalid NAMA');
				return;
			}

			const request = indexedDB.open('RDMSiswa', 1);
			request.onerror = (event) => {
				reject('Database error');
			};
			request.onsuccess = (event) => {
				const db = event.target.result;
				const transaction = db.transaction([ 'siswa' ], 'readonly');
				const objectStore = transaction.objectStore('siswa');
				const index = objectStore.index('nama');
				const getRequest = index.get(nama);

				getRequest.onsuccess = (event) => {
					resolve(event.target.result || {});
				};

				getRequest.onerror = (event) => {
					reject('Error fetching data');
				};
			};
		});
	};

	const updateEmisData = async (item) => {
		try {
			// console.log(item.siswa.id_emis);
			toast.success('Data EMIS berhasil diperbarui');
			const token = localStorage.getItem('token');
			const response = await axios.post(
				`${URL}updatesiswabuduk`,
				{
					id: item.siswa.id_emis,
					local_nis: item.rdm_nis,
					nisn: item.rdm_nisn,
					nsm: localStorage.getItem('nsm')
				},
				{ headers: { Authorization: `Bearer ${token}` } }
			);
			if (response.data.res === 200) {
				fetchDataSiswa(); // Refresh data
			} else {
				alert('Gagal memperbarui data');
			}
		} catch (error) {
			console.error('Error updating  data:', error);
		}
	};

	const deleteEmisData = async (item) => {
		const handleDelete = async () => {
			const token = localStorage.getItem('token');
			// console.log(item.siswa.id_emis);
			try {
				const res = await axios.post(
					`${URL}delsiswabuduk`,
					{
						id: item.siswa.id_emis,
						nsm: localStorage.getItem('nsm')
					},
					{ headers: { Authorization: `Bearer ${token}` } }
				);
				if (res.data.res === 200) {
					Swal.fire({
						title: 'Berhasil',
						text: 'Data berhasil dihapus',
						icon: 'success',
						timer: 1000,
						timerProgressBar: true
					});
					fetchDataSiswa();
				} else {
					toast.error('Gagal menghapus data');
				}
			} catch (error) {
				console.error('Error deleting data:', error);
				toast.error('Terjadi kesalahan saat menghapus data');
			}
		};

		toast(
			<DeleteConfirmationToast
				onConfirm={() => {
					handleDelete();
					toast.dismiss();
				}}
				closeToast={() => toast.dismiss()}
			/>
		);
	};


  const checkIndexedDBExists = async (dbName) => {
		const dbs = await window.indexedDB.databases();
		return dbs.some((db) => db.name === dbName);
	};


	useEffect(() => {
    checkIndexedDBExists('RDMSiswa').then((exists) => {
			if (exists) {
				fetchDataSiswa();
			} else {
				Swal.fire({
					title: 'Oops...',
					text: 'Silahkan Sinkron Data RDM Terlebih Dahulu',
					icon: 'error',
					// timer: 10000,
					// timerProgressBar: true
				});
			}
		});
	}, []);



	return (
		<div>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<h4>Pemadanan Data Emis dengan RDM</h4>
			<div className="row">
				<div className="card">
					<div className="col-md-12">
						<div className="card-body">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th rowSpan="2" style={{ backgroundColor: '#00BFFF', verticalAlign: 'middle' }}>
											No
										</th>
										<th colSpan="3" className="text-center" style={{ backgroundColor: '#00BFFF' }}>
											DATA EMIS
										</th>
										<th rowSpan="2" style={{ verticalAlign: 'middle', backgroundColor: '#ADFF2F' }}>
											Action
										</th>
										<th colSpan="3" className="text-center" style={{ backgroundColor: '#00BFFF' }}>
											DATA RDM
										</th>
									</tr>
									<tr>
										<th style={{ backgroundColor: '#00BFFF' }}>Induk </th>
										<th style={{ backgroundColor: '#00BFFF' }}>NISN </th>
										<th style={{ backgroundColor: '#00BFFF' }}>Nama </th>

										<th style={{ backgroundColor: '#00BFFF' }}>No Induk RDM</th>
										<th style={{ backgroundColor: '#00BFFF' }}>NISN RDM</th>
										<th style={{ backgroundColor: '#00BFFF' }}>Nama RDM</th>
									</tr>
								</thead>
								{!loading ? (
									<tbody>
										{data.map((item, index) => (
											<tr key={index}>
												<td style={{ backgroundColor: 'linen' }}>{index + 1}</td>
												<td
													style={{
														backgroundColor:
															item.siswa.local_nis !== item.rdm_nis ? 'red' : 'linen'
													}}
												>
													{item.siswa.local_nis}
												</td>
												<td
													style={{
														backgroundColor:
															item.siswa.nisn !== item.rdm_nisn ? 'red' : 'linen'
													}}
												>
													{item.siswa.nisn}
												</td>
												<td
													style={{
														backgroundColor: 'linen'
													}}
												>
													{item.siswa.full_name}
												</td>
												<td style={{ backgroundColor: '#4B0082', alignItems: 'center' }}>
													{(() => {
														const isMismatch = item.siswa.local_nis !== item.rdm_nis;
														const isMatch = item.siswa.local_nis === item.rdm_nis;
														const isEmptyRdmData =
															Object.keys(item.rdm_full_name).length === 0;
														const isEmptyRdmNisnData =
															Object.keys(item.rdm_nisn).length === 0;

														if (isMismatch && !isEmptyRdmNisnData) {
															return (
																<button
																	className="btn btn-warning"
																	onClick={() => updateEmisData(item)}
																	title="Padankan Data"
																>
																	<i className="fa fa-sync" />
																</button>
															);
														} else if (isEmptyRdmData || isEmptyRdmNisnData) {
															return (
																<button
																	className="btn btn-danger"
																	onClick={() => deleteEmisData(item)}
																	title="Hapus Data"
																>
																	<i className="fa fa-trash" />
																</button>
															);
														} else if (isMatch) {
															return (
																<button className="btn btn-success" title="Valid">
																	<i className="fa fa-check" />
																</button>
															);
														} else {
															return null;
														}
													})()}
												</td>
												<td style={{ backgroundColor: '#8FBC8F' }}>{item.rdm_nis}</td>
												<td style={{ backgroundColor: '#8FBC8F' }}>{item.rdm_nisn}</td>
												<td style={{ backgroundColor: '#8FBC8F' }}>{item.rdm_full_name}</td>
											</tr>
										))}
									</tbody>
								) : (
									<td colSpan="8" className="text-center">
										<div style={{ height: '50px', overflow: 'hidden' }}>
											<h5 className="text-danger">Loading...</h5>
										</div>
									</td>
								)}
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Padankan;
