import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Eye, EyeOff } from "react-feather";
import { Modal, Button } from "react-bootstrap";

const Members = ({ url }) => {
  const URL = url && url;
  const [ambilMember, setAmbilMember] = useState([]);
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);
  const [search, setSearch] = useState("");
  const [showPassword, setShowPassword] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [newMember, setNewMember] = useState({
    name: "",
    nsm: "",
    kontak: "",
    password: "",
    confirmPassword: "",
  });

  const listMember = async (e) => {
    const token = localStorage.getItem("token");
    try {
      const getlistMember = await axios.get(URL + "listusers", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (getlistMember.data.status === true) {
        setAmbilMember(JSON.stringify(getlistMember.data));
      } else {
        setError(getlistMember.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("Data tidak ditemukan!");
      } else {
        setError("Terjadi kesalahan saat mengambil data");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      await listMember();
      setLoad(false);
    };
    fetchData();
  }, []);

  const togglePasswordVisibility = (id) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  let parsedMember = [];
  try {
    parsedMember = ambilMember && JSON.parse(ambilMember).data;
  } catch (error) {}

  // Filter data berdasarkan nilai pencarian
  const filteredData = parsedMember.filter((item) => {
    const fullName = item.name ? item.name.toLowerCase() : "";
    const nsm = item.nsm ? item.nsm.toLowerCase() : "";

    return (
      fullName.includes(search.toLowerCase()) ||
      nsm.includes(search.toLowerCase())
    );
  });

  const handleEdit = (id) => {
    const member = parsedMember.find((item) => item.id === id);
    setSelectedMember(member);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMember(null);
  };

  const handleSaveChanges = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${URL}updateMember/${selectedMember.id}`,
        selectedMember,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Save changes for member:", response.data);
      setShowModal(false);
      // Optionally, refresh the member list
      await listMember();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleRegister = async () => {
    if (newMember.password !== newMember.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      const response = await axios.post(`${URL}register`, newMember);
      console.log("New member registered:", response.data);
      setShowRegisterModal(false);
      // Optionally, refresh the member list
      await listMember();
    } catch (error) {
      console.error("Error registering new member:", error);
    }
  };

  const columns = [
    {
      name: "ACTION",
      cell: (row) => (
        <div>
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => handleEdit(row.id)}
          >
            <i className="feather-edit"></i>
          </button>
          <button
            className="btn btn-outline-success btn-sm"
            // onClick={() => handleEdit(row.id)}
          >
            <i className="feather-shopping-cart"></i>
          </button>
        </div>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "NSM",
      selector: (row) => row.nsm,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Kontak",
      selector: (row) => row.kontak,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Password",
      selector: (row) => (
        <div>
          {showPassword[row.id] ? row.scope : "••••"}
          <span
            onClick={() => togglePasswordVisibility(row.id)}
            style={{ cursor: "pointer", marginLeft: "5px", fontSize: "10px" }}
          >
            {showPassword[row.id] ? <EyeOff /> : <Eye />}
          </span>
        </div>
      ),
      sortable: true,
      minWidth: "180px",
    },
    {
      name: "Is Active",
      selector: (row) => (
        <span
          className={`badge badge-${
            row.is_active === "1"
              ? "success"
              : row.is_active === "0"
              ? "danger"
              : row.is_active === null
              ? "danger"
              : "secondary"
          }`}
        >
          {row.is_active === "1"
            ? "Active"
            : row.is_active === "0"
            ? "Non Active"
            : row.is_active === null
            ? "Blocked"
            : "Non Active"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => (
        <span
          className={`badge badge-${
            row.role_id === "1"
              ? "success"
              : row.role_id === "2"
              ? "primary"
              : row.role_id === "3"
              ? "info"
              : "danger"
          }`}
        >
          {row.role_id === "1"
            ? "Superadmin"
            : row.role_id === "2"
            ? "Member"
            : row.role_id === "3"
            ? "Admin"
            : "Non Active"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Is Admin",
      selector: (row) => (
        <span
          className={`badge badge-${
            row.is_admin === "1" ? "success" : "danger"
          }`}
        >
          {row.role_id === "1" ? "Ya" : "Tidak"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Layanan Active",
      selector: (row) => (
        <span
          className={`badge badge-${
            row.layanan_active === "1" ? "success" : "danger"
          }`}
        >
          {row.layanan_active === "1" ? "Active" : "Non Active"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Package",
      selector: (row) => (
        <span
          className={`badge badge-${
            row.package === "1"
              ? "info"
              : row.package === "2"
              ? "warning"
              : row.package === "3"
              ? "success"
              : "danger"
          }`}
        >
          {row.package === "1"
            ? "Paket 1"
            : row.package === "2"
            ? "Paket 2"
            : row.package === "3"
            ? "Paket 3"
            : "Non Active"}
        </span>
      ),
      sortable: true,
    },
  ];

  return (
    <div>
      <h5>List Members</h5>

      <div className="row mb-3">
        <div className="table-responsive table-striped">
          {load ? (
            <div className="text-center py-5">
              <p>Loading...</p>
            </div>
          ) : error ? (
            <div className="text-center py-5">
              <p className="text-danger">{error}</p>
            </div>
          ) : filteredData.length > 0 ? (
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={10}
              subHeader
              subHeaderComponent={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    flexDirection: "row-reverse", // Reverse the order of the elements
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="form-control form-sm mt-3"
                    style={{ width: "200px" }} // Menyesuaikan ukuran input
                  />
                  <Button
                    variant="primary"
                    className="btn btn-primary btn-sm mt-3"
                    onClick={() => setShowRegisterModal(true)}
                  >
                    Register New Member
                  </Button>
                </div>
              }
            />
          ) : (
            <div>Data tidak ditemukan</div>
          )}
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMember && (
            <div>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedMember.name}
                  onChange={(e) =>
                    setSelectedMember({
                      ...selectedMember,
                      name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label>NSM</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedMember.nsm}
                  onChange={(e) =>
                    setSelectedMember({
                      ...selectedMember,
                      nsm: e.target.value,
                    })
                  }
                />
              </div>

              <div className="form-group">
                <label>Is Active</label>
                <select
                  className="form-control"
                  value={selectedMember.is_active}
                  onChange={(e) =>
                    setSelectedMember({
                      ...selectedMember,
                      is_active: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    {selectedMember.is_active === "1"
                      ? "Active"
                      : selectedMember.is_active === "0"
                      ? "Non Active"
                      : "Blocked"}
                  </option>
                  <option value="1">Active</option>
                  <option value="0">Non Active</option>
                  <option value="null">Blocked</option>
                </select>
              </div>
              <div className="form-group">
                <label>Role</label>
                <select
                  className="form-control"
                  value={selectedMember.role_id}
                  onChange={(e) =>
                    setSelectedMember({
                      ...selectedMember,
                      role_id: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    {selectedMember.role_id === "1"
                      ? "Superadmin"
                      : selectedMember.role_id === "2"
                      ? "Member"
                      : "Admin"}
                  </option>
                  <option value="1">Superadmin</option>
                  <option value="2">Member</option>
                  <option value="3">Admin</option>
                </select>
              </div>
              <div className="form-group">
                <label>Is Admin</label>
                <select
                  className="form-control"
                  value={selectedMember.is_admin}
                  onChange={(e) =>
                    setSelectedMember({
                      ...selectedMember,
                      is_admin: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    {selectedMember.is_admin === ""
                      ? "Tidak"
                      : selectedMember.is_admin === "1"
                      ? "Ya"
                      : "Tidak"}
                  </option>
                  <option value=""></option>
                  <option value="1">Ya</option>
                  <option value="0">Tidak</option>
                </select>
              </div>
              <div className="form-group">
                <label>Layanan Active</label>
                <select
                  className="form-control"
                  value={selectedMember.layanan_active}
                  onChange={(e) =>
                    setSelectedMember({
                      ...selectedMember,
                      layanan_active: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    {selectedMember.layanan_active === "1"
                      ? "Active"
                      : "Non Active"}
                  </option>
                  <option value=""></option>
                  <option value="1">Active</option>
                  <option value="0">Non Active</option>
                </select>
              </div>
              <div className="form-group">
                <label>Package</label>
                <select
                  className="form-control"
                  value={selectedMember.package}
                  onChange={(e) =>
                    setSelectedMember({
                      ...selectedMember,
                      package: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    {selectedMember.package === "1"
                      ? "Paket 1"
                      : selectedMember.package === "2"
                      ? "Paket 2"
                      : "Paket 3"}
                  </option>
                  <option value="">None</option>
                  <option value="1">Paket 1</option>
                  <option value="2">Paket 2</option>
                  <option value="3">Paket 3</option>
                </select>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showRegisterModal}
        onHide={() => setShowRegisterModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Register New Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={newMember.name}
              onChange={(e) =>
                setNewMember({ ...newMember, name: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>NSM</label>
            <input
              type="text"
              className="form-control"
              value={newMember.nsm}
              onChange={(e) =>
                setNewMember({ ...newMember, nsm: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              value={newMember.password}
              onChange={(e) =>
                setNewMember({ ...newMember, password: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              className="form-control"
              value={newMember.confirmPassword}
              onChange={(e) =>
                setNewMember({ ...newMember, confirmPassword: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Kontak</label>
            <input
              type="text"
              className="form-control"
              value={newMember.kontak}
              onChange={(e) =>
                setNewMember({ ...newMember, kontak: e.target.value })
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowRegisterModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleRegister}>
            Register
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Members;
