import React, { useState, useEffect } from "react";
import axios from "axios";

const Tutorial = ({ url }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tutorial, setTutorial] = useState([]);

  const URL = url;

  const fetchTutorial = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}tutorial`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setTutorial(response.data.data);
      } else {
        setTutorial([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTutorial();
  }, []);

  return (
    <div>
      <h5>Demo / Tutorial</h5>

      <div className="row">
        {isLoading ? (
          <tr>
            <td colSpan="2">Loading...</td>
          </tr>
        ) : tutorial.length > 0 ? (
          tutorial.map((tutorial, index) => (
            <div className="col-lg-6" key={index}>
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title">{tutorial.judul}</h4>

                  <div className="ratio ratio-4x3">
                    <iframe src={tutorial.video} allowFullScreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <tr>
            <td colSpan="2">No data available</td>
          </tr>
        )}
      </div>
    </div>
  );
};

export default Tutorial;
