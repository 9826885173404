import React from "react";

const HasilTools = ({ datanisnemis }) => {
  return (
    <div className="row mt-3">
      <div className="card-header">
        <h5 className="card-title">Hasil Pencarian</h5>
      </div>
      <div className="card-body">
        <h5 className="card-title">Identitas</h5>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>NISN</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].nisn}
              />
            </div>
            <div className="form-group">
              <label>Nama Siswa</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].full_name}
              />
            </div>
            <div className="form-group">
              <label>Tempat Lahir</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].birth_place}
              />
            </div>
            <div className="form-group">
              <label>Tanggal Lahir</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].birth_date}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>NIK</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].nik}
              />
            </div>
            <div className="form-group">
              <label>Jenis Kelamin</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].gender_name}
              />
            </div>
            <div className="form-group">
              <label>Alamat</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].address}
              />
            </div>
          </div>
        </div>
        <h5 className="card-title">Status Keaktifan</h5>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>NSM Lembaga</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].institution_nsm}
              />
            </div>
            <div className="form-group">
              <label>Nama Lembaga</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].institution_name}
              />
            </div>
            <div className="form-group">
              <label>Kelas</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].level_name}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Status</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].status_description_name}
              />
            </div>
            <div className="form-group">
              <label>Tahun Pelajaran</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis[0].academic_year}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HasilTools;
