import React from "react";

const Foot = () => {
  return (
    <div>
      <script src="/assets_mobile/js/jquery-3.6.1.min.js"></script>
      <script src="/assets_mobile/js/bootstrap.bundle.min.js"></script>
      <script src="/assets_mobile/js/jquery-ui.js"></script>
      <script src="/assets_mobile/js/mixitup.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.3/gsap.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.3/Draggable.min.js"></script>
      <script src="/assets_mobile/js/swiper-bundle.min.js"></script>
      <script src="/assets_mobile/js/jquery.datetimepicker.full.js"></script>
      <script
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCodvr4TmsTJdYPjs_5PWLPTNLA9uA4iq8&callback=initMap"
        type="text/javascript"
      ></script>
      <script src="/assets_mobile/js/script.js"></script>
    </div>
  );
};

export default Foot;
