import React, { useEffect } from "react";
import Header from "../components/templates/Header";
import Sidebar from "../components/templates/Sidebar";
import Footer from "../components/templates/Footer";
import jquery from "jquery";
const $ = jquery;

const Layouts = ({ children, url }) => {
  return (
    <div className="main-wrapper">
      <React.Fragment>
        <Header />
        <Sidebar url={url} />
        <div
          className="page-wrapper mb-3"
          style={{ overflowY: "auto", height: "100vh" }}
        >
          <main className="px-3 py-3">{children}</main>
        </div>
        <Footer />
      </React.Fragment>
    </div>
  );
};

export default Layouts;
