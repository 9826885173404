import React from "react";
import Layouts from "./Layouts";

const Notfound = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <div className="error-box">
          <h1>404</h1>
          <h3 className="h2 mb-3">
            <i className="fas fa-exclamation-triangle"></i> Oops! Page Not
            Found!
          </h3>
          <p className="h4 font-weight-normal">
            Halaman yang Anda cari tidak ditemukan.
          </p>
          <a href="#" className="btn btn-danger">
            BERTOBATLAH
          </a>
        </div>
      </Layouts>
    </div>
  );
};

export default Notfound;
