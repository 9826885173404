import React from 'react';

const DeleteConfirmationToast = ({ closeToast, onConfirm }) => (
	<div>
		<p>Apakah Anda yakin ingin menghapus data ini?</p>
		<button onClick={onConfirm} className="btn btn-danger" style={{ marginRight: '10px' }}>
			Hapus
		</button>
		<button onClick={closeToast} className="btn btn-secondary">
			Batal
		</button>
	</div>
);

export default DeleteConfirmationToast;
