import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { LoginUser, reset } from "../features/authSlice";
import { Eye, EyeOff } from "react-feather";

const Login = () => {
  const [nsm, setNsm] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user || isSuccess) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [user, isSuccess, navigate, dispatch]);

  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginUser({ nsm, password }));
  };

  return (
    <div className="main-wrapper login-body">
      <div className="login-wrapper">
        <div className="container">
          <div className="loginbox">
            <div className="login-left">
              <img
                className="img-fluid"
                src="assets/img/login.png"
                alt="Logo"
              />
            </div>
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>Welcome to</h1>
                <img
                  className="img-fluid"
                  src="assets/img/logo.png"
                  alt="Logo"
                />
                <p className="account-subtitle">
                  Belum punya akun?{" "}
                  <span>
                    <a href="/register">Register</a>
                  </span>
                </p>
                <h2>Silahkan Login</h2>
                {isError && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <strong>{message}</strong>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                )}

                <form onSubmit={Auth}>
                  <div className="form-group">
                    <label>
                      NSM <span className="login-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={nsm}
                      onChange={(e) => setNsm(e.target.value)}
                      placeholder="Masukkan NSM"
                      required
                    />
                    <span className="profile-views">
                      <i className="fas fa-user-circle"></i>
                    </span>
                  </div>
                  <div className="form-group">
                    <label>
                      Password <span className="login-danger">*</span>
                    </label>

                    <input
                      className="form-control pass-input"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="******"
                      required
                    />

                    <span
                      className="profile-views"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
                    </span>
                  </div>
                  {/* <div className="forgotpass">
                    <a href="#">Forgot Password?</a>
                  </div> */}
                  <div className="form-group">
                    <button className="btn btn-primary btn-block" type="submit">
                      {isLoading ? "Loading..." : "Login"}
                    </button>
                  </div>
                </form>

                <div className="login-or">
                  <span className="or-line"></span>
                  <span>Akses Login Siswa</span>
                </div>

                <div className="social-login">
                  <a href="#">
                    <i className="fab fa-google-play"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-desktop"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
