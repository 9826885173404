import React from "react";
import Layouts from "./Layouts";
import CetakInvoice from "../components/inv/CetakInvoice";

const Invoice = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <CetakInvoice url={url} />
      </Layouts>
    </div>
  );
};

export default Invoice;
