import React, { useEffect, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";



const Reference = ({ url }) => {
  const URL = url;

  const [paket, setPaket] = useState([]);
  const [fbd, setFbd] = useState([]);
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  const [idEmis, setIdEmis] = useState("");
  const [loading, setLoading] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [encoded, setEncoded] = useState("");


  const fetchMe = async () => {
    const response = await axios.get(URL + "me", {
      headers: { Authorization: `Bearer ${token}` },
    });
    setPaket(response.data.user.package);
    setFbd(response.data.user.fbd);
  };

  useEffect(() => {
    const checkInstitution = setInterval(() => {
      fetchMe();
    }, 2000); // Check every second

    return () => clearInterval(checkInstitution); // Cleanup interval on component unmount
  }, []);

  const syncAcademicYear = async () => {
    const token = localStorage.getItem("token");
    const bear = localStorage.getItem("bear");
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${URL}getacademicyearemis`,
        {
          bear: bear,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };



  const convertId = async (e) => {
    e.preventDefault();
    
    
    const length = idEmis.length;
    // setDataSiswa(JSON.stringify(response && response.data));
    console.log("length", length)

    const cryptoKey = "a2c36eb2w1em50d6665dc5d61a68b400";
    const Base64IVkeys = "emisBase64IVkeys";
    const fkey = CryptoJS.enc.Utf8.parse(cryptoKey);

    // security config
    const securityConfig = () => {
      const fiv = CryptoJS.enc.Utf8.parse(Base64IVkeys);
      return {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      };
    };

    const idString = idEmis.toString();

    const encrypted = CryptoJS.AES.encrypt(
      idString, // Ensure this is a string
      fkey,
      securityConfig()
    ).toString();
    const base64encoded = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(encrypted)
    );

    setEncoded(base64encoded)

  }



  return (
    <div>
      <h4>System Reference EMIS</h4>
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <table
              className="table table-striped table-bordered table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>Items</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Academic Year</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={syncAcademicYear}
                    >
                      Sync
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h5>Converter ID</h5>
            <form onSubmit={convertId}>
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                value={idEmis}
                onChange={(e) => setIdEmis(e.target.value)}
                required
              />

              {loading === true ? (
                <button className="btn btn-danger me-2" type="button" disabled>
                  <span
                    className={`${
                      skeleton ? "spinner-border spinner-border-sm" : ""
                    }`}
                  ></span>
                  <span className="text-dark"> Loading...</span>
                </button>
              ) : (
                <button className="btn btn-primary me-2" type="submit">
                  Convert
                </button>
              )}
            </div>
          </form>
          {encoded && (
            <p>Hasil: {encoded}</p>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reference;
