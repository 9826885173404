import React from "react";
import Head from "../t_Mobile/Head";
import Splash from "../t_Mobile/Splash";
import Top from "../t_Mobile/Top";
import Foot from "../t_Mobile/Foot";
import MenuBawah from "../t_Mobile/MenuBawah";

const Layouts = ({ children }) => {
  return (
    <div>
      <React.Fragment>
        <Head />
        <div className="scrollbar-hidden">
          {/* <Splash /> */}
          <main className="home">
            <Top />
            {children}
          </main>
          <MenuBawah />
          <Foot />
        </div>
      </React.Fragment>
    </div>
  );
};

export default Layouts;
