import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const HasilTarikPaksa = ({ datanisnemis, url, fbd }) => {
  const [nisn, setNisn] = useState(datanisnemis.nisn);
  const [name, setName] = useState(datanisnemis.nama);
  const [tempat, setTempat] = useState(datanisnemis.tempat_lahir);
  const [tanggal, setTanggal] = useState(datanisnemis.tanggal_lahir);
  const [nik, setNik] = useState(datanisnemis.nik);
  const [jenis, setJenis] = useState(datanisnemis.jenis_kelamin);
  const [namaAyah, setNamaAyah] = useState("");
  const [nikAyah, setNikAyah] = useState("");
  const [tempatLahirAyah, setTempatLahirAyah] = useState("");
  const [tanggalLahirAyah, setTanggalLahirAyah] = useState("");
  const [namaIbu, setNamaIbu] = useState(datanisnemis.nama_ibu_kandung);
  const [nikIbu, setNikIbu] = useState("");
  const [tempatLahirIbu, setTempatLahirIbu] = useState("");
  const [tanggalLahirIbu, setTanggalLahirIbu] = useState("");
  const [kelas, setKelas] = useState(datanisnemis.tingkat_pendidikan);
  const [sekolah, setSekolah] = useState(datanisnemis.sekolah);
  const [npsn, setNpsn] = useState(datanisnemis.npsn);
  const [bearer, setBearer] = useState(localStorage.getItem("bear"));
  const [pdid, setPdid] = useState(datanisnemis.peserta_didik_id);

  const [loading, setLoading] = useState(false);

  const URL = url && url;

  const handleSaveChanges = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (fbd === 0) {
      const push = await axios.post(
        URL + "tarikpaksa",
        {
          nisn: nisn,
          nama: name,
          tempat_lahir: tempat,
          tanggal_lahir: tanggal,
          nik: nik,
          jenis_kelamin: jenis,
          kelas: kelas,
          sekolah: sekolah,
          npsn: npsn,
          nama_ayah: namaAyah,
          nik_ayah: nikAyah,
          tempat_lahir_ayah: tempatLahirAyah,
          tanggal_lahir_ayah: tanggalLahirAyah,
          nama_ibu_kandung: namaIbu,
          nik_ibu: nikIbu,
          tempat_lahir_ibu: tempatLahirIbu,
          tanggal_lahir_ibu: tanggalLahirIbu,
          peserta_didik_id: pdid,
          bear: localStorage.getItem("bear"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(push);
      if (push.data.success === true) {
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Data siswa berhasil ditarik!",
        });
      } else {
        // console.log("Gagal");
        // console.log(push.data.errors.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: Array.isArray(push.data.errors.message)
            ? push.data.errors.message.join("<br>")
            : `${JSON.stringify(
                push.data.errors.message,
                null,
                2
              )}<hr> <b>Jika terjadi error hubungi WA 081222374440. Jangan di share di group WA BIMA SAKTI!</b>`,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Illegal functions are not allowed (Fungsi ini tidak diizinkan!)",
      });
    }
    setLoading(false);
  };

  return (
    <div className="row mt-3">
      <div className="card-header">
        <h5 className="card-title">Hasil Pencarian NISN</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <h5 className="card-title">Identitas Siswa</h5>
            <div className="form-group">
              <label>NISN</label>
              <input
                type="text"
                className="form-control"
                value={nisn}
                onChange={(e) => setNisn(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Nama Siswa</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Tempat Lahir</label>
              <input
                type="text"
                className="form-control"
                value={tempat}
                onChange={(e) => setTempat(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Tanggal Lahir</label>
              <input
                type="date"
                className="form-control"
                value={tanggal}
                onChange={(e) => setTanggal(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>NIK Siswa</label>
              <input
                type="text"
                className="form-control"
                value={nik}
                onChange={(e) => setNik(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Jenis Kelamin</label>
              <input
                type="text"
                className="form-control"
                value={jenis}
                onChange={(e) => setJenis(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Kelas (Masukkan Kelas Saat Ini)</label>
              <input
                type="text"
                className="form-control"
                value={kelas}
                onChange={(e) => setKelas(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Sekolah</label>
              <input
                type="text"
                className="form-control"
                value={sekolah}
                onChange={(e) => setSekolah(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>NPSN</label>
              <input
                type="text"
                className="form-control"
                value={npsn}
                onChange={(e) => setNpsn(e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <h5 className="card-title">Data Orang Tua</h5>
            <div className="form-group">
              <label>Nama Ayah</label>
              <input
                type="text"
                className="form-control"
                value={namaAyah}
                onChange={(e) => setNamaAyah(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>NIK Ayah</label>
              <input
                type="text"
                className="form-control"
                value={nikAyah}
                onChange={(e) => setNikAyah(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Tempat Lahir Ayah</label>
              <input
                type="text"
                className="form-control"
                value={tempatLahirAyah}
                onChange={(e) => setTempatLahirAyah(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Tanggal Lahir Ayah</label>
              <input
                type="date"
                className="form-control"
                value={tanggalLahirAyah}
                onChange={(e) => setTanggalLahirAyah(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Nama Ibu Kandung</label>
              <input
                type="text"
                className="form-control"
                value={namaIbu}
                onChange={(e) => setNamaIbu(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>NIK Ibu</label>
              <input
                type="text"
                className="form-control"
                value={nikIbu}
                onChange={(e) => setNikIbu(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Tempat Lahir Ibu</label>
              <input
                type="text"
                className="form-control"
                value={tempatLahirIbu}
                onChange={(e) => setTempatLahirIbu(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Tanggal Lahir Ibu</label>
              <input
                type="date"
                className="form-control"
                value={tanggalLahirIbu}
                onChange={(e) => setTanggalLahirIbu(e.target.value)}
              />
            </div>
          </div>
        </div>
        <button
          className="btn btn-primary"
          onClick={handleSaveChanges}
          disabled={loading}
        >
          {loading ? "Loading..." : "Sikat"}
        </button>
      </div>
    </div>
  );
};

export default HasilTarikPaksa;
