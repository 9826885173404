import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Modal, Button } from "react-bootstrap"; // Assuming you're using react-bootstrap
import Swal from "sweetalert2";

const UploadDataOrtu = ({ url }) => {
  const URL = url;
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [skeletonUpload, setSkeletonUpload] = useState(false);
  const [ambilOrtu, setAmbilOrtu] = useState([]);
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
  const [redCellCount, setRedCellCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [loadingKirim, setLoadingKirim] = useState(false);
  const [skeletonKirim, setSkeletonKirim] = useState(false);

  const [status, setStatus] = useState([]);
  const [pendidikan, setPendidikan] = useState([]);
  const [pekerjaan, setPekerjaan] = useState([]);
  const [penghasilan, setPenghasilan] = useState([]);
  const [punyaHp, setPunyaHp] = useState([]);

  //  Referensi Data
  const statusOrtu = async (e) => {
    const token = localStorage.getItem("token");
    const status = await axios.get(URL + "status", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setStatus(JSON.stringify(status.data));
  };
  const pendidikanOrtu = async (e) => {
    const token = localStorage.getItem("token");
    const pendidikan = await axios.get(URL + "pendidikan", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setPendidikan(JSON.stringify(pendidikan.data));
  };
  const pekerjaanOrtu = async (e) => {
    const token = localStorage.getItem("token");
    const pekerjaan = await axios.get(URL + "pekerjaan", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setPekerjaan(JSON.stringify(pekerjaan.data));
  };
  const penghasilanOrtu = async (e) => {
    const token = localStorage.getItem("token");
    const penghasilan = await axios.get(URL + "penghasilan", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setPenghasilan(JSON.stringify(penghasilan.data));
  };
  const punyaHpRef = async (e) => {
    const token = localStorage.getItem("token");
    const punyaHp = await axios.get(URL + "punyahp", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setPunyaHp(JSON.stringify(punyaHp.data));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      await listUploadOrtu();
      await statusOrtu();
      await pendidikanOrtu();
      await pekerjaanOrtu();
      await penghasilanOrtu();
      await punyaHpRef();
      setLoad(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    calculateRedCellCount();
  }, [ambilOrtu, search]);

  const calculateRedCellCount = () => {
    let count = 0;
    let parsedOrtu = [];
    let parsedStatus = [];
    let parsedPendidikan = [];
    let parsedPekerjaan = [];
    let parsedPenghasilan = [];
    let parsedPunyaHp = [];

    try {
      parsedOrtu = ambilOrtu && JSON.parse(ambilOrtu).data;
      parsedStatus = status && JSON.parse(status).data;
      parsedPendidikan = pendidikan && JSON.parse(pendidikan).data;
      parsedPekerjaan = pekerjaan && JSON.parse(pekerjaan).data;
      parsedPenghasilan = penghasilan && JSON.parse(penghasilan).data;
      parsedPunyaHp = punyaHp && JSON.parse(punyaHp).data;
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    parsedOrtu.forEach((row) => {
      // AYAH
      if (!row.father_full_name) count++;
      if (
        !row.father_m_life_status_id ||
        !parsedStatus.find(
          (status) => status.kode === row.father_m_life_status_id
        )
      )
        count++;
      if (!row.father_nik || row.father_nik.length !== 16) count++;
      if (!row.father_birth_place) count++;
      if (!row.father_birth_date) count++;
      if (
        !row.father_m_last_education_id ||
        !parsedPendidikan.find(
          (pendidikan) => pendidikan.kode === row.father_m_last_education_id
        )
      )
        count++;
      if (
        !row.father_m_job_id ||
        !parsedPekerjaan.find(
          (pekerjaan) => pekerjaan.kode === row.father_m_job_id
        )
      )
        count++;
      if (
        !row.father_m_average_income_per_month_id ||
        !parsedPenghasilan.find(
          (penghasilan) =>
            penghasilan.kode === row.father_m_average_income_per_month_id
        )
      )
        count++;
      if (
        !row.is_father_phone_null ||
        !parsedPunyaHp.find(
          (punyaHp) => punyaHp.kode === row.is_father_phone_null
        )
      )
        count++;
      if (
        (row.is_father_phone_null === "0" &&
          row.father_phone_number.substr(0, 3) !== "628") ||
        (row.is_father_phone_null === "0" && !row.father_phone_number)
      )
        count++;
      if (
        !row.father_nationality ||
        (row.father_nationality !== "wni" && row.father_nationality !== "wna")
      )
        count++;
      // IBU
      if (!row.mother_full_name) count++;
      if (
        !row.mother_m_life_status_id ||
        !parsedStatus.find(
          (status) => status.kode === row.mother_m_life_status_id
        )
      )
        count++;
      if (!row.mother_nik || row.mother_nik.length !== 16) count++;
      if (!row.mother_birth_place) count++;
      if (!row.mother_birth_date) count++;
      if (
        !row.mother_m_last_education_id ||
        !parsedPendidikan.find(
          (pendidikan) => pendidikan.kode === row.mother_m_last_education_id
        )
      )
        count++;
      if (
        !row.mother_m_job_id ||
        !parsedPekerjaan.find(
          (pekerjaan) => pekerjaan.kode === row.mother_m_job_id
        )
      )
        count++;
      if (
        !row.mother_m_average_income_per_month_id ||
        !parsedPenghasilan.find(
          (penghasilan) =>
            penghasilan.kode === row.mother_m_average_income_per_month_id
        )
      )
        count++;
      if (
        !row.is_mother_phone_null ||
        !parsedPunyaHp.find(
          (punyaHp) => punyaHp.kode === row.is_mother_phone_null
        )
      )
        count++;
      if (
        (row.is_mother_phone_null === "0" &&
          row.mother_phone_number.substr(0, 3) !== "628") ||
        (row.is_mother_phone_null === "0" && !row.mother_phone_number)
      )
        count++;
      if (
        !row.mother_nationality ||
        (row.mother_nationality !== "wni" && row.mother_nationality !== "wna")
      )
        count++;
      if (
        !row.is_kk_same_father ||
        (row.is_kk_same_father !== "0" && row.is_kk_same_father !== "1")
      )
        count++;

      // WALI
      if (
        !row.wali ||
        (row.wali !== "Sama dengan ayah kandung" &&
          row.wali !== "Sama dengan ibu kandung" &&
          row.wali !== "Lainnya")
      )
        count++;
      if (!row.wali_full_name) count++;
      if (
        !row.wali_m_life_status_id ||
        !parsedStatus.find(
          (status) => status.kode === row.wali_m_life_status_id
        )
      )
        count++;
      if (!row.wali_nik || row.wali_nik.length !== 16) count++;
      if (!row.wali_birth_place) count++;
      if (!row.wali_birth_date) count++;
      if (
        !row.wali_m_last_education_id ||
        !parsedPendidikan.find(
          (pendidikan) => pendidikan.kode === row.wali_m_last_education_id
        )
      )
        count++;
      if (
        !row.wali_m_job_id ||
        !parsedPekerjaan.find(
          (pekerjaan) => pekerjaan.kode === row.wali_m_job_id
        )
      )
        count++;
      if (
        !row.wali_m_average_income_per_month_id ||
        !parsedPenghasilan.find(
          (penghasilan) =>
            penghasilan.kode === row.wali_m_average_income_per_month_id
        )
      )
        count++;
      if (
        !row.is_wali_phone_null ||
        !parsedPunyaHp.find(
          (punyaHp) => punyaHp.kode === row.is_wali_phone_null
        )
      )
        count++;
      if (
        (row.is_wali_phone_null === "0" &&
          row.wali_phone_number.substr(0, 3) !== "628") ||
        (row.is_wali_phone_null === "0" && !row.wali_phone_number)
      )
        count++;
      if (
        !row.wali_nationality ||
        (row.wali_nationality !== "wni" && row.wali_nationality !== "wna")
      )
        count++;
    });
    setRedCellCount(count);
  };

  const columns = [
    {
      name: "ACTION",
      cell: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDelete(row.kode_enkripsi)}
        >
          <i className="feather-trash-2"></i>
        </button>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "NAMA SISWA",
      selector: (row) => row.full_name,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.full_name,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NAMA AYAH",
      selector: (row) => row.father_full_name,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.father_full_name,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "STATUS AYAH",
      selector: (row) => row.father_m_life_status_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.father_m_life_status_id ||
            !parsedStatus.find(
              (status) => status.kode === row.father_m_life_status_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NIK AYAH",
      selector: (row) => row.father_nik,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.father_nik || row.father_nik.length !== 16,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "TMP LAHIR AYAH",
      selector: (row) => row.father_birth_place,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.father_birth_place,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "TGL LAHIR AYAH",
      selector: (row) => row.father_birth_date,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.father_birth_date,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PENDIDIKAN AYAH",
      selector: (row) => row.father_m_last_education_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.father_m_last_education_id ||
            !parsedPendidikan.find(
              (pendidikan) => pendidikan.kode === row.father_m_last_education_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PEKERJAAAN AYAH",
      selector: (row) => row.father_m_job_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.father_m_job_id ||
            !parsedPekerjaan.find(
              (pekerjaan) => pekerjaan.kode === row.father_m_job_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PENGHASILAN AYAH",
      selector: (row) => row.father_m_average_income_per_month_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.father_m_average_income_per_month_id ||
            !parsedPenghasilan.find(
              (penghasilan) =>
                penghasilan.kode === row.father_m_average_income_per_month_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "AYAH PUNYA HP?",
      selector: (row) => row.is_father_phone_null,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.is_father_phone_null ||
            !parsedPunyaHp.find(
              (punyaHp) => punyaHp.kode === row.is_father_phone_null
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NO HP AYAH",
      selector: (row) => row.father_phone_number,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            (row.is_father_phone_null === "0" &&
              row.father_phone_number.substr(0, 3) !== "628") ||
            (row.is_father_phone_null === "0" && !row.father_phone_number),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "KEWARGANEGARAAN AYAH",
      selector: (row) => row.father_nationality,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.father_nationality ||
            (row.father_nationality !== "wni" &&
              row.father_nationality !== "wna"),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NAMA IBU",
      selector: (row) => row.mother_full_name,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.mother_full_name,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "STATUS IBU",
      selector: (row) => row.mother_m_life_status_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.mother_m_life_status_id ||
            !parsedStatus.find(
              (status) => status.kode === row.mother_m_life_status_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NIK IBU",
      selector: (row) => row.mother_nik,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.mother_nik || row.mother_nik.length !== 16,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "TMP LAHIR IBU",
      selector: (row) => row.mother_birth_place,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.mother_birth_place,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "TGL LAHIR IBU",
      selector: (row) => row.mother_birth_date,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.mother_birth_date,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PENDIDIKAN IBU",
      selector: (row) => row.mother_m_last_education_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.mother_m_last_education_id ||
            !parsedPendidikan.find(
              (pendidikan) => pendidikan.kode === row.mother_m_last_education_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PEKERJAAAN IBU",
      selector: (row) => row.mother_m_job_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.mother_m_job_id ||
            !parsedPekerjaan.find(
              (pekerjaan) => pekerjaan.kode === row.mother_m_job_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PENGHASILAN IBU",
      selector: (row) => row.mother_m_average_income_per_month_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.mother_m_average_income_per_month_id ||
            !parsedPenghasilan.find(
              (penghasilan) =>
                penghasilan.kode === row.mother_m_average_income_per_month_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "IBU PUNYA HP?",
      selector: (row) => row.is_mother_phone_null,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.is_mother_phone_null ||
            !parsedPunyaHp.find(
              (punyaHp) => punyaHp.kode === row.is_mother_phone_null
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NO HP IBU",
      selector: (row) => row.mother_phone_number,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            (row.is_mother_phone_null === "0" &&
              row.mother_phone_number.substr(0, 3) !== "628") ||
            (row.is_mother_phone_null === "0" && !row.mother_phone_number),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "KEWARGANEGARAAN IBU",
      selector: (row) => row.mother_nationality,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.mother_nationality ||
            (row.mother_nationality !== "wni" &&
              row.mother_nationality !== "wna"),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "KK SAMA DENGAN AYAH",
      selector: (row) => row.is_kk_same_father,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.is_kk_same_father ||
            (row.is_kk_same_father !== "0" && row.is_kk_same_father !== "1"),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "WALI",
      selector: (row) => row.wali,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.wali ||
            (row.wali !== "Sama dengan ayah kandung" &&
              row.wali !== "Sama dengan ibu kandung" &&
              row.wali !== "Lainnya"),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NAMA WALI",
      selector: (row) => row.wali_full_name,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.wali_full_name,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "STATUS WALI",
      selector: (row) => row.wali_m_life_status_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.wali_m_life_status_id ||
            !parsedStatus.find(
              (status) => status.kode === row.wali_m_life_status_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NIK WALI",
      selector: (row) => row.wali_nik,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.wali_nik || row.wali_nik.length !== 16,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "TMP LAHIR WALI",
      selector: (row) => row.wali_birth_place,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.wali_birth_place,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "TGL LAHIR WALI",
      selector: (row) => row.wali_birth_date,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.wali_birth_date,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PENDIDIKAN WALI",
      selector: (row) => row.wali_m_last_education_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.wali_m_last_education_id ||
            !parsedPendidikan.find(
              (pendidikan) => pendidikan.kode === row.wali_m_last_education_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PEKERJAAN WALI",
      selector: (row) => row.wali_m_job_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.wali_m_job_id ||
            !parsedPekerjaan.find(
              (pekerjaan) => pekerjaan.kode === row.wali_m_job_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PENGHASILAN WALI",
      selector: (row) => row.wali_m_average_income_per_month_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.wali_m_average_income_per_month_id ||
            !parsedPenghasilan.find(
              (penghasilan) =>
                penghasilan.kode === row.wali_m_average_income_per_month_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "WALI PUNYA HP?",
      selector: (row) => row.is_wali_phone_null,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.is_wali_phone_null ||
            !parsedPunyaHp.find(
              (punyaHp) => punyaHp.kode === row.is_wali_phone_null
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NO HP WALI",
      selector: (row) => row.wali_phone_number,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            (row.is_wali_phone_null === "0" &&
              row.wali_phone_number.substr(0, 3) !== "628") ||
            (row.is_wali_phone_null === "0" && !row.wali_phone_number),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "KEWARGANEGARAAN WALI",
      selector: (row) => row.wali_nationality,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.wali_nationality ||
            (row.wali_nationality !== "wni" && row.wali_nationality !== "wna"),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
  ];

  let parsedOrtu = [];
  let parsedStatus = [];
  let parsedPendidikan = [];
  let parsedPekerjaan = [];
  let parsedPenghasilan = [];
  let parsedPunyaHp = [];

  try {
    parsedOrtu = ambilOrtu && JSON.parse(ambilOrtu).data;
    parsedStatus = status && JSON.parse(status).data;
    parsedPendidikan = pendidikan && JSON.parse(pendidikan).data;
    parsedPekerjaan = pekerjaan && JSON.parse(pekerjaan).data;
    parsedPenghasilan = penghasilan && JSON.parse(penghasilan).data;
    parsedPunyaHp = punyaHp && JSON.parse(punyaHp).data;
  } catch (error) {
    // console.error("Error parsing JSON:", error);
  }

  // Filter data berdasarkan nilai pencarian
  const filteredData = parsedOrtu.filter((item) => {
    const fullName = item.full_name ? item.full_name.toLowerCase() : "";

    return fullName.includes(search.toLowerCase());
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoad(true);
  //     await listUploadOrtu();
  //     await statusOrtu();
  //     await pendidikanOrtu();
  //     await pekerjaanOrtu();
  //     await penghasilanOrtu();
  //     await punyaHpRef();
  //     setLoad(false);
  //   };
  //   fetchData();
  // }, []);

  // Fetch Data
  const listUploadOrtu = async (e) => {
    const token = localStorage.getItem("token");

    try {
      const getlistOrtu = await axios.post(
        URL + "get_ortu_upload",
        {
          nsm: localStorage.getItem("nsm"),
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getlistOrtu.data.status === true) {
        setAmbilOrtu(JSON.stringify(getlistOrtu.data));
      } else {
        setError(getlistOrtu.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError(
          "History upload template ortu tidak ditemukan, Silahkan upload template ortu terlebih dahulu !"
        );
      } else {
        setError("Terjadi kesalahan saat mengambil data");
      }
    }
  };

  // Download Template
  const downloadTemplate = async () => {
    setLoading(true);
    setSkeleton(true);

    const token = localStorage.getItem("token");
    try {
      const response = await axios({
        url: URL + "download_template_ortu", // Adjust the URL as needed
        method: "POST",
        data: {
          nsm: localStorage.getItem("nsm"),
        },
        responseType: "blob", // Important for file download
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1]
        : "template_siswa.xlsx";
      const validExtensions = [".xlsx", ".xls"];
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));

      if (!validExtensions.includes(fileExtension)) {
        throw new Error("Invalid file extension");
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setLoading(false);
      setSkeleton(false);
    }
  };

  const validasi = async () => {
    calculateRedCellCount();
  };

  // UPLOAD TEMPLATE
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadTemplate = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const validExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name.slice(file.name.lastIndexOf("."));
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validExtensions.includes(fileExtension)) {
      alert("Invalid file type. Please upload an Excel file.");
      return;
    }

    if (file.size > maxSize) {
      alert("File size exceeds the limit of 5MB.");
      return;
    }

    setLoadingUpload(true);
    setSkeletonUpload(true);

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("nsm", localStorage.getItem("nsm"));

    try {
      setShowModal(false);
      await axios.post(URL + "upload_template_ortu", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      await listUploadOrtu();
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Template siswa berhasil diupload!",
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file.");
    } finally {
      await statusOrtu();
      await pendidikanOrtu();
      await pekerjaanOrtu();
      await penghasilanOrtu();
      await punyaHpRef();
      await listUploadOrtu();
      setLoadingUpload(false);
      setSkeletonUpload(false);
    }
  };

  // Download Template
  const downloadTemplateOrtu = async () => {
    setLoading(true);
    setSkeleton(true);

    const token = localStorage.getItem("token");
    try {
      const response = await axios({
        url: URL + "download_template_ortu", // Adjust the URL as needed
        method: "POST",
        data: {
          nsm: localStorage.getItem("nsm"),
        },
        responseType: "blob", // Important for file download
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1]
        : "template_ortu.xlsx";
      const validExtensions = [".xlsx", ".xls"];
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));

      if (!validExtensions.includes(fileExtension)) {
        throw new Error("Invalid file extension");
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setLoading(false);
      setSkeleton(false);
    }
  };

  // KIRIM
  const kirimData = async () => {
    setLoadingKirim(true);
    const token = localStorage.getItem("token");
    const bear = localStorage.getItem("bear");

    const kirim = await axios.post(
      URL + "push_ortu_template",
      {
        nsm: localStorage.getItem("nsm"),
        bear: bear,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(kirim);
    setLoadingKirim(false);
    if (kirim.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Data siswa berhasil dikirim, Silahkan cek di EMIS, dan ajukan perubahan data!",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: JSON.stringify(kirim.data.errors.message),
      });
    }
  };

  // DELETE ROW
  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    try {
      await axios.post(
        URL + "del_ortu_template",
        { id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await listUploadOrtu();
      Swal.fire({
        icon: "success",
        title: "Deleted",
        text: "Data siswa berhasil dihapus!",
      });
    } catch (error) {
      console.error("Error deleting data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Terjadi kesalahan saat menghapus data",
      });
    }
  };

  return (
    <div>
      <div class="btn-group">
        {/* Dowwnload Template */}
        {loading === true ? (
          <button className="btn btn-danger" type="button" disabled>
            <span
              className={`${
                skeleton ? "spinner-border spinner-border-sm" : ""
              }`}
            ></span>
            <span className="text-dark"> Loading...</span>
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={downloadTemplateOrtu}
            type="button"
          >
            Download Template
          </button>
        )}
        {/* Upload Template */}
        {loadingUpload === true ? (
          <button className="btn btn-danger" type="button" disabled>
            <span
              className={`${
                skeletonUpload ? "spinner-border spinner-border-sm" : ""
              }`}
            ></span>
            <span className="text-dark"> Loading...</span>
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => setShowModal(true)}
          >
            Upload Template
          </button>
        )}

        <button
          type="button"
          className="btn btn-info"
          onClick={() => validasi()}
        >
          Validasi
        </button>

        {/* Kirim Data */}
        {loadingKirim === true ? (
          <button className="btn btn-danger" type="button" disabled>
            <span className="spinner-border spinner-border-sm"></span>
            <span className="text-dark"> Loading...</span>
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-success"
            onClick={kirimData}
            disabled={redCellCount > 0}
          >
            Kirim
          </button>
        )}
      </div>

      {/* MODAL UPLOAD */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Template Ortu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            onChange={handleFileChange}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={uploadTemplate}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      {/* LIST DATA TEMPLATE */}
      <div className="row mt-4">
        <div className="table-responsive table-striped table-bordered">
          {load ? (
            <div className="text-center py-5">
              <p>Loading...</p>
            </div>
          ) : error ? (
            <div className="text-center py-5">
              <p className="text-danger">{error}</p>
            </div>
          ) : filteredData.length > 0 ? (
            <div style={{ overflowX: "auto" }}>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                subHeader
                subHeaderComponent={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <p style={{ marginTop: "13px", color: "red" }}>
                        {redCellCount > 0 && (
                          <b>
                            Ada {redCellCount} data yang tidak sesuai / masih
                            kosong
                          </b>
                        )}
                      </p>
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="form-control"
                        style={{ width: "200px" }} // Menyesuaikan ukuran input
                      />
                    </div>
                  </div>
                }
                fixedHeader
                customStyles={{
                  headCells: {
                    style: {
                      "&:nth-of-type(1)": {
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "#b6d7a8",
                      },
                      "&:nth-of-type(2)": {
                        position: "sticky",
                        left: "100px", // Adjust based on the width of the first column
                        zIndex: 1,
                        backgroundColor: "#b6d7a8",
                      },
                      backgroundColor: "#b6d7a8",
                      whiteSpace: "normal",
                    },
                  },
                  cells: {
                    style: {
                      "&:nth-of-type(1)": {
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "#fff",
                      },
                      "&:nth-of-type(2)": {
                        position: "sticky",
                        left: "100px", // Adjust based on the width of the first column
                        zIndex: 1,
                        backgroundColor: "#fff",
                      },
                    },
                  },
                }}
                className="table-bordered table-sm"
              />
            </div>
          ) : (
            <div>Data tidak ditemukan</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadDataOrtu;
