import React from "react";

const HasilToolsPusdatin = ({ datanisnemis }) => {
  return (
    <div className="row mt-3">
      <div className="card-header">
        <h5 className="card-title">Hasil Pencarian NISN Pusdatin</h5>
      </div>
      <div className="card-body">
        <h5 className="card-title">Identitas</h5>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>NISN</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis.nisn}
              />
            </div>
            <div className="form-group">
              <label>Nama Siswa</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis.nama}
              />
            </div>
            <div className="form-group">
              <label>Tempat Lahir</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis.tempat_lahir}
              />
            </div>
            <div className="form-group">
              <label>Tanggal Lahir</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis.tanggal_lahir}
              />
            </div>
            <div className="form-group">
              <label>NIK</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis.nik}
              />
            </div>
            <div className="form-group">
              <label>Jenis Kelamin</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis.jenis_kelamin}
              />
            </div>
            <div className="form-group">
              <label>Nama Ibu Kandung</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis.nama_ibu_kandung}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Sekolah</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis.sekolah}
              />
            </div>
            <div className="form-group">
              <label>NPSN</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis.npsn}
              />
            </div>
            <div className="form-group">
              <label>Kelas</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis.tingkat_pendidikan}
              />
            </div>
            {/* <div className="form-group">
              <label>Status Keaktifan</label>
              <input
                type="text"
                className="form-control"
                value={datanisnemis.aktif === 1 ? "Aktif" : "Nonaktif"}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HasilToolsPusdatin;
