import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { openDB } from 'idb';
import { Modal, Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const IsiBuduk = ({ url }) => {
	const URL = url;
	const [selectedYear, setSelectedYear] = useState('');
	const [academicYear, setAcademicYear] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedRombel, setSelectedRombel] = useState('');
	const [rombel, setRombel] = useState([]);
	const [rombelLength, setRombelLength] = useState(0);
	const [siswas, setSiswas] = useState([]);
	const [siswaLength, setSiswaLength] = useState(0);
	const [search, setSearch] = useState('');
	const [nilai, setNilai] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [modalData, setModalData] = useState(null);
	const [pdfData, setPdfData] = useState(null);
	const [showPdfModal, setShowPdfModal] = useState(false);
	const [currentRowIndex, setCurrentRowIndex] = useState(0);


	const fetchAcademicYear = async () => {
		const token = localStorage.getItem('token');
		try {
			setIsLoading(true);
			const response = await axios.get(`${URL}academicyear`, {
				headers: { Authorization: `Bearer ${token}` }
			});
			setAcademicYear(response.data.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchRombel = async () => {
		setIsLoading(true);
		const token = localStorage.getItem('token');
		try {
			const response = await axios.post(
				`${URL}fetchrombel`,
				{ ay: selectedYear, nsm: localStorage.getItem('nsm') },
				{ headers: { Authorization: `Bearer ${token}` } }
			);
			setRombel(response.data.data);
			setRombelLength(response.data.length);
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchLearningActivity = async () => {
		setIsLoading(true);
		const token = localStorage.getItem('token');
		try {
			const response = await axios.post(
				`${URL}getlearningactivitybyid_join`,
				{ id: selectedRombel },
				{ headers: { Authorization: `Bearer ${token}` } }
			);
			setSiswas(JSON.stringify(response.data));
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchAcademicYear();
	}, []);

	useEffect(() => {
		if (selectedYear) {
			fetchRombel();
		}
	}, [selectedYear]);

	useEffect(() => {
		if (selectedRombel) {
			fetchLearningActivity();
		}
	}, [selectedRombel]);

	let parsedSiswa = [];
	try {
		parsedSiswa = siswas && JSON.parse(siswas).data;
	} catch (error) {
		console.error('Error parsing JSON:', error);
	}

	const filteredData = parsedSiswa.filter((item) => {
		const fullName = item.full_name ? item.full_name.toLowerCase() : '';
		const nisn = item.nisn ? item.nisn.toLowerCase() : '';
		return fullName.includes(search.toLowerCase()) || nisn.includes(search.toLowerCase());
	});

	const generatePDF = (data) => {
    const doc = new jsPDF();

    doc.setFontSize(12);
		doc.setFont('helvetica', 'bold');
    doc.text('I. PESERTA DIDIK', 10, 10);
    doc.setFont('helvetica', 'normal');

    doc.autoTable({
        startY: 12,
        body: [
					['Nomor Induk', ':' , data.local_nis],
            ['NISN', ':' , data.nisn],
            ['Nama', ':' , data.full_name],	
            ['Jenis Kelamin', ':' , data.gender ],
            ['Tempat, Tanggal Lahir', ':' ,  data.birth_place.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')+', ' + new Date(data.birth_date).toLocaleDateString('id-ID', { year: 'numeric', month: 'long', day: 'numeric' })],
						['Anak Ke', ':' ,  data.child_of_num ],
            ['Jumlah Saudara', ':' ,  data.siblings_num ],
					['Alamat Lengkap', ':' , data.full_address.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') ],
					
        ],
        theme: 'plain',
        styles: { cellPadding: 1, fontSize: 12, lineHeight: 1 },
        columnStyles: {
            0: { cellWidth: 50 },
            1: { cellWidth: 3 },
            2: {  cellWidth:'auto' },
            3: {  cellWidth:50 },
            4: {  cellWidth:3 },
            5: {  cellWidth:'auto' },
        },
				didParseCell: (data) => {
					if (data.column.index === 2 || data.column.index === 5) {
						data.cell.styles.fontStyle = 'bold';
				}
			},
    });

    doc.text('II. ORANG TUA', 10, doc.lastAutoTable.finalY + 10);

    doc.autoTable({
        startY: doc.lastAutoTable.finalY + 20,
        head: [['Field', 'Value']],
        body: [
            ['Nama Orang Tua', `Ayah: ${data.nama_ayah} Ibu: ${data.nama_ibu}`],
            ['Pendidikan Orang Tua', `Ayah: ${data.pendidikan_ayah} Ibu: ${data.pendidikan_ibu}`],
            ['Pekerjaan Orang Tua', `Ayah: ${data.pekerjaan_ayah} Ibu: ${data.pekerjaan_ibu}`],
            ['Agama', data.agama],
            ['Alamat Orang Tua', data.alamat_orang_tua],
            ['Telephone / HP', data.telephone],
        ],
        theme: 'plain',
        styles: { cellPadding: 2, fontSize: 10 },
        columnStyles: {
            0: { cellWidth: 50 },
            1: { cellWidth: 140 },
        },
    });

    doc.text('III. WALI', 10, doc.lastAutoTable.finalY + 10);

    doc.autoTable({
        startY: doc.lastAutoTable.finalY + 20,
        head: [['Field', 'Value']],
        body: [
            ['Nama Wali', data.nama_wali],
            ['Pendidikan Wali', data.pendidikan_wali],
            ['Pekerjaan Wali', data.pekerjaan_wali],
            ['Alamat Wali', data.alamat_wali],
            ['Telephone / HP', data.telephone_wali],
        ],
        theme: 'plain',
        styles: { cellPadding: 2, fontSize: 10 },
        columnStyles: {
            0: { cellWidth: 50 },
            1: { cellWidth: 140 },
        },
    });

    doc.text('IV. MASUK MADRASAH INI', 10, doc.lastAutoTable.finalY + 10);

    doc.autoTable({
        startY: doc.lastAutoTable.finalY + 20,
        head: [['Field', 'Value']],
        body: [
            ['Dari Sekolah/Madrasah', data.dari_sekolah],
            ['Tanggal', data.tanggal_masuk],
            ['Diterima di kelas', data.diterima_di_kelas],
            ['Status Siswa', data.status_siswa],
        ],
        theme: 'plain',
        styles: { cellPadding: 2, fontSize: 10 },
        columnStyles: {
            0: { cellWidth: 50 },
            1: { cellWidth: 140 },
        },
    });

    // Add image if available
    if (data.image) {
        doc.addImage(data.image, 'JPEG', 150, 10, 40, 50); // Adjust the position and size as needed
    }

    const pdfBlob = doc.output('blob');

    const reader = new FileReader();
    reader.onload = function(e) {
        setPdfData(e.target.result);
        setShowPdfModal(true);
    };
    reader.readAsDataURL(pdfBlob);
};

	const columns = [
		{
			name: 'No',
			selector: (row, index) => index + 1,
			sortable: true,
			maxWidth: "50px",
		},
		{
			name: 'No Absen',
			selector: (row) => row.absent_number,
			sortable: true,
			maxWidth: "50px",
		},
		{
			name: 'NIS',
			selector: (row) => row.local_nis,
			sortable: true,
			minWidth: "150px",
		},
		{
			name: 'NISN',
			selector: (row) => row.nisn,
			sortable: true,
			minWidth: "100px",
		},
		{
			name: 'Nama Siswa',
			selector: (row) => row.full_name,
			sortable: true,
			minWidth: "200px",
		},
		{
			name: 'Action',
			selector: (row) => (
				<div>
					<button className="btn btn-primary btn-sm" onClick={() => generatePDF(row)}>Cetak Identitas</button>
					<button className="btn btn-primary btn-sm" onClick={() => fetchNilaiFromIndexedDB(row.nisn ? row.nisn : row.full_name)}>Nilai</button>
					<button className="btn btn-danger btn-sm">Delete</button>
				</div>
			),
			sortable: true
		}
	];
	const sortedData = filteredData.sort((a, b) => a.absent_number - b.absent_number);

	const fetchNilaiFromIndexedDB = async (nisn) => {
		try {
			const db = await openDB('RDMDatabase', 1);
			const tx = db.transaction('nilai', 'readonly');
			const store = tx.objectStore('nilai');

			console.log("nisn", nisn)

			const index = isNaN(nisn) ? store.index('nama') : store.index('nisn');
			const data = await index.getAll(nisn);
			const filteredData = isNaN(nisn)
				? data.filter((item) => String(item.nama).includes(String(nisn)))
				: data.filter((item) => item.nisn === nisn);

			console.log('Fetched data:', filteredData);
			setModalData(filteredData);
			if (filteredData.length > 0) {
				setShowModal(true);
			}
		} catch (error) {
			console.error('Error fetching data from IndexedDB:', error);
		}
	};

	const handleCloseModal = () => setShowModal(false);
	const handleClosePdfModal = () => setShowPdfModal(false);

	useEffect(() => {
		checkIndexedDBExists('RDMDatabase').then((exists) => {
			if (exists) {
				fetchNilaiFromIndexedDB();
			}
		});
	}, []);

	const checkIndexedDBExists = async (dbName) => {
		const dbs = await window.indexedDB.databases();
		return dbs.some((db) => db.name === dbName);
	};


	const handleNextRecord = () => {
		const nextIndex = currentRowIndex + 1;
		if (nextIndex < sortedData.length) {
			setCurrentRowIndex(nextIndex);
			generatePDF(sortedData[nextIndex]);
		}
	};
	const handlePreviousRecord = () => {
		const prevIndex = currentRowIndex - 1;
		if (prevIndex >= 0) {
			setCurrentRowIndex(prevIndex);
			generatePDF(sortedData[prevIndex]);
		}
	};



	return (
		<div style={{ marginBottom: '40px' }}>
			<h3>Buku Induk</h3>
			<div className="form-group row">
				<label className="col-form-label col-md-2 text-end">Pilih Tahun / Semester</label>
				<div className="col-md-3">
					<select
						className="form-control form-select"
						value={selectedYear}
						onChange={(e) => setSelectedYear(e.target.value)}
					>
						<option>-- Pilih Tahun Ajaran / Semester --</option>
						{academicYear.map((year, index) => (
							<option key={index} value={year.id}>
								{year.name}
							</option>
						))}
					</select>
				</div>

				<label className="col-form-label col-md-2 text-end">Pilih Rombel</label>
				<div className="col-md-3">
					<select
						className="form-control form-select"
						value={selectedRombel}
						onChange={(e) => setSelectedRombel(e.target.value)}
					>
						<option>-- Pilih Rombel --</option>
						{rombel.map((rombel, index) => (
							<option key={index} value={rombel.id}>
								{rombel.name}
							</option>
						))}
					</select>
				</div>
			</div>

			<div className="row">
				<div className="col-md-12">
					<DataTable
						columns={columns}
						data={sortedData}
						pagination
						paginationPerPage={10}
						subHeader
						subHeaderComponent={
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									width: '100%'
								}}
							>
								<input
									type="text"
									placeholder="Search"
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									className="form-control"
									style={{ width: '200px' }}
								/>
							</div>
						}
					/>
				</div>
			</div>
			<Modal show={showModal} onHide={handleCloseModal} size="xl">
				<Modal.Header closeButton>
					<Modal.Title>Nilai Siswa {modalData && modalData[0] ? `- ${modalData[0].nama}` : ''}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{modalData ? (
						<div>
							<table className="table">
								<thead>
									<tr>
										<th rowSpan="2">Mapel</th>
										{[...new Set(modalData.map(item => item.kelas))].map((kelas, index) => (
											<React.Fragment key={index}>
												<th colSpan="2">Kelas {kelas}</th>
											</React.Fragment>
										))}
									</tr>
									<tr>
										{[...new Set(modalData.map(item => item.kelas))].map((kelas, index) => (
											<React.Fragment key={index}>
												<th>Semester 1</th>
												<th>Semester 2</th>
											</React.Fragment>
										))}
									</tr>
								</thead>
								<tbody>
									{Object.entries(
										modalData.reduce((acc, item) => {
											if (!acc[item.mapel]) {
												acc[item.mapel] = {};
											}
											if (!acc[item.mapel][item.kelas]) {
												acc[item.mapel][item.kelas] = { semester1: '', semester2: '' };
											}
											if (item.semester === 1) {
												acc[item.mapel][item.kelas].semester1 = item.nilai;
											} else if (item.semester === 2) {
												acc[item.mapel][item.kelas].semester2 = item.nilai;
											}
											return acc;
										}, {})
									).map(([mapel, kelasData], mapelIndex) => (
										<tr key={mapelIndex}>
											<td>{mapel}</td>
											{[...new Set(modalData.map(item => item.kelas))].map((kelas, kelasIndex) => (
												<React.Fragment key={`${mapelIndex}-${kelasIndex}`}>
													<td>{kelasData[kelas]?.semester1 || ''}</td>
													<td>{kelasData[kelas]?.semester2 || ''}</td>
												</React.Fragment>
											))}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<p>Loading...</p>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showPdfModal} onHide={handleClosePdfModal} fullscreen>
				<Modal.Header closeButton>
					<Modal.Title><h5>Preview Identitas Siswa</h5></Modal.Title>
				
				</Modal.Header>
				

				<div class="btn-group px-3">
        <button type="button" class="btn btn-danger text-dark" onClick={handlePreviousRecord} disabled={currentRowIndex === 0}>
          Sebelumnya
        </button>
        <button type="button" class="btn btn-warning" onClick={handleNextRecord} disabled={currentRowIndex === sortedData.length - 1}>
          Selanjutnya
        </button>
		
        <button type="button" class="btn btn-info" onClick={() => { 
            const link = document.createElement('a'); 
            link.href = pdfData; 
            link.download = `INDUK-BIODATA-${sortedData[currentRowIndex].full_name}.pdf`; 
            link.click(); 
        }}>
          Download
        </button>

      </div>


				<Modal.Body>
					{pdfData && (
						<Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
							<Viewer fileUrl={pdfData} />
						</Worker>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClosePdfModal}>
						Close
					</Button>
					
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default IsiBuduk;