import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BlockUIku from "../../BlockUI/BlockUIku";

const PriceList = ({ title, description, feature, warna, harga, loading }) => {
  return (
    <div className="col-md-4 col-sm-6">
      <div className="ribbon-wrapper card">
        <BlockUIku blocking={loading} />
        <div className="card-body">
          <div className={`ribbon ribbon-${warna}`}>
            {loading ? <Skeleton /> : title}
          </div>

          <div class="card flex-fill fb sm-box">
            <div class="social-likes">
              <p>Price</p>
              <h3 className="text-white">{loading ? <Skeleton /> : harga}</h3>
            </div>
            <div class="social-boxs">
              <img
                src="assets/img/icons/dash-icon-04.svg"
                alt="Dashboard Icon"
              />
            </div>
          </div>
          <p>{loading ? <Skeleton /> : description}</p>
          {feature && (
            <ol className="price-list">
              {feature.map((feature, index) => (
                <li key={index}>{loading ? <Skeleton /> : feature}</li>
              ))}
            </ol>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceList;
