import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap"; // Assuming you're using react-bootstrap
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";

const UploadDataSiswa = ({ url }) => {
  const [loading, setLoading] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [skeletonUpload, setSkeletonUpload] = useState(false);
  const [error, setError] = useState("");
  const [ambilSiswa, setAmbilSiswa] = useState([]);
  const [search, setSearch] = useState("");
  const [load, setLoad] = useState(false);
  const [redCellCount, setRedCellCount] = useState(0);
  const [cita, setCita] = useState([]);
  const [hobi, setHobi] = useState([]);
  const [biaya, setBiaya] = useState([]);
  const [kebutuhan, setKebutuhan] = useState([]);
  const [disabilitas, setDisabilitas] = useState([]);
  const [agama, setAgama] = useState([]);
  const [tk, setTk] = useState([]);
  const [paud, setPaud] = useState([]);
  const [punyaHp, setPunyaHp] = useState([]);
  const [loadingKirim, setLoadingKirim] = useState(false);

  const URL = url;

  // Fetch Data
  const listUploadSiswa = async (e) => {
    const token = localStorage.getItem("token");

    try {
      const getlistSiswa = await axios.post(
        URL + "get_siswa_upload",
        {
          nsm: localStorage.getItem("nsm"),
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getlistSiswa.data.status === true) {
        setAmbilSiswa(JSON.stringify(getlistSiswa.data));
      } else {
        setError(getlistSiswa.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError(
          "History upload template siswa tidak ditemukan, Silahkan upload template siswa terlebih dahulu !"
        );
      } else {
        setError("Terjadi kesalahan saat mengambil data");
      }
    }
  };

  // Referensi Data
  const citaCita = async (e) => {
    const token = localStorage.getItem("token");
    const cita = await axios.get(URL + "citacita", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setCita(JSON.stringify(cita.data));
  };
  const hobiRef = async (e) => {
    const token = localStorage.getItem("token");
    const hobi = await axios.get(URL + "hobi", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setHobi(JSON.stringify(hobi.data));
  };
  const biayaRef = async (e) => {
    const token = localStorage.getItem("token");
    const biaya = await axios.get(URL + "biaya", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setBiaya(JSON.stringify(biaya.data));
  };
  const kebutuhanRef = async (e) => {
    const token = localStorage.getItem("token");
    const kebutuhan = await axios.get(URL + "kebutuhankhusus", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setKebutuhan(JSON.stringify(kebutuhan.data));
  };
  const disabilitasRef = async (e) => {
    const token = localStorage.getItem("token");
    const disabilitas = await axios.get(URL + "kebutuhandisabilitas", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setDisabilitas(JSON.stringify(disabilitas.data));
  };
  const agamaRef = async (e) => {
    const token = localStorage.getItem("token");
    const agama = await axios.get(URL + "agama", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setAgama(JSON.stringify(agama.data));
  };
  const tkRef = async (e) => {
    const token = localStorage.getItem("token");
    const tk = await axios.get(URL + "tkpaud", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setTk(JSON.stringify(tk.data));
  };
  const paudRef = async (e) => {
    const token = localStorage.getItem("token");
    const paud = await axios.get(URL + "tkpaud", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setPaud(JSON.stringify(paud.data));
  };
  const punyaHpRef = async (e) => {
    const token = localStorage.getItem("token");
    const punyaHp = await axios.get(URL + "punyahp", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setPunyaHp(JSON.stringify(punyaHp.data));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      await listUploadSiswa();
      await citaCita();
      await hobiRef();
      await biayaRef();
      await kebutuhanRef();
      await disabilitasRef();
      await agamaRef();
      await tkRef();
      await paudRef();
      await punyaHpRef();
      setLoad(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    calculateRedCellCount();
  }, [ambilSiswa, search]);

  const calculateRedCellCount = () => {
    let count = 0;
    let parsedSiswa = [];
    let parsedCita = [];
    let parsedHobi = [];
    let parsedBiaya = [];
    let parsedKebutuhan = [];
    let parsedDisabilitas = [];
    let parsedAgama = [];
    let parsedTk = [];
    let parsedPaud = [];
    let parsedPunyaHp = [];

    try {
      parsedSiswa = ambilSiswa && JSON.parse(ambilSiswa).data;
      parsedCita = cita && JSON.parse(cita).data;
      parsedHobi = hobi && JSON.parse(hobi).data;
      parsedBiaya = biaya && JSON.parse(biaya).data;
      parsedKebutuhan = kebutuhan && JSON.parse(kebutuhan).data;
      parsedDisabilitas = disabilitas && JSON.parse(disabilitas).data;
      parsedAgama = agama && JSON.parse(agama).data;
      parsedTk = tk && JSON.parse(tk).data;
      parsedPaud = paud && JSON.parse(paud).data;
      parsedPunyaHp = punyaHp && JSON.parse(punyaHp).data;
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    parsedSiswa.forEach((row) => {
      if (!row.full_name) count++;
      if (
        !row.local_nis ||
        row.local_nis.length !== 18 ||
        row.local_nis.substr(0, 12) !== localStorage.getItem("nsm")
      )
        count++;
      if (!row.child_of_num) count++;
      if (!row.siblings_num) count++;
      if (
        !parsedAgama.some((item) => item.kode === row.m_religion_id) ||
        !row.m_religion_id
      )
        count++;
      if (
        !parsedCita.some((item) => item.kode === row.m_life_goal_id) ||
        !row.m_life_goal_id
      )
        count++;

      if (
        !parsedHobi.some((item) => item.kode === row.m_hobby_id) ||
        !row.m_hobby_id
      )
        count++;
      if (
        !parsedBiaya.some((item) => item.kode === row.m_fund_source_id) ||
        !row.m_fund_source_id
      )
        count++;
      if (
        !parsedKebutuhan.some((item) => item.kode === row.m_special_need_id) ||
        !row.m_special_need_id
      )
        count++;
      if (!row.kk_num || row.kk_num.length !== 16) count++;

      if (!row.family_head_name) count++;
      if (
        !parsedTk.some((item) => item.kode === row.entered_tk_ra) ||
        !row.entered_tk_ra
      )
        count++;
      if (
        !parsedPaud.some((item) => item.kode === row.entered_paud) ||
        !row.entered_paud
      )
        count++;
      if (
        !parsedDisabilitas.some((item) => item.kode === row.m_disability_id) ||
        !row.m_disability_id
      )
        count++;
      if (
        !row.nationality ||
        (row.nationality !== "wni" && row.nationality !== "wna")
      )
        count++;
      if (
        !row.is_handphone_null ||
        !parsedPunyaHp.some((item) => item.kode === row.is_handphone_null)
      )
        count++;
      if (
        (row.is_handphone_null === "0" &&
          row.handphone.substr(0, 3) !== "628") ||
        (row.is_handphone_null === "0" && !row.handphone)
      )
        count++;
    });
    setRedCellCount(count);
  };

  let parsedSiswa = [];
  let parsedCita = [];
  let parsedHobi = [];
  let parsedBiaya = [];
  let parsedKebutuhan = [];
  let parsedDisabilitas = [];
  let parsedAgama = [];
  let parsedTk = [];
  let parsedPaud = [];
  let parsedPunyaHp = [];
  try {
    parsedSiswa = ambilSiswa && JSON.parse(ambilSiswa).data;
    parsedCita = cita && JSON.parse(cita).data;
    parsedHobi = hobi && JSON.parse(hobi).data;
    parsedBiaya = biaya && JSON.parse(biaya).data;
    parsedKebutuhan = kebutuhan && JSON.parse(kebutuhan).data;
    parsedDisabilitas = disabilitas && JSON.parse(disabilitas).data;
    parsedAgama = agama && JSON.parse(agama).data;
    parsedTk = tk && JSON.parse(tk).data;
    parsedPaud = paud && JSON.parse(paud).data;
    parsedPunyaHp = punyaHp && JSON.parse(punyaHp).data;
  } catch (error) {
    // console.error("Error parsing JSON:", error);
  }

  const columns = [
    {
      name: "ACTION",
      cell: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDelete(row.id)}
        >
          <i className="feather-trash-2"></i>
        </button>
      ),

      ignoreRowClick: true,

      allowOverflow: true,

      button: true,
    },
    {
      name: "NAMA",
      selector: (row) => row.full_name,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.full_name,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NIS LOKAL",
      selector: (row) => row.local_nis,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "180px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.local_nis ||
            row.local_nis.length !== 18 ||
            row.local_nis.substr(0, 12) !== localStorage.getItem("nsm"),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "ANAK KE",
      selector: (row) => row.child_of_num,
      sortable: true,
      wrap: true, // Adjust column width based on content
      conditionalCellStyles: [
        {
          when: (row) => !row.child_of_num,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "JML SAUDARA",
      selector: (row) => row.siblings_num,
      sortable: true,
      wrap: true, // Adjust column width based on content
      style: { whiteSpace: "nowrap" },
      conditionalCellStyles: [
        {
          when: (row) => !row.siblings_num,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "AGAMA",
      selector: (row) => row.m_religion_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.m_religion_id ||
            !parsedAgama.some((item) => item.kode === row.m_religion_id),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "CITA-CITA",
      selector: (row) => row.m_life_goal_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.m_life_goal_id ||
            !parsedCita.some((item) => item.kode === row.m_life_goal_id),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "HOBI",
      selector: (row) => row.m_hobby_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.m_hobby_id ||
            !parsedHobi.some((item) => item.kode === row.m_hobby_id),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "BIAYA SEKOLAH",
      selector: (row) => row.m_fund_source_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.m_fund_source_id ||
            !parsedBiaya.some((item) => item.kode === row.m_fund_source_id),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "KEBUTUHAN KHUSUS",
      selector: (row) => row.m_special_need_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.m_special_need_id ||
            !parsedKebutuhan.some(
              (item) => item.kode === row.m_special_need_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NO KK",
      selector: (row) => row.kk_num,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "180px",
      conditionalCellStyles: [
        {
          when: (row) => !row.kk_num || row.kk_num.length !== 16,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NAMA KK",
      selector: (row) => row.family_head_name,
      sortable: true,
      wrap: true, // Adjust column width based on content
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.family_head_name,
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PERNAH TK",
      selector: (row) => row.entered_tk_ra,
      sortable: true,
      wrap: true, // Adjust column width based on content
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.entered_tk_ra ||
            !parsedTk.some((item) => item.kode === row.entered_tk_ra),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PERNAH PAUD",
      selector: (row) => row.entered_paud,
      sortable: true,
      wrap: true, // Adjust column width based on content
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.entered_paud ||
            !parsedPaud.some((item) => item.kode === row.entered_paud),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "DISABILITAS",
      selector: (row) => row.m_disability_id,
      sortable: true,
      wrap: true, // Adjust column width based on content
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.m_disability_id ||
            !parsedDisabilitas.some(
              (item) => item.kode === row.m_disability_id
            ),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "KEWARGANEGARAAN",
      selector: (row) => row.nationality,
      sortable: true,
      wrap: true, // Adjust column width based on content
      style: { whiteSpace: "nowrap" },
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.nationality ||
            (row.nationality !== "wni" && row.nationality !== "wna"),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "PUNYA HP?",
      selector: (row) => row.is_handphone_null,
      sortable: true,
      wrap: true, // Adjust column width based on content
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.is_handphone_null ||
            !parsedPunyaHp.some((item) => item.kode === row.is_handphone_null),
          style: {
            backgroundColor: "red",
          },
        },
      ],
    },
    {
      name: "NO HP",
      selector: (row) => row.handphone,
      sortable: true,
      wrap: true, // Adjust column width based on content
      conditionalCellStyles: [
        {
          when: (row) =>
            (row.is_handphone_null === "0" &&
              row.handphone.substr(0, 3) !== "628") ||
            (row.is_handphone_null === "0" && !row.handphone),
          style: {
            backgroundColor: "red",
          },
        },
      ],
      minWidth: "130px",
    },
  ];

  // Filter data berdasarkan nilai pencarian
  const filteredData = parsedSiswa.filter((item) => {
    const fullName = item.full_name ? item.full_name.toLowerCase() : "";

    return fullName.includes(search.toLowerCase());
  });

  // Download Template
  const downloadTemplate = async () => {
    setLoading(true);
    setSkeleton(true);

    const token = localStorage.getItem("token");
    try {
      const response = await axios({
        url: URL + "download_template_siswa", // Adjust the URL as needed
        method: "POST",
        data: {
          nsm: localStorage.getItem("nsm"),
        },
        responseType: "blob", // Important for file download
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1]
        : "template_siswa.xlsx";
      const validExtensions = [".xlsx", ".xls"];
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));

      if (!validExtensions.includes(fileExtension)) {
        throw new Error("Invalid file extension");
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setLoading(false);
      setSkeleton(false);
    }
  };

  // UPLOAD TEMPLATE
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadTemplate = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const validExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name.slice(file.name.lastIndexOf("."));
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validExtensions.includes(fileExtension)) {
      alert("Invalid file type. Please upload an Excel file.");
      return;
    }

    if (file.size > maxSize) {
      alert("File size exceeds the limit of 5MB.");
      return;
    }

    setLoadingUpload(true);
    setSkeletonUpload(true);

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("nsm", localStorage.getItem("nsm"));

    try {
      setShowModal(false);
      await axios.post(URL + "upload_template_siswa", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      await listUploadSiswa();
      await citaCita();
      await hobiRef();
      await biayaRef();
      await kebutuhanRef();
      await disabilitasRef();
      await agamaRef();
      await tkRef();
      await paudRef();
      await punyaHpRef();
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Template siswa berhasil diupload!",
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file.");
    } finally {
      setLoadingUpload(false);
      setSkeletonUpload(false);
    }
  };

  // DELETE ROW
  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    try {
      await axios.post(
        URL + "del_siswa_template",
        { id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Deleted",
        text: "Data siswa berhasil dihapus!",
      });
      await listUploadSiswa();
      await citaCita();
      await hobiRef();
      await biayaRef();
      await kebutuhanRef();
      await disabilitasRef();
      await agamaRef();
      await tkRef();
      await paudRef();
      await punyaHpRef();
    } catch (error) {
      console.error("Error deleting data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Terjadi kesalahan saat menghapus data",
      });
    }
  };

  const validasi = async () => {
    calculateRedCellCount();
  };

  // KIRIM
  const kirimData = async () => {
    setLoadingKirim(true);
    const token = localStorage.getItem("token");
    const bear = localStorage.getItem("bear");

    const kirim = await axios.post(
      URL + "push_siswa_template",
      {
        nsm: localStorage.getItem("nsm"),
        bear: bear,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(kirim);
    setLoadingKirim(false);
    Swal.fire({
      icon: "success",
      title: "Berhasil",
      text: "Data siswa berhasil dikirim, Silahkan cek di EMIS!",
    });
  };

  return (
    <div>
      <div className="btn-group">
        {/* Download Template */}
        {loading === true ? (
          <button className="btn btn-danger" type="button" disabled>
            <span
              className={`${
                skeleton ? "spinner-border spinner-border-sm" : ""
              }`}
            ></span>
            <span className="text-dark"> Loading...</span>
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={downloadTemplate}
            type="button"
          >
            Download Template
          </button>
        )}

        {/* Upload Template */}
        {loadingUpload === true ? (
          <button className="btn btn-danger" type="button" disabled>
            <span
              className={`${
                skeletonUpload ? "spinner-border spinner-border-sm" : ""
              }`}
            ></span>
            <span className="text-dark"> Loading...</span>
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => setShowModal(true)}
          >
            Upload Template
          </button>
        )}

        <button
          type="button"
          className="btn btn-info text-white"
          onClick={() => validasi()}
        >
          Validasi
        </button>

        {/* Kirim */}
        {loadingKirim === true ? (
          <button className="btn btn-danger" type="button" disabled>
            <span className="spinner-border spinner-border-sm"></span>
            <span className="text-dark"> Loading...</span>
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-success"
            onClick={kirimData}
            disabled={redCellCount > 0}
          >
            Kirim
          </button>
        )}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Template Siswa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            onChange={handleFileChange}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={uploadTemplate}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      {/* LIST DATA TEMPLATE */}
      <div className="row mt-4">
        <div className="table-responsive table-striped table-bordered">
          {load ? (
            <div className="text-center py-5">
              <p>Loading...</p>
            </div>
          ) : error ? (
            <div className="text-center py-5">
              <p className="text-danger">{error}</p>
            </div>
          ) : filteredData.length > 0 ? (
            <div style={{ overflowX: "auto" }}>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                subHeader
                subHeaderComponent={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <p style={{ marginTop: "13px", color: "red" }}>
                        {redCellCount > 0 && (
                          <b>
                            Ada {redCellCount} data yang tidak sesuai / masih
                            kosong
                          </b>
                        )}
                      </p>
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="form-control"
                        style={{ width: "200px" }} // Menyesuaikan ukuran input
                      />
                    </div>
                  </div>
                }
                fixedHeader
                customStyles={{
                  headCells: {
                    style: {
                      "&:nth-of-type(1)": {
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "#b6d7a8",
                      },
                      "&:nth-of-type(2)": {
                        position: "sticky",
                        left: "100px", // Adjust based on the width of the first column
                        zIndex: 1,
                        backgroundColor: "#b6d7a8",
                      },
                      backgroundColor: "#b6d7a8",
                    },
                  },
                  cells: {
                    style: {
                      "&:nth-of-type(1)": {
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "#fff",
                      },
                      "&:nth-of-type(2)": {
                        position: "sticky",
                        left: "100px", // Adjust based on the width of the first column
                        zIndex: 1,
                        backgroundColor: "#fff",
                      },
                    },
                  },
                }}
                className="table-bordered table-sm"
              />
            </div>
          ) : (
            <div>Data tidak ditemukan</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadDataSiswa;
