import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-loading-skeleton/dist/skeleton.css";
import CariNisnEmis from "./Tools/CariNisnEmis";
import CariNikEmis from "./Tools/CariNikEmis";
import CariNamaTTL from "./Tools/CariNamaTTL";
import CariNISNPusdatin from "./Tools/CariNISNPusdatin";
import CariAlumniEmis from "./Tools/CariAlumniEmis";
import TarikPaksa from "./Tools/TarikPaksa";

const ToolsEmis = ({ url }) => {
  const [paket, setPaket] = useState("");
  const [fbd, setFbd] = useState("");
  const token = localStorage.getItem("token");
  const fetchMe = async () => {
    const response = await axios.get(url + "me", {
      headers: { Authorization: `Bearer ${token}` },
    });
    setPaket(response.data.user.package);
    setFbd(response.data.user.fbd);
  };

  useEffect(() => {
    const checkInstitution = setInterval(() => {
      fetchMe();
    }, 2000); // Check every second

    return () => clearInterval(checkInstitution); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card bg-white">
          <div className="card-header">
            <h5 className="card-title">TOOLS BIMA SAKTI</h5>
          </div>
          <div className="card-body">
            <ul className="nav nav-tabs nav-tabs-bottom">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  href="#nisn-emis"
                  data-bs-toggle="tab"
                >
                  NISN (EMIS)
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#nik-emis" data-bs-toggle="tab">
                  NIK
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#nama-emis" data-bs-toggle="tab">
                  NAMA & TTL
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#nisn-pusdatin"
                  data-bs-toggle="tab"
                >
                  NISN (Pusdatin)
                </a>
              </li>
              {paket === "3" && (
                <li className="nav-item">
                  <a className="nav-link" href="#alumni" data-bs-toggle="tab">
                    Cari Alumni
                  </a>
                </li>
              )}

              {(paket === "3" || paket === "2") && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#tarikpaksa"
                    data-bs-toggle="tab"
                  >
                    Tarik Paksa
                  </a>
                </li>
              )}
            </ul>

            <div className="tab-content">
              {/* MULAI CARI PAKAI NISN EMIS */}
              <CariNisnEmis url={url} />
              {/* MULAI CARI PAKAI NIK */}
              <CariNikEmis url={url} />
              {/* MULAI CARI NAMA & TTL */}
              <CariNamaTTL url={url} />
              {/* MULAI CARI NISN PUSDATIN */}
              <CariNISNPusdatin url={url} />
              {/* Cari Alumni Lembaga Lain */}
              <CariAlumniEmis url={url} />
              {/* Tarik Paksa */}
              <TarikPaksa url={url} fbd={fbd} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolsEmis;
