import React from "react";

const MenuBawah = () => {
  return (
    <div>
      <footer className="bottom-nav">
        <ul className="d-flex align-items-center justify-content-around w-100 h-100">
          <li>
            <a href="home.html">
              <img
                src="/assets_mobile/svg/bottom-nav/home-active.svg"
                alt="home"
              />
            </a>
          </li>
          <li>
            <a href="explore.html">
              <img
                src="/assets_mobile/svg/bottom-nav/category.svg"
                alt="category"
              />
            </a>
          </li>
          <li>
            <a href="ticket-booked.html">
              <img
                src="/assets_mobile/svg/bottom-nav/ticket.svg"
                alt="ticket"
              />
            </a>
          </li>
          <li>
            <a href="wishlist.html">
              <img src="/assets_mobile/svg/bottom-nav/heart.svg" alt="heart" />
            </a>
          </li>
          <li>
            <a href="profile/user-profile.html">
              <img
                src="/assets_mobile/svg/bottom-nav/profile.svg"
                alt="profile"
              />
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default MenuBawah;
