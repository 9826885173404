import React, { useState, useEffect } from "react";
import axios from "axios";

const KitabTemplates = ({ url }) => {
  const URL = url;
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("kode-wilayah");
  const [statusOrtu, setStatusOrtu] = useState([]);
  const [kodePendidikan, setKodePendidikan] = useState([]);
  const [kodePekerjaan, setKodePekerjaan] = useState([]);
  const [kodePenghasilan, setKodePenghasilan] = useState([]);
  const [kodeStatusRumahOrtu, setKodeStatusRumahOrtu] = useState([]);
  const [kodeAgama, setKodeAgama] = useState([]);
  const [kodeStatusRumahSiswa, setKodeStatusRumahSiswa] = useState([]);
  const [kodeJarak, setKodeJarak] = useState([]);
  const [kodeWaktu, setKodeWaktu] = useState([]);
  const [kodeBiaya, setKodeBiaya] = useState([]);
  const [kodeKebKhusus, setKodeKebKhusus] = useState([]);
  const [kodeDisabilitas, setKodeDisabilitas] = useState([]);
  const [kodeTransportasi, setKodeTransportasi] = useState([]);
  const [kodeHobi, setKodeHobi] = useState([]);
  const [kodeCita, setKodeCita] = useState([]);
  const [kodeProvinsi, setKodeProvinsi] = useState([]);
  const [pilihProvinsi, setPilihProvinsi] = useState("");
  const [kabko, setKabko] = useState([]);
  const [pilihKabko, setPilihKabko] = useState("");
  const [kec, setKec] = useState([]);
  const [pilihKec, setPilihKec] = useState("");
  const [desa, setDesa] = useState([]);
  const [pilihDesa, setPilihDesa] = useState("");
  const [loadingKabko, setLoadingKabko] = useState(false);
  const [loadingKec, setLoadingKec] = useState(false);
  const [loadingDesa, setLoadingDesa] = useState(false);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const fetchStatusOrtu = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}status`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setStatusOrtu(response.data.data);
      } else {
        setStatusOrtu([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodePendidikan = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}pendidikan`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodePendidikan(response.data.data);
      } else {
        setKodePendidikan([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodePekerjaan = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}pekerjaan`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodePekerjaan(response.data.data);
      } else {
        setKodePekerjaan([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodePenghasilan = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}penghasilan`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodePenghasilan(response.data.data);
      } else {
        setKodePenghasilan([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeStatusRumahOrtu = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}residence_ortu`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeStatusRumahOrtu(response.data.data);
      } else {
        setKodeStatusRumahOrtu([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeAgama = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}agama`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeAgama(response.data.data);
      } else {
        setKodeAgama([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeStatusRumahSiswa = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}residence_siswa`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeStatusRumahSiswa(response.data.data);
      } else {
        setKodeStatusRumahSiswa([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeJarak = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}jarak`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeJarak(response.data.data);
      } else {
        setKodeJarak([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeWaktu = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}waktu`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeWaktu(response.data.data);
      } else {
        setKodeWaktu([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeBiaya = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}biaya`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeBiaya(response.data.data);
      } else {
        setKodeBiaya([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeKebKhusus = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}kebutuhankhusus`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeKebKhusus(response.data.data);
      } else {
        setKodeKebKhusus([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeDisabilitas = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}kebutuhandisabilitas`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeDisabilitas(response.data.data);
      } else {
        setKodeDisabilitas([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeTransportasi = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}transportasi`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeTransportasi(response.data.data);
      } else {
        setKodeTransportasi([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeHobi = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}hobi`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeHobi(response.data.data);
      } else {
        setKodeHobi([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeCita = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}citacita`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeCita(response.data.data);
      } else {
        setKodeCita([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKodeProvinsi = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}provinsi`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data.data)) {
        setKodeProvinsi(response.data.data);
      } else {
        setKodeProvinsi([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKabko = async (pilihProvinsi) => {
    setLoadingKabko(true);
    setPilihKabko("");
    setPilihKec("");
    setPilihDesa("");
    setKabko([]);
    setKec([]);
    setDesa([]);
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${URL}kabupaten`,
      { provinsi: pilihProvinsi },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setKabko(response.data.data);
    setLoadingKabko(false);
  };

  const addDotAfterSecondChar = (str) => {
    if (str.length > 2) {
      return str.slice(0, 2) + "." + str.slice(2);
    }
    return str;
  };

  const handleKec = async (kabko) => {
    setLoadingKec(true);
    setPilihKec("");
    setPilihDesa("");
    setKec([]);
    setDesa([]);

    const token = localStorage.getItem("token");
    const modifiedKabko = addDotAfterSecondChar(kabko);
    const response = await axios.post(
      `${URL}kecamatan`,
      { kabupaten: modifiedKabko },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setKec(response.data.data);
    setLoadingKec(false);
  };

  const addDotAfterSecondAndFourthChar = (str) => {
    if (str.length > 4) {
      return str.slice(0, 2) + "." + str.slice(2, 4) + "." + str.slice(4);
    }
    return str;
  };

  const handleDesa = async (kec) => {
    setLoadingDesa(true);
    setPilihDesa("");
    setDesa([]);
    const token = localStorage.getItem("token");
    const modifiedkec = addDotAfterSecondAndFourthChar(kec);
    const response = await axios.post(
      `${URL}desa`,
      { kecamatan: modifiedkec },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setDesa(response.data.data);
    setLoadingDesa(false);
  };

  useEffect(() => {
    fetchKodeProvinsi();
    fetchStatusOrtu();
    fetchKodePendidikan();
    fetchKodePekerjaan();
    fetchKodePenghasilan();
    fetchKodeStatusRumahOrtu();
    fetchKodeAgama();
    fetchKodeStatusRumahSiswa();
    fetchKodeJarak();
    fetchKodeWaktu();
    fetchKodeBiaya();
    fetchKodeKebKhusus();
    fetchKodeDisabilitas();
    fetchKodeTransportasi();
    fetchKodeHobi();
    fetchKodeCita();
  }, []);

  return (
    <div>
      <h5>Kitab Template</h5>
      <p className="text-muted">
        Ikuti kode-kode di bawah ini saat pengisian template Excel untuk
        menghindari error!
      </p>
      <div class="tab-content">
        <div class="row">
          <div class="col-xl-3 col-md-4 mt-3">
            <div className="card">
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <div class="widget settings-menu">
                  <ul>
                    <li class="nav-item">
                      <a
                        href="#kode-wilayah"
                        onClick={() => handleTabClick("kode-wilayah")}
                        data-toggle="tab"
                        className={`nav-link ${
                          activeTab === "kode-wilayah" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Wilayah</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-status"
                        onClick={() => handleTabClick("kode-status")}
                        data-toggle="tab"
                        className={`nav-link ${
                          activeTab === "kode-status" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Status Orangtua</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="#kode-pendidikan"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-pendidikan")}
                        className={`nav-link ${
                          activeTab === "kode-pendidikan" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Pendidikan Terakhir</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-pekerjaan"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-pekerjaan")}
                        className={`nav-link ${
                          activeTab === "kode-pekerjaan" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Pekerjaan</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-penghasilan"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-penghasilan")}
                        className={`nav-link ${
                          activeTab === "kode-penghasilan" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Penghasilan</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-status-rumah-ortu"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-status-rumah-ortu")}
                        className={`nav-link ${
                          activeTab === "kode-status-rumah-ortu" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Status Tempat Tinggal Ortu</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-wali"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-wali")}
                        className={`nav-link ${
                          activeTab === "kode-wali" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i> <span>Kode Wali</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-jenis-kelamin"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-jenis-kelamin")}
                        className={`nav-link ${
                          activeTab === "kode-jenis-kelamin" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Jenis Kelamin</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-agama"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-agama")}
                        className={`nav-link ${
                          activeTab === "kode-agama" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i> <span>Kode Agama</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-status-rumah-siswa"
                        data-toggle="tab"
                        onClick={() =>
                          handleTabClick("kode-status-rumah-siswa")
                        }
                        className={`nav-link ${
                          activeTab === "kode-status-rumah-siswa"
                            ? "active"
                            : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Status Rumah Siswa</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-jarak"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-jarak")}
                        className={`nav-link ${
                          activeTab === "kode-jarak" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Jarak Tempat Tinggal</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-waktu"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-waktu")}
                        className={`nav-link ${
                          activeTab === "kode-waktu" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Waktu Tempuh</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-biaya"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-biaya")}
                        className={`nav-link ${
                          activeTab === "kode-biaya" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Biaya Sekolah</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-kebutuhankhusus"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-kebutuhankhusus")}
                        className={`nav-link ${
                          activeTab === "kode-kebutuhankhusus" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Kebutuhan Khusus</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-disabilitas"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-disabilitas")}
                        className={`nav-link ${
                          activeTab === "kode-disabilitas" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Disabilitas</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-transportasi"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-transportasi")}
                        className={`nav-link ${
                          activeTab === "kode-transportasi" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Transportasi</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-hobi"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-hobi")}
                        className={`nav-link ${
                          activeTab === "kode-hobi" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i> <span>Kode Hobi</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#kode-cita"
                        data-toggle="tab"
                        onClick={() => handleTabClick("kode-cita")}
                        className={`nav-link ${
                          activeTab === "kode-cita" ? "active" : ""
                        }`}
                      >
                        <i class="fe fe-git-commit"></i>{" "}
                        <span>Kode Cita Cita</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* CONTENT */}

          <div class="tab-content col-xl-9 col-md-8">
            {/* STATUS WILAYAH */}
            <div
              className={`tab-pane ${
                activeTab === "kode-wilayah" ? "show active" : ""
              }`}
              id="kode-wilayah"
            >
              <div className="card invoices-settings-card">
                <div className="card-header">
                  <h5 className="card-title">Kode Wilayah</h5>
                </div>
                <div className="card-body">
                  {/* PROVINSI */}
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Provinsi</label>
                    <div className="col-lg-9">
                      <select
                        className="form-control"
                        disabled={isLoading}
                        onChange={(e) => {
                          handleKabko(e.target.value);
                          setPilihProvinsi(e.target.value);
                        }}
                      >
                        <option>
                          {isLoading ? "Loading..." : "Pilih Provinsi"}
                        </option>
                        {!isLoading &&
                          kodeProvinsi.map((provinsi, index) => (
                            <option value={provinsi.kode} key={index}>
                              {provinsi.nama}
                            </option>
                          ))}
                      </select>
                      <br />
                      {pilihProvinsi && (
                        <h6 className="text-danger">
                          Kode Provinsi : {pilihProvinsi}
                        </h6>
                      )}
                    </div>
                  </div>

                  {/* KABUPATEN / KOTA */}
                  {pilihProvinsi && (
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">
                        Kabupaten / Kota
                      </label>
                      <div className="col-lg-9">
                        <select
                          className="form-control"
                          disabled={isLoading}
                          onChange={(e) => {
                            setPilihKabko(e.target.value);
                            handleKec(e.target.value);
                          }}
                        >
                          <option>
                            {loadingKabko
                              ? "Loading..."
                              : "Pilih Kabupaten/Kota"}
                          </option>
                          {!loadingKabko &&
                            kabko.map((kabko, index) => (
                              <option value={kabko.kode} key={index}>
                                {kabko.nama}
                              </option>
                            ))}
                        </select>
                        <br />
                        {pilihKabko && (
                          <h6 className="text-danger">
                            Kode Kabupaten/Kota : {pilihKabko}
                          </h6>
                        )}
                      </div>
                    </div>
                  )}

                  {/* KECAMATAN */}
                  {pilihKabko && (
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">
                        Kecamatan
                      </label>
                      <div className="col-lg-9">
                        <select
                          className="form-control"
                          disabled={isLoading}
                          onChange={(e) => {
                            setPilihKec(e.target.value);
                            handleDesa(e.target.value);
                          }}
                        >
                          <option>
                            {loadingKec ? "Loading..." : "Pilih Kecamatan"}
                          </option>
                          {!loadingKec &&
                            kec.map((kec, index) => (
                              <option value={kec.kode} key={index}>
                                {kec.nama}
                              </option>
                            ))}
                        </select>
                        <br />
                        {pilihKec && (
                          <h6 className="text-danger">
                            Kode Kecamatan : {pilihKec}
                          </h6>
                        )}
                      </div>
                    </div>
                  )}

                  {/* DESA */}
                  {pilihKec && (
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">
                        Desa / Kelurahan
                      </label>
                      <div className="col-lg-9">
                        <select
                          className="form-control"
                          disabled={isLoading}
                          onChange={(e) => {
                            setPilihDesa(e.target.value);
                          }}
                        >
                          <option>
                            {loadingDesa
                              ? "Loading..."
                              : "Pilih Desa / Kelurahan"}
                          </option>
                          {!loadingDesa &&
                            desa.map((desa, index) => (
                              <option value={desa.kode} key={index}>
                                {desa.nama}
                              </option>
                            ))}
                        </select>
                        <br />
                        {pilihDesa && (
                          <h6 className="text-danger">
                            Kode Desa / Kelurahan : {pilihDesa}
                          </h6>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* STATUS ORTU */}
            <div
              className={`tab-pane ${
                activeTab === "kode-status" ? "show active" : ""
              }`}
              id="kode-status"
            >
              <div className="card invoices-settings-card">
                <div className="card-header">
                  <h5 className="card-title">Kode Status Ayah / Ibu</h5>
                </div>
                <div className="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Status Ortu</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : statusOrtu.length > 0 ? (
                          statusOrtu.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* PENDIDIKAN */}
            <div
              className={`tab-pane ${
                activeTab === "kode-pendidikan" ? "show active" : ""
              }`}
              id="kode-pendidikan"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Pendidikan Terakhir</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Pendidikan Terakhir</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodePendidikan.length > 0 ? (
                          kodePendidikan.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* PEKERJAAN */}
            <div
              className={`tab-pane ${
                activeTab === "kode-pekerjaan" ? "show active" : ""
              }`}
              id="kode-pekerjaan"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Pekerjaan</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Pekerjaan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodePekerjaan.length > 0 ? (
                          kodePekerjaan.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* PENGHASILAN */}
            <div
              className={`tab-pane ${
                activeTab === "kode-penghasilan" ? "show active" : ""
              }`}
              id="kode-penghasilan"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Penghasilan</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Penghasilan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodePenghasilan.length > 0 ? (
                          kodePenghasilan.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Tempat Tinggal Ortu */}
            <div
              className={`tab-pane ${
                activeTab === "kode-status-rumah-ortu" ? "show active" : ""
              }`}
              id="kode-status-rumah-ortu"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Tempat Tinggal Ortu</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Status Rumah Ortu</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodeStatusRumahOrtu.length > 0 ? (
                          kodeStatusRumahOrtu.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Wali */}
            <div
              className={`tab-pane ${
                activeTab === "kode-wali" ? "show active" : ""
              }`}
              id="kode-wali"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Wali</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Sama dengan ayah kandung</td>
                        </tr>
                        <tr>
                          <td>Sama dengan ibu kandung</td>
                        </tr>
                        <tr>
                          <td>Lainnya</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Jenis Kelamin */}
            <div
              className={`tab-pane ${
                activeTab === "kode-jenis-kelamin" ? "show active" : ""
              }`}
              id="kode-jenis-kelamin"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Jenis Kelamin</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Jenis Kelamin</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Laki-laki</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Perempuan</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Agama */}
            <div
              className={`tab-pane ${
                activeTab === "kode-agama" ? "show active" : ""
              }`}
              id="kode-agama"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Agama</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Agama</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodeAgama.length > 0 ? (
                          kodeAgama.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Status Rumah Siswa */}
            <div
              className={`tab-pane ${
                activeTab === "kode-status-rumah-siswa" ? "show active" : ""
              }`}
              id="kode-status-rumah-siswa"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Status Rumah Siswa</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Status Rumah Siswa</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodeStatusRumahSiswa.length > 0 ? (
                          kodeStatusRumahSiswa.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.residence_siswa_deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Jarak */}
            <div
              className={`tab-pane ${
                activeTab === "kode-jarak" ? "show active" : ""
              }`}
              id="kode-jarak"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Jarak Tempat Tinggal</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Jarak</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodeJarak.length > 0 ? (
                          kodeJarak.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Waktu Tempuh */}
            <div
              className={`tab-pane ${
                activeTab === "kode-waktu" ? "show active" : ""
              }`}
              id="kode-waktu"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Waktu Tempuh</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Waktu Tempuh</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodeWaktu.length > 0 ? (
                          kodeWaktu.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Biaya Sekolah */}
            <div
              className={`tab-pane ${
                activeTab === "kode-biaya" ? "show active" : ""
              }`}
              id="kode-biaya"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Biaya Sekolah</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Yang Membiayai Sekolah</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodeBiaya.length > 0 ? (
                          kodeBiaya.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Kebutuhan Khusus */}
            <div
              className={`tab-pane ${
                activeTab === "kode-kebutuhankhusus" ? "show active" : ""
              }`}
              id="kode-kebutuhankhusus"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Kebutuhan Khusus</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Kebutuhan Khusus</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodeKebKhusus.length > 0 ? (
                          kodeKebKhusus.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Disabilitas */}
            <div
              className={`tab-pane ${
                activeTab === "kode-disabilitas" ? "show active" : ""
              }`}
              id="kode-disabilitas"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Disabilitas</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Disabilitas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodeDisabilitas.length > 0 ? (
                          kodeDisabilitas.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Transportasi */}
            <div
              className={`tab-pane ${
                activeTab === "kode-transportasi" ? "show active" : ""
              }`}
              id="kode-transportasi"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Transportasi</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Transportasi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="2">Loading...</td>
                          </tr>
                        ) : kodeTransportasi.length > 0 ? (
                          kodeTransportasi.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Hobi */}
            <div
              className={`tab-pane ${
                activeTab === "kode-hobi" ? "show active" : ""
              }`}
              id="kode-hobi"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Hobi</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Hobi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {kodeHobi.length > 0 ? (
                          kodeHobi.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.hobi_deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Kode Cita Cita */}
            <div
              className={`tab-pane ${
                activeTab === "kode-cita" ? "show active" : ""
              }`}
              id="kode-cita"
            >
              <div class="card invoices-settings-card">
                <div class="card-header">
                  <h5 class="card-title">Kode Cita Cita</h5>
                </div>
                <div class="card-body">
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Cita-cita</th>
                        </tr>
                      </thead>
                      <tbody>
                        {kodeCita.length > 0 ? (
                          kodeCita.map((status_, index) => (
                            <tr key={index}>
                              <td>{status_.kode}</td>
                              <td>{status_.cita_deskripsi}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KitabTemplates;
