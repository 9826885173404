import React from "react";
import ListFile from "../components/ajuan/ListFile";
import Layouts from "./Layouts";

const File = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <ListFile url={url} />
      </Layouts>
    </div>
  );
};

export default File;
