import React, { useEffect, useState } from "react";
import Layouts from "./Layouts";
import Welcome from "../components/Welcome";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe, LoginUserEmis, ValidasiToken } from "../features/authSlice";

const Dashboard = ({ url }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LoginUserEmis());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ValidasiToken());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  return (
    <Layouts url={url}>
      <Welcome url={url} />
    </Layouts>
  );
};

export default Dashboard;
