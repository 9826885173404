import React from "react";
import Siswa from "./Tools/TarikData/Siswa";
import Guru from "./Tools/TarikData/Guru";
import DetailSiswa from "./DetailSiswa";

const TarikDataEmis = ({ content, url, title }) => {
  const isi = content;

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card bg-white">
          <div className="card-header">
            <h5 className="card-title">
              {title} {isi}
            </h5>
          </div>
          <div className="card-body">
            {/* {isi === "SISWA" ? <Siswa url={url} /> || isi==="DETAIL SISWA"?<DetailSiswa url={url} /> : <Guru url={url} />} */}
            {isi === "SISWA" ? (
              <Siswa url={url} />
            ) : isi === "DETAIL SISWA" ? (
              <DetailSiswa url={url} />
            ) : (
              <Guru url={url} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TarikDataEmis;
