import React from 'react';
import './BlockUI.css';
import turtle from '../turtle.gif';

const BlockUIku = (props) => {
	if (!props.blocking) {
		return '';
	}

	return (
		<div>
			<div className="block-ui-container">
				<div className="block-ui-overlay" />
				<div className="block-ui-message-container">
					<div className="block-ui-message">
						<h4 className="text-center">Sabar Nggih...</h4>
						<h6 className="text-center">{!props.title ? ' ' : `Mengambil Data ${props.title}`}</h6>
						<h6 className="text-center">{!props.judul1 ? ' ' : `Mengambil Data`}</h6>
						<h6 className="text-center">{!props.judul1 ? ' ' : `${props.judul1}`}</h6>
						<h6 className="text-center">{!props.judul2 ? ' ' : `${props.judul2}`}</h6>
						<h6 className="text-center">{props.subtitle}</h6>

						<div className="loading-indicator">
							<img src={turtle} alt="turtle" width={180} />
							{/* <svg id="indicator" viewBox="0 0 100 100">
                <circle id="circle" cx="50" cy="50" r="45" />
              </svg> */}
						</div>
						{props.progress && (
							<div className="progress-container px-3">
								<div className="progress mt-3 w-100">
									<div
										className="progress-bar"
										role="progressbar"
										style={{
											width: `${props.progress}%`, // Update width dynamically
											background: 'linear-gradient(to right, #00ff00, #008000)' // Green gradient
										}} // Update width dynamically
										aria-valuenow={props.progress} // Update aria-valuenow dynamically
										aria-valuemin="0"
										aria-valuemax="100"
									/>
								</div>
								<h6 className="text-center mt-4">
									<strong>{Math.floor(props.progress)}%</strong>
								</h6>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

BlockUIku.defaultProps = {
	blocking: false,
	// title: "Sabar Nggih...",
	subtitle: 'Jangan menutup halaman ini!'
};

export default BlockUIku;
