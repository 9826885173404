import React from 'react';
import Layouts from './Layouts';
import Padankan from '../components/buduk/Padankan';

const Pemadanan = ({ url }) => {
	return (
		<div>
			<Layouts url={url}>
				<Padankan url={url} />
			</Layouts>
		</div>
	);
};

export default Pemadanan;
