import React, { useEffect } from "react";
import ToolsEmis from "../components/ToolsEmis";
import Layouts from "./Layouts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe, LoginUserEmis, ValidasiToken } from "../features/authSlice";

const Tools = ({ url }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LoginUserEmis());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ValidasiToken());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  useEffect(() => {
    const checkInstitution = setInterval(() => {
      const nsmlokal = localStorage.getItem("nsm");
      const localstrg = localStorage.getItem("institution");

      if (localstrg) {
        const institutionArray = JSON.parse(localstrg);
        const nsmEmis = institutionArray[0].statistic_num;

        if (nsmlokal !== nsmEmis) {
          navigate("/dashboard");
        }

        clearInterval(checkInstitution); // Stop checking once the condition is met
      }
    }, 1000); // Check every second

    return () => clearInterval(checkInstitution); // Cleanup interval on component unmount
  }, []);

  return (
    <Layouts url={url}>
      <ToolsEmis url={url} />
    </Layouts>
  );
};

export default Tools;
