import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";

const UploadDataAlamat = ({ url }) => {
  const [loading, setLoading] = useState(false);
  const [loadingKirim, setLoadingKirim] = useState(false);
  const [ambilAlamat, setAmbilAlamat] = useState([]);
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);
  const [search, setSearch] = useState("");
  const [redCellCount, setRedCellCount] = useState(0);
  const [skeleton, setSkeleton] = useState(false);
  const [skeletonUpload, setSkeletonUpload] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [validationProvAyah, setValidationProvAyah] = useState({});
  const [validationProvIbu, setValidationProvIbu] = useState({});
  const [validationKabkoAyah, setValidationKabkoAyah] = useState({});
  const [validationKabkoIbu, setValidationKabkoIbu] = useState({});
  const [validationKabkoWali, setValidationKabkoWali] = useState({});
  const [validationKabkoSiswa, setValidationKabkoSiswa] = useState({});
  const [validationKecAyah, setValidationKecAyah] = useState({});
  const [validationKecIbu, setValidationKecIbu] = useState({});
  const [validationKecWali, setValidationKecWali] = useState({});
  const [validationKecSiswa, setValidationKecSiswa] = useState({});
  const [residenceOrtu, setResidenceOrtu] = useState([]);
  const [residenceSiswa, setResidenceSiswa] = useState([]);
  const [provAyah, setProvAyah] = useState([]);
  const [provIbu, setProvIbu] = useState([]);
  const [provWali, setProvWali] = useState([]);
  const [provSiswa, setProvSiswa] = useState([]);
  const [kabkoAyah, setKabkoAyah] = useState([]);
  const [kabkoIbu, setKabkoIbu] = useState([]);
  const [kabkoWali, setKabkoWali] = useState([]);
  const [kabkoSiswa, setKabkoSiswa] = useState([]);
  const [kecAyah, setKecAyah] = useState([]);
  const [kecIbu, setKecIbu] = useState([]);
  const [kecWali, setKecWali] = useState([]);
  const [kecSiswa, setKecSiswa] = useState([]);
  const [desaAyah, setDesaAyah] = useState([]);
  const [desaIbu, setDesaIbu] = useState([]);
  const [desaWali, setDesaWali] = useState([]);
  const [desaSiswa, setDesaSiswa] = useState([]);
  const [jarak, setJarak] = useState([]);
  const [transport, setTransport] = useState([]);
  const [waktu, setWaktu] = useState([]);

  const URL = url;

  // Fetch residence data
  const fetchResidenceOrtu = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${URL}residence_ortu`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setResidenceOrtu(JSON.stringify(response.data));
  };

  // Fetch jarak
  const fetchJarak = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${URL}jarak`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setJarak(JSON.stringify(response.data));
  };
  // Fetch transportasi
  const fetchTransport = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${URL}transportasi`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setTransport(JSON.stringify(response.data));
  };

  // Fetch waktu
  const fetchWaktu = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${URL}waktu`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setWaktu(JSON.stringify(response.data));
  };

  // Fetch residence data
  const fetchResidenceSiswa = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${URL}residence_siswa`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setResidenceSiswa(JSON.stringify(response.data));
  };

  // Fetch province data
  const fetchProvAyah = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${URL}provinsi`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProvAyah(JSON.stringify(response.data));
  };
  // Fetch province data
  const fetchProvIbu = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${URL}provinsi`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProvIbu(JSON.stringify(response.data));
  };
  // Fetch province data
  const fetchProvWali = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${URL}provinsi`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProvWali(JSON.stringify(response.data));
  };

  // Fetch province data
  const fetchProvSiswa = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${URL}provinsi`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProvSiswa(JSON.stringify(response.data));
  };

  // Fetch address upload data
  const fetchListUploadAlamat = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${URL}get_alamat_upload`,
        { nsm: localStorage.getItem("nsm") },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        setAmbilAlamat(JSON.stringify(response.data));
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError(
        error.response && error.response.status === 404
          ? "History upload template alamat tidak ditemukan, Silahkan upload template alamat terlebih dahulu !"
          : "Terjadi kesalahan saat mengambil data"
      );
    }
  };

  // Fetch kabupaten data based on provinsi
  const fetchKabkoAyah = async (prov_ayah) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${URL}kabupaten`,
      { provinsi: prov_ayah },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };
  // Fetch kabupaten data based on provinsi
  const fetchKabkoIbu = async (prov_ibu) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${URL}kabupaten`,
      { provinsi: prov_ibu },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };
  // Fetch kabupaten data based on provinsi
  const fetchKabkoWali = async (prov_wali) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${URL}kabupaten`,
      { provinsi: prov_wali },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };
  // Fetch kabupaten data based on provinsi
  const fetchKabkoSiswa = async (prov_siswa) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${URL}kabupaten`,
      { provinsi: prov_siswa },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };

  // Untuk menambahkan karakter titik (.) setelah kedua karakter
  const addDotAfterSecondChar = (str) => {
    if (str.length > 2) {
      return str.slice(0, 2) + "." + str.slice(2);
    }
    return str;
  };

  // Untuk menambahkan karakter titik (.) setelah kedua dan keempat karakter
  const addDotAfterSecondAndFourthChar = (str) => {
    if (str.length > 4) {
      return str.slice(0, 2) + "." + str.slice(2, 4) + "." + str.slice(4);
    }
    return str;
  };

  // Fetch kecamatan data based on kabupaten
  const fetchKecAyah = async (kabko_ayah) => {
    const token = localStorage.getItem("token");
    const modifiedKabkoAyah = addDotAfterSecondChar(kabko_ayah);
    const response = await axios.post(
      `${URL}kecamatan`,
      { kabupaten: modifiedKabkoAyah },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };
  // Fetch kecamatan data based on kabupaten
  const fetchKecIbu = async (kabko_ibu) => {
    const token = localStorage.getItem("token");
    const modifiedKabkoIbu = addDotAfterSecondChar(kabko_ibu);
    const response = await axios.post(
      `${URL}kecamatan`,
      { kabupaten: modifiedKabkoIbu },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };
  // Fetch kecamatan data based on kabupaten
  const fetchKecWali = async (kabko_wali) => {
    const token = localStorage.getItem("token");
    const modifiedKabkoWali = addDotAfterSecondChar(kabko_wali);
    const response = await axios.post(
      `${URL}kecamatan`,
      { kabupaten: modifiedKabkoWali },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };
  // Fetch kecamatan data based on kabupaten
  const fetchKecSiswa = async (kabko_siswa) => {
    const token = localStorage.getItem("token");
    const modifiedKabkoSiswa = addDotAfterSecondChar(kabko_siswa);
    const response = await axios.post(
      `${URL}kecamatan`,
      { kabupaten: modifiedKabkoSiswa },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };

  // Fetch desa data based on kecamatan
  const fetchDesaAyah = async (kec_ayah) => {
    const token = localStorage.getItem("token");
    const modifiedkecAyah = addDotAfterSecondAndFourthChar(kec_ayah);
    const response = await axios.post(
      `${URL}desa`,
      { kecamatan: modifiedkecAyah },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };
  // Fetch desa data based on kecamatan
  const fetchDesaIbu = async (kec_ibu) => {
    const token = localStorage.getItem("token");
    const modifiedkecIbu = addDotAfterSecondAndFourthChar(kec_ibu);
    const response = await axios.post(
      `${URL}desa`,
      { kecamatan: modifiedkecIbu },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };
  // Fetch desa data based on kecamatan
  const fetchDesaWali = async (kec_wali) => {
    const token = localStorage.getItem("token");
    const modifiedkecWali = addDotAfterSecondAndFourthChar(kec_wali);
    const response = await axios.post(
      `${URL}desa`,
      { kecamatan: modifiedkecWali },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };
  // Fetch desa data based on kecamatan
  const fetchDesaSiswa = async (kec_siswa) => {
    const token = localStorage.getItem("token");
    const modifiedkecSiswa = addDotAfterSecondAndFourthChar(kec_siswa);
    const response = await axios.post(
      `${URL}desa`,
      { kecamatan: modifiedkecSiswa },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };

  // Validate kabko_ayah data
  const validateKabkoAyah = (kabko_ayah, prov_ayah) => {
    const validationData = kabkoAyah[prov_ayah] || [];
    return validationData.some((item) => item.kode === kabko_ayah);
  };

  // Validate kabko_ibu data
  const validateKabkoIbu = (kabko_ibu, prov_ibu) => {
    const validationData = kabkoIbu[prov_ibu] || [];
    return validationData.some((item) => item.kode === kabko_ibu);
  };

  // Validate kabko_wali data
  const validateKabkoWali = (kabko_wali, prov_wali) => {
    const validationData = kabkoWali[prov_wali] || [];
    return validationData.some((item) => item.kode === kabko_wali);
  };

  // Validate kabko_siswa data
  const validateKabkoSiswa = (kabko_siswa, prov_siswa) => {
    const validationData = kabkoSiswa[prov_siswa] || [];
    return validationData.some((item) => item.kode === kabko_siswa);
  };

  // Validate kec_ayah data
  const validateKecAyah = (kec_ayah, kabko_ayah) => {
    const validationData = kecAyah[kabko_ayah] || [];
    return validationData.some((item) => item.kode === kec_ayah);
  };
  // Validate kec_ibu data
  const validateKecIbu = (kec_ibu, kabko_ibu) => {
    const validationData = kecIbu[kabko_ibu] || [];
    return validationData.some((item) => item.kode === kec_ibu);
  };
  // Validate kec_wali data
  const validateKecWali = (kec_wali, kabko_wali) => {
    const validationData = kecWali[kabko_wali] || [];
    return validationData.some((item) => item.kode === kec_wali);
  };
  // Validate kec_siswa data
  const validateKecSiswa = (kec_siswa, kabko_siswa) => {
    const validationData = kecSiswa[kabko_siswa] || [];
    return validationData.some((item) => item.kode === kec_siswa);
  };

  // Validate desa_ayah data
  const validateDesaAyah = (desa_ayah, kec_ayah) => {
    const validationData = desaAyah[kec_ayah] || [];
    return validationData.some((item) => item.kode === desa_ayah);
  };
  // Validate desa_ibu data
  const validateDesaIbu = (desa_ibu, kec_ibu) => {
    const validationData = desaIbu[kec_ibu] || [];
    return validationData.some((item) => item.kode === desa_ibu);
  };
  // Validate desa_wali data
  const validateDesaWali = (desa_wali, kec_wali) => {
    const validationData = desaWali[kec_wali] || [];
    return validationData.some((item) => item.kode === desa_wali);
  };
  // Validate desa_siswa data
  const validateDesaSiswa = (desa_siswa, kec_siswa) => {
    const validationData = desaSiswa[kec_siswa] || [];
    return validationData.some((item) => item.kode === desa_siswa);
  };

  // Define table columns
  const columns = [
    {
      name: "ACTION",
      cell: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDelete(row.kode_enkripsi)}
        >
          <i className="feather-trash-2"></i>
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "NAMA",
      selector: (row) => row.full_name,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.full_name,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "STS RUMAH AYAH",
      selector: (row) => row.sts_rumah_ayah,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.sts_rumah_ayah ||
            !parsedStsRumahAyah.some(
              (item) => item.kode === row.sts_rumah_ayah
            ),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "PROV AYAH",
      selector: (row) => row.prov_ayah,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.prov_ayah ||
            !parsedProvAyah.some((item) => item.kode === row.prov_ayah),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KABKO AYAH",
      selector: (row) => row.kabko_ayah,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !validateKabkoAyah(row.kabko_ayah, row.prov_ayah),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KEC AYAH",
      selector: (row) => row.kec_ayah,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !validateKabkoAyah(row.kabko_ayah, row.prov_ayah) ||
            !validateKecAyah(row.kec_ayah, row.kabko_ayah),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "DESA AYAH",
      selector: (row) => row.des_ayah,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !validateKabkoAyah(row.kabko_ayah, row.prov_ayah) ||
            !validateKecAyah(row.kec_ayah, row.kabko_ayah) ||
            !validateDesaAyah(row.des_ayah, row.kec_ayah),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "RT AYAH",
      selector: (row) => row.rt_ayah,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.rt_ayah,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "RW AYAH",
      selector: (row) => row.rw_ayah,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.rw_ayah,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "ALAMAT AYAH",
      selector: (row) => row.alamat_ayah,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.alamat_ayah,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KODEPOS AYAH",
      selector: (row) => row.kdpos_ayah,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.kdpos_ayah,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "STS RUMAH IBU",
      selector: (row) => row.sts_rumah_ibu,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.sts_rumah_ibu ||
            !parsedStsRumahIbu.some((item) => item.kode === row.sts_rumah_ibu),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "PROV IBU",
      selector: (row) => row.prov_ibu,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.prov_ibu ||
            !parsedProvIbu.some((item) => item.kode === row.prov_ibu),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KABKO IBU",
      selector: (row) => row.kabko_ibu,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !validateKabkoIbu(row.kabko_ibu, row.prov_ibu),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KEC IBU",
      selector: (row) => row.kec_ibu,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !validateKabkoIbu(row.kabko_ibu, row.prov_ibu) ||
            !validateKecIbu(row.kec_ibu, row.kabko_ibu),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "DESA IBU",
      selector: (row) => row.des_ibu,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !validateKabkoIbu(row.kabko_ibu, row.prov_ibu) ||
            !validateKecIbu(row.kec_ibu, row.kabko_ibu) ||
            !validateDesaIbu(row.des_ibu, row.kec_ibu),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "RT IBU",
      selector: (row) => row.rt_ibu,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.rt_ibu,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "RW IBU",
      selector: (row) => row.rw_ibu,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.rw_ibu,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "ALAMAT IBU",
      selector: (row) => row.alamat_ibu,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.alamat_ibu,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KODEPOS IBU",
      selector: (row) => row.kdpos_ibu,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.kdpos_ibu,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "STS RUMAH WALI",
      selector: (row) => row.sts_rumah_wali,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.sts_rumah_wali ||
            !parsedStsRumahWali.some(
              (item) => item.kode === row.sts_rumah_wali
            ),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "PROV WALI",
      selector: (row) => row.prov_wali,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.prov_wali ||
            !parsedProvWali.some((item) => item.kode === row.prov_wali),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KABKO WALI",
      selector: (row) => row.kabko_wali,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !validateKabkoWali(row.kabko_wali, row.prov_wali),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KEC WALI",
      selector: (row) => row.kec_wali,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !validateKabkoWali(row.kabko_wali, row.prov_wali) ||
            !validateKecWali(row.kec_wali, row.kabko_wali),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "DESA WALI",
      selector: (row) => row.des_wali,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !validateKabkoWali(row.kabko_wali, row.prov_wali) ||
            !validateKecWali(row.kec_wali, row.kabko_wali) ||
            !validateDesaWali(row.des_wali, row.kec_wali),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "RT WALI",
      selector: (row) => row.rt_wali,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.rt_wali,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "RW WALI",
      selector: (row) => row.rw_wali,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.rw_wali,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "ALAMAT WALI",
      selector: (row) => row.alamat_wali,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.alamat_wali,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KODEPOS WALI",
      selector: (row) => row.kdpos_wali,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.kdpos_wali,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "STTS TEMPAT TINGGAL SISWA",
      selector: (row) => row.sts_rumah_siswa,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.sts_rumah_siswa ||
            !parsedStsRumahSiswa.some(
              (item) => item.kode === row.sts_rumah_siswa
            ),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "PROV SISWA",
      selector: (row) => row.prov_siswa,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.prov_siswa ||
            !parsedProvSiswa.some((item) => item.kode === row.prov_siswa),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KABKO SISWA",
      selector: (row) => row.kabko_siswa,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !validateKabkoSiswa(row.kabko_siswa, row.prov_siswa),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KEC SISWA",
      selector: (row) => row.kec_siswa,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !validateKabkoSiswa(row.kabko_siswa, row.prov_siswa) ||
            !validateKecSiswa(row.kec_siswa, row.kabko_siswa),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "DESA SISWA",
      selector: (row) => row.des_siswa,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !validateKabkoSiswa(row.kabko_siswa, row.prov_siswa) ||
            !validateKecSiswa(row.kec_siswa, row.kabko_siswa) ||
            !validateDesaSiswa(row.des_siswa, row.kec_siswa),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "RT SISWA",
      selector: (row) => row.rt_siswa,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.rt_siswa,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "RW SISWA",
      selector: (row) => row.rw_siswa,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.rw_siswa,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "ALAMAT SISWA",
      selector: (row) => row.alamat_siswa,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.alamat_siswa,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "KODEPOS SISWA",
      selector: (row) => row.kdpos_siswa,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => !row.kdpos_siswa,
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "JARAK KE MADRASAH",
      selector: (row) => row.jarak,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.jarak || !parsedJarak.some((item) => item.kode === row.jarak),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "TRANSPORT KE MADRASAH",
      selector: (row) => row.transport,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.transport ||
            !parsedTransport.some((item) => item.kode === row.transport),
          style: { backgroundColor: "red" },
        },
      ],
    },
    {
      name: "WAKTU TEMPUH",
      selector: (row) => row.waktu,
      sortable: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) =>
            !row.waktu || !parsedWaktu.some((item) => item.kode === row.waktu),
          style: { backgroundColor: "red" },
        },
      ],
    },
  ];

  let parsedAlamat = [];
  let parsedStsRumahAyah = [];
  let parsedProvAyah = [];
  let parsedProvIbu = [];
  let parsedProvWali = [];
  let parsedProvSiswa = [];
  let parsedKabkoAyah = {};
  let parsedKabkoIbu = {};
  let parsedKabkoWali = {};
  let parsedKabkoSiswa = {};
  let parsedKecAyah = {};
  let parsedKecIbu = {};
  let parsedKecWali = {};
  let parsedKecSiswa = {};
  let parsedDesaAyah = {};
  let parsedDesaIbu = {};
  let parsedDesaWali = {};
  let parsedDesaSiswa = {};
  let parsedStsRumahIbu = [];
  let parsedStsRumahWali = [];
  let parsedStsRumahSiswa = [];
  let parsedJarak = [];
  let parsedTransport = [];
  let parsedWaktu = [];

  try {
    parsedStsRumahAyah = residenceOrtu && JSON.parse(residenceOrtu).data;
    parsedStsRumahIbu = residenceOrtu && JSON.parse(residenceOrtu).data;
    parsedStsRumahWali = residenceOrtu && JSON.parse(residenceOrtu).data;
    parsedStsRumahSiswa = residenceSiswa && JSON.parse(residenceSiswa).data;
    parsedAlamat = ambilAlamat && JSON.parse(ambilAlamat).data;
    parsedJarak = jarak && JSON.parse(jarak).data;
    parsedTransport = transport && JSON.parse(transport).data;
    parsedWaktu = waktu && JSON.parse(waktu).data;
    parsedProvAyah = provAyah && JSON.parse(provAyah).data;
    parsedProvIbu = provIbu && JSON.parse(provIbu).data;
    parsedProvWali = provWali && JSON.parse(provWali).data;
    parsedProvSiswa = provSiswa && JSON.parse(provSiswa).data;
    parsedKabkoAyah = validationKabkoAyah && JSON.parse(validationKabkoAyah);
    parsedKabkoIbu = validationKabkoIbu && JSON.parse(validationKabkoIbu);
    parsedKabkoWali = validationKabkoWali && JSON.parse(validationKabkoWali);
    parsedKabkoSiswa = validationKabkoSiswa && JSON.parse(validationKabkoSiswa);
    parsedKecAyah = validationKecAyah && JSON.parse(validationKecAyah);
    parsedKecIbu = validationKecIbu && JSON.parse(validationKecIbu);
    parsedKecWali = validationKecWali && JSON.parse(validationKecWali);
    parsedKecSiswa = validationKecSiswa && JSON.parse(validationKecSiswa);
  } catch (error) {
    // console.error("Error parsing JSON:", error);
  }

  // Filter data based on search value
  const filteredData = parsedAlamat.filter((item) => {
    const fullName = item.full_name ? item.full_name.toLowerCase() : "";
    return fullName.includes(search.toLowerCase());
  });

  // useEffect(() => {

  // }, [ambilAlamat, search]);

  const calculateRedCellCount = () => {
    let count = 0;
    let parsedAlamat = [];
    let parsedStsRumahAyah = [];
    let parsedProvAyah = [];
    let parsedProvIbu = [];
    let parsedProvWali = [];
    let parsedProvSiswa = [];
    let parsedKabkoAyah = {};
    let parsedKabkoIbu = {};
    let parsedKabkoWali = {};
    let parsedKabkoSiswa = {};
    let parsedKecAyah = {};
    let parsedKecIbu = {};
    let parsedKecWali = {};
    let parsedKecSiswa = {};
    let parsedDesaAyah = {};
    let parsedDesaIbu = {};
    let parsedDesaWali = {};
    let parsedDesaSiswa = {};
    let parsedStsRumahIbu = [];
    let parsedStsRumahWali = [];
    let parsedStsRumahSiswa = [];
    let parsedJarak = [];
    let parsedTransport = [];
    let parsedWaktu = [];

    try {
      parsedStsRumahAyah = residenceOrtu && JSON.parse(residenceOrtu).data;
      parsedStsRumahIbu = residenceOrtu && JSON.parse(residenceOrtu).data;
      parsedStsRumahWali = residenceOrtu && JSON.parse(residenceOrtu).data;
      parsedStsRumahSiswa = residenceSiswa && JSON.parse(residenceSiswa).data;
      parsedAlamat = ambilAlamat && JSON.parse(ambilAlamat).data;
      parsedJarak = jarak && JSON.parse(jarak).data;
      parsedTransport = transport && JSON.parse(transport).data;
      parsedWaktu = waktu && JSON.parse(waktu).data;
      parsedProvAyah = provAyah && JSON.parse(provAyah).data;
      parsedProvIbu = provIbu && JSON.parse(provIbu).data;
      parsedProvWali = provWali && JSON.parse(provWali).data;
      parsedProvSiswa = provSiswa && JSON.parse(provSiswa).data;
      parsedKabkoAyah = validationKabkoAyah && JSON.parse(validationKabkoAyah);
      parsedKabkoIbu = validationKabkoIbu && JSON.parse(validationKabkoIbu);
      parsedKabkoWali = validationKabkoWali && JSON.parse(validationKabkoWali);
      parsedKabkoSiswa =
        validationKabkoSiswa && JSON.parse(validationKabkoSiswa);
      parsedKecAyah = validationKecAyah && JSON.parse(validationKecAyah);
      parsedKecIbu = validationKecIbu && JSON.parse(validationKecIbu);
      parsedKecWali = validationKecWali && JSON.parse(validationKecWali);
      parsedKecSiswa = validationKecSiswa && JSON.parse(validationKecSiswa);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    parsedAlamat.forEach((row) => {
      if (
        !row.sts_rumah_ayah ||
        !parsedStsRumahAyah.some((item) => item.kode === row.sts_rumah_ayah)
      )
        count++;
      if (
        !row.prov_ayah ||
        !parsedProvAyah.some((item) => item.kode === row.prov_ayah)
      )
        count++;
      if (!validateKabkoAyah(row.kabko_ayah, row.prov_ayah)) count++;
      if (
        !validateKabkoAyah(row.kabko_ayah, row.prov_ayah) ||
        !validateKecAyah(row.kec_ayah, row.kabko_ayah)
      )
        count++;
      if (
        !validateKabkoAyah(row.kabko_ayah, row.prov_ayah) ||
        !validateKecAyah(row.kec_ayah, row.kabko_ayah) ||
        !validateDesaAyah(row.des_ayah, row.kec_ayah)
      )
        count++;
      if (!row.rt_ayah) count++;
      if (!row.rw_ayah) count++;
      if (!row.alamat_ayah) count++;
      if (!row.kdpos_ayah) count++;
      if (
        !row.sts_rumah_ibu ||
        !parsedStsRumahIbu.some((item) => item.kode === row.sts_rumah_ibu)
      )
        count++;
      if (
        !row.prov_ibu ||
        !parsedProvIbu.some((item) => item.kode === row.prov_ibu)
      )
        count++;
      if (!validateKabkoIbu(row.kabko_ibu, row.prov_ibu)) count++;
      if (
        !validateKabkoIbu(row.kabko_ibu, row.prov_ibu) ||
        !validateKecIbu(row.kec_ibu, row.kabko_ibu)
      )
        count++;
      if (
        !validateKabkoIbu(row.kabko_ibu, row.prov_ibu) ||
        !validateKecIbu(row.kec_ibu, row.kabko_ibu) ||
        !validateDesaIbu(row.des_ibu, row.kec_ibu)
      )
        count++;
      if (!row.rt_ibu) count++;
      if (!row.rw_ibu) count++;
      if (!row.alamat_ibu) count++;
      if (!row.kdpos_ibu) count++;
      if (
        !row.sts_rumah_wali ||
        !parsedStsRumahWali.some((item) => item.kode === row.sts_rumah_wali)
      )
        count++;
      if (
        !row.prov_wali ||
        !parsedProvWali.some((item) => item.kode === row.prov_wali)
      )
        count++;
      if (!validateKabkoWali(row.kabko_wali, row.prov_wali)) count++;
      if (
        !validateKabkoWali(row.kabko_wali, row.prov_wali) ||
        !validateKecWali(row.kec_wali, row.kabko_wali)
      )
        count++;
      if (
        !validateKabkoWali(row.kabko_wali, row.prov_wali) ||
        !validateKecWali(row.kec_wali, row.kabko_wali) ||
        !validateDesaWali(row.des_wali, row.kec_wali)
      )
        count++;
      if (!row.rt_wali) count++;
      if (!row.rw_wali) count++;
      if (!row.alamat_wali) count++;
      if (!row.kdpos_wali) count++;
      if (
        !row.sts_rumah_siswa ||
        !parsedStsRumahSiswa.some((item) => item.kode === row.sts_rumah_siswa)
      )
        count++;
      if (
        !row.prov_siswa ||
        !parsedProvSiswa.some((item) => item.kode === row.prov_siswa)
      )
        count++;
      if (!validateKabkoSiswa(row.kabko_siswa, row.prov_siswa)) count++;
      if (
        !validateKabkoSiswa(row.kabko_siswa, row.prov_siswa) ||
        !validateKecSiswa(row.kec_siswa, row.kabko_siswa)
      )
        count++;
      if (
        !validateKabkoSiswa(row.kabko_siswa, row.prov_siswa) ||
        !validateKecSiswa(row.kec_siswa, row.kabko_siswa) ||
        !validateDesaSiswa(row.des_siswa, row.kec_siswa)
      )
        count++;
      if (!row.rt_siswa) count++;
      if (!row.rw_siswa) count++;
      if (!row.alamat_siswa) count++;
      if (!row.kdpos_siswa) count++;
      if (!row.jarak || !parsedJarak.some((item) => item.kode === row.jarak))
        count++;
      if (
        !row.transport ||
        !parsedTransport.some((item) => item.kode === row.transport)
      )
        count++;
      if (!row.waktu || !parsedWaktu.some((item) => item.kode === row.waktu))
        count++;
    });
    setRedCellCount(count);
  };

  const validasiAll = async () => {
    const fetchData = async () => {
      setLoad(true);
      await fetchResidenceOrtu();
      await fetchResidenceSiswa();
      await fetchProvAyah();
      await fetchProvIbu();
      await fetchProvWali();
      await fetchProvSiswa();
      await fetchJarak();
      await fetchTransport();
      await fetchWaktu();

      // Parse ambilAlamat to get the rows
      let parsedAlamat = [];
      let parsedKabkoAyah = {};
      let parsedKabkoIbu = {};
      let parsedKabkoWali = {};
      let parsedKabkoSiswa = {};
      let parsedKecAyah = {};
      let parsedKecIbu = {};
      let parsedKecWali = {};
      let parsedKecSiswa = {};
      let parsedDesaAyah = {};
      let parsedDesaIbu = {};
      let parsedDesaWali = {};
      let parsedDesaSiswa = {};
      let parsedJarak = [];
      let parsedTransport = [];
      let parsedWaktu = [];

      try {
        parsedAlamat = ambilAlamat && JSON.parse(ambilAlamat).data;
        parsedProvAyah = provAyah && JSON.parse(provAyah).data;
        parsedProvIbu = provIbu && JSON.parse(provIbu).data;
        parsedProvWali = provWali && JSON.parse(provWali).data;
        parsedProvSiswa = provSiswa && JSON.parse(provSiswa).data;
        parsedKabkoAyah =
          validationKabkoAyah && JSON.parse(validationKabkoAyah);
        parsedKabkoIbu = validationKabkoIbu && JSON.parse(validationKabkoIbu);
        parsedKabkoWali =
          validationKabkoWali && JSON.parse(validationKabkoWali);
        parsedKabkoSiswa =
          validationKabkoSiswa && JSON.parse(validationKabkoSiswa);
        parsedKecAyah = validationKecAyah && JSON.parse(validationKecAyah);
        parsedKecIbu = validationKecIbu && JSON.parse(validationKecIbu);
        parsedKecWali = validationKecWali && JSON.parse(validationKecWali);
        parsedKecSiswa = validationKecSiswa && JSON.parse(validationKecSiswa);
        parsedJarak = jarak && JSON.parse(jarak).data;
        parsedTransport = transport && JSON.parse(transport).data;
        parsedWaktu = waktu && JSON.parse(waktu).data;
      } catch (error) {
        // console.error("Error parsing JSON:", error);
      }

      // Fetch kabupaten data for each province
      const kabkoValidationResults = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKabkoAyah(row.prov_ayah);
        kabkoValidationResults[row.prov_ayah] = validationResult.data;
      }
      setKabkoAyah(kabkoValidationResults);

      // Fetch kabupaten data for each province
      const kabkoValidationResultsIbu = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKabkoIbu(row.prov_ibu);
        kabkoValidationResultsIbu[row.prov_ibu] = validationResult.data;
      }
      setKabkoIbu(kabkoValidationResultsIbu);

      // Fetch kabupaten data for each province
      const kabkoValidationResultsWali = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKabkoWali(row.prov_wali);
        kabkoValidationResultsWali[row.prov_wali] = validationResult.data;
      }
      setKabkoWali(kabkoValidationResultsWali);

      // Fetch kabupaten data for each province
      const kabkoValidationResultsSiswa = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKabkoSiswa(row.prov_siswa);
        kabkoValidationResultsSiswa[row.prov_siswa] = validationResult.data;
      }
      setKabkoSiswa(kabkoValidationResultsSiswa);

      // Fetch kec data for each province
      const kecValidationResults = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKecAyah(row.kabko_ayah);
        kecValidationResults[row.kabko_ayah] = validationResult.data;
      }
      setKecAyah(kecValidationResults);

      // Fetch kec data for each province
      const kecValidationResultsIbu = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKecIbu(row.kabko_ibu);
        kecValidationResultsIbu[row.kabko_ibu] = validationResult.data;
      }
      setKecIbu(kecValidationResultsIbu);

      // Fetch kec data for each province
      const kecValidationResultsWali = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKecWali(row.kabko_wali);
        kecValidationResultsWali[row.kabko_wali] = validationResult.data;
      }
      setKecWali(kecValidationResultsWali);

      // Fetch kec data for each province
      const kecValidationResultsSiswa = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKecSiswa(row.kabko_siswa);
        kecValidationResultsSiswa[row.kabko_siswa] = validationResult.data;
      }
      setKecSiswa(kecValidationResultsSiswa);

      // Fetch desa data for each kecamatan
      const desaValidationResults = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchDesaAyah(row.kec_ayah);
        desaValidationResults[row.kec_ayah] = validationResult.data;
      }
      setDesaAyah(desaValidationResults);

      // Fetch desa data for each kecamatan
      const desaValidationResultsIbu = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchDesaIbu(row.kec_ibu);
        desaValidationResultsIbu[row.kec_ibu] = validationResult.data;
      }
      setDesaIbu(desaValidationResultsIbu);

      // Fetch desa data for each kecamatan
      const desaValidationResultsWali = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchDesaWali(row.kec_wali);
        desaValidationResultsWali[row.kec_wali] = validationResult.data;
      }
      setDesaWali(desaValidationResultsWali);

      // Fetch desa data for each kecamatan
      const desaValidationResultsSiswa = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchDesaSiswa(row.kec_siswa);
        desaValidationResultsSiswa[row.kec_siswa] = validationResult.data;
      }
      setDesaSiswa(desaValidationResultsSiswa);

      await fetchListUploadAlamat();
      setLoad(false);
    };
    fetchData();
    calculateRedCellCount();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      await fetchProvAyah();
      await fetchProvIbu();
      await fetchProvWali();
      await fetchProvSiswa();
      await fetchJarak();
      await fetchTransport();
      await fetchWaktu();

      // Parse ambilAlamat to get the rows
      let parsedAlamat = [];
      let parsedStsRumahAyah = [];
      let parsedProvAyah = [];
      let parsedProvIbu = [];
      let parsedProvWali = [];
      let parsedProvSiswa = [];
      let parsedKabkoAyah = {};
      let parsedKabkoIbu = {};
      let parsedKabkoWali = {};
      let parsedKabkoSiswa = {};
      let parsedKecAyah = {};
      let parsedKecIbu = {};
      let parsedKecWali = {};
      let parsedKecSiswa = {};
      let parsedDesaAyah = {};
      let parsedDesaIbu = {};
      let parsedDesaWali = {};
      let parsedDesaSiswa = {};
      let parsedStsRumahIbu = [];
      let parsedStsRumahWali = [];
      let parsedStsRumahSiswa = [];
      let parsedJarak = [];
      let parsedTransport = [];
      let parsedWaktu = [];

      try {
        parsedStsRumahAyah = residenceOrtu && JSON.parse(residenceOrtu).data;
        parsedAlamat = ambilAlamat && JSON.parse(ambilAlamat).data;
        parsedProvAyah = provAyah && JSON.parse(provAyah).data;
        parsedProvIbu = provIbu && JSON.parse(provIbu).data;
        parsedProvWali = provWali && JSON.parse(provWali).data;
        parsedProvSiswa = provSiswa && JSON.parse(provSiswa).data;
        parsedKabkoAyah =
          validationKabkoAyah && JSON.parse(validationKabkoAyah);
        parsedKabkoIbu = validationKabkoIbu && JSON.parse(validationKabkoIbu);
        parsedKabkoWali =
          validationKabkoWali && JSON.parse(validationKabkoWali);
        parsedKabkoSiswa =
          validationKabkoSiswa && JSON.parse(validationKabkoSiswa);
        parsedKecAyah = validationKecAyah && JSON.parse(validationKecAyah);
        parsedKecIbu = validationKecIbu && JSON.parse(validationKecIbu);
        parsedKecWali = validationKecWali && JSON.parse(validationKecWali);
        parsedKecSiswa = validationKecSiswa && JSON.parse(validationKecSiswa);
        parsedStsRumahIbu = residenceOrtu && JSON.parse(residenceOrtu).data;
        parsedStsRumahWali = residenceOrtu && JSON.parse(residenceOrtu).data;
        parsedStsRumahSiswa = residenceSiswa && JSON.parse(residenceSiswa).data;
        parsedJarak = jarak && JSON.parse(jarak).data;
        parsedTransport = transport && JSON.parse(transport).data;
        parsedWaktu = waktu && JSON.parse(waktu).data;
      } catch (error) {
        // console.error("Error parsing JSON:", error);
      }

      // Fetch kabupaten data for each province
      const kabkoValidationResults = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKabkoAyah(row.prov_ayah);
        kabkoValidationResults[row.prov_ayah] = validationResult.data;
      }
      setKabkoAyah(kabkoValidationResults);
      // Fetch kabupaten data for each province
      const kabkoValidationResultsIbu = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKabkoIbu(row.prov_ibu);
        kabkoValidationResultsIbu[row.prov_ibu] = validationResult.data;
      }
      setKabkoIbu(kabkoValidationResultsIbu);
      // Fetch kabupaten data for each province
      const kabkoValidationResultsWali = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKabkoWali(row.prov_wali);
        kabkoValidationResultsWali[row.prov_wali] = validationResult.data;
      }
      setKabkoWali(kabkoValidationResultsWali);

      // Fetch kabupaten data for each province
      const kabkoValidationResultsSiswa = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKabkoSiswa(row.prov_siswa);
        kabkoValidationResultsSiswa[row.prov_siswa] = validationResult.data;
      }
      setKabkoSiswa(kabkoValidationResultsSiswa);

      // Fetch kec data for each kabupaten
      const kecValidationResults = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKecAyah(row.kabko_ayah);
        kecValidationResults[row.kabko_ayah] = validationResult.data;
      }
      setKecAyah(kecValidationResults);
      // Fetch kec data for each kabupaten
      const kecValidationResultsIbu = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKecIbu(row.kabko_ibu);
        kecValidationResultsIbu[row.kabko_ibu] = validationResult.data;
      }
      setKecIbu(kecValidationResultsIbu);
      // Fetch kec data for each kabupaten
      const kecValidationResultsWali = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKecWali(row.kabko_wali);
        kecValidationResultsWali[row.kabko_wali] = validationResult.data;
      }
      setKecWali(kecValidationResultsWali);
      // Fetch kec data for each kabupaten
      const kecValidationResultsSiswa = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchKecSiswa(row.kabko_siswa);
        kecValidationResultsSiswa[row.kabko_siswa] = validationResult.data;
      }
      setKecSiswa(kecValidationResultsSiswa);

      // Fetch desa data for each kecamatan
      const desaValidationResults = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchDesaAyah(row.kec_ayah);
        desaValidationResults[row.kec_ayah] = validationResult.data;
      }
      setDesaAyah(desaValidationResults);
      // Fetch desa data for each kecamatan
      const desaValidationResultsIbu = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchDesaIbu(row.kec_ibu);
        desaValidationResultsIbu[row.kec_ibu] = validationResult.data;
      }
      setDesaIbu(desaValidationResultsIbu);
      // Fetch desa data for each kecamatan
      const desaValidationResultsWali = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchDesaWali(row.kec_wali);
        desaValidationResultsWali[row.kec_wali] = validationResult.data;
      }
      setDesaWali(desaValidationResultsWali);
      // Fetch desa data for each kecamatan
      const desaValidationResultsSiswa = {};
      for (const row of parsedAlamat) {
        const validationResult = await fetchDesaSiswa(row.kec_siswa);
        desaValidationResultsSiswa[row.kec_siswa] = validationResult.data;
      }
      setDesaSiswa(desaValidationResultsSiswa);

      await fetchResidenceOrtu();
      await fetchResidenceSiswa();
      await fetchListUploadAlamat();
      calculateRedCellCount();
      setLoad(false);
    };
    fetchData();
  }, []);

  // Download template
  const downloadTemplateAlamat = async () => {
    setLoading(true);
    setSkeleton(true);

    const token = localStorage.getItem("token");
    try {
      const response = await axios({
        url: `${URL}download_template_alamat`,
        method: "POST",
        data: { nsm: localStorage.getItem("nsm") },
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1]
        : "template_alamat.xlsx";
      const validExtensions = [".xlsx", ".xls"];
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));

      if (!validExtensions.includes(fileExtension)) {
        throw new Error("Invalid file extension");
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setLoading(false);
      setSkeleton(false);
    }
  };

  // Handle file change
  const handleFileChangeAlamat = (e) => {
    setFile(e.target.files[0]);
  };

  // Upload template
  const uploadTemplateAlamat = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const validExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name.slice(file.name.lastIndexOf("."));
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validExtensions.includes(fileExtension)) {
      alert("Invalid file type. Please upload an Excel file.");
      return;
    }

    if (file.size > maxSize) {
      alert("File size exceeds the limit of 5MB.");
      return;
    }

    setLoadingUpload(true);
    setSkeletonUpload(true);

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("nsm", localStorage.getItem("nsm"));

    try {
      setShowModal(false);
      await axios.post(`${URL}upload_template_alamat`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      await fetchListUploadAlamat();
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Template alamat berhasil diupload!",
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file.");
    } finally {
      await fetchListUploadAlamat();
      setLoadingUpload(false);
      setSkeletonUpload(false);
    }
  };

  // handle Delete
  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    try {
      await axios.post(
        URL + "del_alamat_template",
        { id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Deleted",
        text: "Data alamat berhasil dihapus!",
      });
      await fetchListUploadAlamat();
      calculateRedCellCount();
    } catch (error) {
      console.error("Error deleting data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Terjadi kesalahan saat menghapus data",
      });
    }
  };

  // handle Kirim
  // KIRIM
  const kirimData = async () => {
    setLoadingKirim(true);
    const token = localStorage.getItem("token");
    const bear = localStorage.getItem("bear");

    const kirim = await axios.post(
      URL + "push_alamat_template",
      {
        nsm: localStorage.getItem("nsm"),
        bear: bear,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(kirim);
    setLoadingKirim(false);
    Swal.fire({
      icon: "success",
      title: "Berhasil",
      text: "Data alamat berhasil dikirim, Silahkan cek di EMIS!",
    });
  };

  return (
    <div>
      <div className="btn-group">
        {/* Download Template */}
        {loading ? (
          <button className="btn btn-danger" type="button" disabled>
            <span
              className={skeleton ? "spinner-border spinner-border-sm" : ""}
            ></span>
            <span className="text-dark"> Loading...</span>
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={downloadTemplateAlamat}
            type="button"
          >
            Download Template
          </button>
        )}
        {/* Upload Template */}
        {loadingUpload ? (
          <button className="btn btn-danger" type="button" disabled>
            <span
              className={
                skeletonUpload ? "spinner-border spinner-border-sm" : ""
              }
            ></span>
            <span className="text-dark"> Loading...</span>
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => setShowModal(true)}
          >
            Upload Template
          </button>
        )}
        <button
          type="button"
          className="btn btn-info text-white"
          onClick={validasiAll}
        >
          Validasi
        </button>
        {/* Kirim */}
        {loadingKirim === true ? (
          <button className="btn btn-danger" type="button" disabled>
            <span className="spinner-border spinner-border-sm"></span>
            <span className="text-dark"> Loading...</span>
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-success"
            onClick={kirimData}
            disabled={redCellCount > 0}
          >
            Kirim
          </button>
        )}
      </div>

      {/* Modal Upload */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Template Alamat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            onChange={handleFileChangeAlamat}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={uploadTemplateAlamat}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      {/* List Data Template */}
      <div className="row mt-4">
        <div className="table-responsive table-striped table-bordered">
          {load ? (
            <div className="text-center py-5">
              <p>Loading...</p>
            </div>
          ) : error ? (
            <div className="text-center py-5">
              <p className="text-danger">{error}</p>
            </div>
          ) : filteredData.length > 0 ? (
            <div style={{ overflowX: "auto" }}>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                subHeader
                subHeaderComponent={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <p style={{ marginTop: "13px", color: "red" }}>
                        {redCellCount > 0 && (
                          <b>
                            Ada {redCellCount} data yang tidak sesuai / masih
                            kosong
                          </b>
                        )}
                      </p>
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="form-control"
                        style={{ width: "200px" }}
                      />
                    </div>
                  </div>
                }
                fixedHeader
                customStyles={{
                  headCells: {
                    style: {
                      "&:nth-of-type(1)": {
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "#b6d7a8",
                      },
                      "&:nth-of-type(2)": {
                        position: "sticky",
                        left: "100px",
                        zIndex: 1,
                        backgroundColor: "#b6d7a8",
                      },
                      backgroundColor: "#b6d7a8",
                    },
                  },
                  cells: {
                    style: {
                      "&:nth-of-type(1)": {
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "#fff",
                      },
                      "&:nth-of-type(2)": {
                        position: "sticky",
                        left: "100px",
                        zIndex: 1,
                        backgroundColor: "#fff",
                      },
                    },
                  },
                }}
                className="table-bordered table-sm"
              />
            </div>
          ) : (
            <div>Data tidak ditemukan</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadDataAlamat;
