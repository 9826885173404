import React, { useState } from "react";
import axios from "axios";
import HasilTools from "../HasilTools";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CariNamaTTL = ({ url }) => {
  const URL = url && url;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tdkDitemukan, setTdkDitemukan] = useState(false);
  const [skeleton, setSkeleton] = useState("");
  const [namasiswa, setNamaSiswa] = useState("");
  const [tempatlahir, setTempatLahir] = useState("");
  const [tanggallahir, setTanggalLahir] = useState("");
  const [datanamaemis, setDataNamaEmis] = useState([]);

  const getNamaEmis = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const bear = localStorage.getItem("bear");
    setLoading(true);

    if (bear === "undefined") {
      setError("Belum terkoneksi dengan EMIS");
      return;
    }

    const response = await axios.post(
      URL + "namaemis",
      {
        bear: bear,
        nama: namasiswa,
        tpl: tempatlahir,
        tgl: tanggallahir,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.success === true) {
      setDataNamaEmis(response.data);
      setTdkDitemukan(false);
      setError("");
      if (response.data.results.length === 0) {
        setTdkDitemukan(true);
        setError("");
      }
    } else {
      setError(response.data.errors.message);
      setTdkDitemukan(false);
      setDataNamaEmis([]);
    }
    setLoading(false);
  };

  return (
    <div className="tab-pane" id="nama-emis">
      Digunakan untuk melakukan pencarian siswa di EMIS menggunakan Nama dan
      Tempat Tanggal Lahir
      <div className="form-group mt-4 row">
        <div className="col-md-8">
          <form onSubmit={getNamaEmis}>
            <div className="row">
              <table>
                <th>
                  <label>Nama Siswa:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={namasiswa}
                    onChange={(e) => setNamaSiswa(e.target.value)}
                    required
                  />
                </th>
                <th>
                  <label>Tempat Lahir:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={tempatlahir}
                    onChange={(e) => setTempatLahir(e.target.value)}
                    required
                  />
                </th>
                <th>
                  <label>Tanggal Lahir:</label>
                  <input
                    type="date"
                    className="form-control"
                    value={tanggallahir}
                    onChange={(e) => setTanggalLahir(e.target.value)}
                    required
                  />
                </th>
                <th style={{ verticalAlign: "middle" }}>
                  {loading === true ? (
                    <button
                      className="btn btn-danger me-2 mt-4"
                      type="button"
                      disabled
                    >
                      <span
                        className={`${
                          skeleton ? "spinner-border spinner-border-sm" : ""
                        }`}
                      ></span>
                      <span className="text-dark"> Loading...</span>
                    </button>
                  ) : (
                    <button className="btn btn-primary me-2 mt-4" type="submit">
                      Cari
                    </button>
                  )}
                </th>
              </table>
            </div>
          </form>
        </div>
      </div>
      {datanamaemis.results && datanamaemis.results.length > 0 ? (
        <HasilTools
          datanisnemis={datanamaemis.results && datanamaemis.results}
        />
      ) : (
        ""
      )}
      {(datanamaemis.results && datanamaemis.results === null) || error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        ""
      )}
      {tdkDitemukan ? (
        <div className="alert alert-danger">Data tidak ditemukan</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CariNamaTTL;
