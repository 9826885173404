import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "./CetakInvoice.css";

const CetakInvoice = ({ url }) => {
  const { order_id } = useParams();
  const URL = url;
  const [transaksi, setTransaksi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetchTransaksi = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        URL + "trx_by_id",
        {
          order_id: order_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setTransaksi(response.data.data);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        console.log(message);
      }
    } finally {
      const response = await axios.post(URL + "getnsm", {
        nsm: localStorage.getItem("nsm"),
      });
      setData(response.data.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransaksi();
  }, []);

  const getPackageName = (grossAmount) => {
    switch (grossAmount) {
      case 150000:
        return "BIMA SAKTI Paket Professional";
      case 75000:
        return "BIMA SAKTI Paket Premium";
      case 50000:
        return "BIMA SAKTI Paket Standart";
      default:
        return "Unknown";
    }
  };

  const getCategory = (grossAmount) => {
    switch (grossAmount) {
      case 150000:
        return "Paket 3";
      case 75000:
        return "Paket 2";
      case 50000:
        return "Paket 1";
      default:
        return "Unknown";
    }
  };

  function formatRupiah(amount) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(amount);
  }

  const handlePrint = () => {
    navigate("/cetakinvoice/" + order_id);
  };

  return (
    <>
      <div>
        <div className="card invoice-info-card">
          <div className="card-body print-area">
            <div className="invoice-item invoice-item-one">
              <div className="row">
                <div className="col-md-6">
                  <div className="invoice-logo">
                    <img src="/assets/img/logo.png" alt="logo" />
                  </div>
                  <div className="invoice-head">
                    <h2>Invoice</h2>
                    <p>Invoice Number : {transaksi.order_id}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="invoice-info">
                    <strong className="customer-text-one">Invoice From</strong>
                    <h6 className="invoice-name">MICROMEDIA DEV</h6>
                    <p className="invoice-details">https://mmc.biz.id</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="invoice-item invoice-item-two">
              <div className="row">
                <div className="col-md-6">
                  <div className="invoice-info">
                    <strong className="customer-text-one">Billed to</strong>
                    <h6 className="invoice-name">
                      {transaksi.customer_name} - {data.name}
                    </h6>
                    <p className="invoice-details invoice-details-two">
                      {data.institution_address}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="invoice-info invoice-info2">
                    <strong className="customer-text-one">
                      Payment Details
                    </strong>
                    <p className="invoice-details">
                      {transaksi.payment_type} <br />
                      {transaksi.bank} <br />
                      {transaksi.va_numbers}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="invoice-issues-box">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="invoice-issues-date">
                    <p>Due Date : {transaksi.transaction_time}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="invoice-issues-date">
                    <p>Due Amount : {formatRupiah(transaksi.gross_amount)} </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="invoice-item invoice-table-wrap">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="invoice-table table table-center mb-0">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Category</th>
                          <th className="text-end">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{getPackageName(transaksi.gross_amount)}</td>
                          <td>{getCategory(transaksi.gross_amount)}</td>
                          <td className="text-end">
                            {formatRupiah(transaksi.gross_amount)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 col-md-6">
                <div className="invoice-terms">
                  <h6>Notes:</h6>
                  <p className="mb-0">
                    Berlaku sampai akhir semester Ganjil 2024-2025
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="invoice-total-card">
                  <div className="invoice-total-box">
                    <div className="invoice-total-inner">
                      <p className="mb-0">
                        Sub total{" "}
                        <span>{formatRupiah(transaksi.gross_amount)}</span>
                      </p>
                    </div>
                    <div className="invoice-total-footer">
                      <h4>
                        Total Amount{" "}
                        <span>{formatRupiah(transaksi.gross_amount)}</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="invoice-sign text-end">
              <img
                className="img-fluid d-inline-block"
                src="/assets/img/mmcdev.png"
                width={50}
                alt="sign"
              />
              <span className="d-block">MICROMEDIA DEV</span>
            </div>
            <div className="text-center mt-4">
              <button className="btn btn-primary" onClick={handlePrint}>
                Cetak Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CetakInvoice;
