import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import BlockUIku from '../../BlockUI/BlockUIku';
import { openDB } from 'idb';

const ProsesSyncRdm = ({ url }) => {
	const URL = url;
	const [ showModal, setShowModal ] = useState(false);
	const [ showModalNilai, setShowModalNilai ] = useState(false);
	const [ endpoint, setEndpoint ] = useState('');
	const [ status, setStatus ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ progress, setProgress ] = useState(0);
	const [ nilai, setNilai ] = useState([]);
	const [ loadingNilai, setLoadingNilai ] = useState(false);
	const [ tahun, setTahun ] = useState('');
	const [ semester, setSemester ] = useState('');
	const [ getSiswa, setGetSiswa ] = useState([]);

	const SimpanEndpoint = async (endpoint) => {
		const token = localStorage.getItem('token');
		await axios.post(
			`${URL}setendpoint`,
			{ endpoint, nsm: localStorage.getItem('nsm') },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		setShowModal(false);
		GetEndpoint();
		Swal.fire({ title: 'Berhasil', text: 'Data berhasil disimpan', icon: 'success' });
	};

	const GetEndpoint = async () => {
		const token = localStorage.getItem('token');
		const response = await axios.post(
			`${URL}getendpoint`,
			{ nsm: localStorage.getItem('nsm') },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		const data = response.data.data;
		if (data && data.endpoint) {
			localStorage.setItem('endpoint', JSON.stringify(data));
			setEndpoint(data.endpoint);
		} else {
			console.error('Endpoint data is null or undefined');
		}
	};

	useEffect(
		() => {
			const ping = async () => {
				try {
					const endpointData = JSON.parse(localStorage.getItem('endpoint'));
					const response = await axios.get(`${endpointData.endpoint}/api/ping`);
					setStatus(response.data);
				} catch (error) {
					console.error('Ping failed:', error);
					setStatus({ message: 'error' });
				}
			};

			if (endpoint !== '') {
				const intervalId = setInterval(ping, 5000);
				return () => clearInterval(intervalId);
			}
		},
		[ endpoint ]
	);

	useEffect(() => {
		GetEndpoint();
		checkIndexedDBExists('RDMSiswa').then((exists) => {
			if (exists) {
				fetchSiswaFromIndexedDB();
			}
		});
		checkIndexedDBExists('RDMDatabase').then((exists) => {
			if (exists) {
				fetchNilaiFromIndexedDB();
			}
		});
	}, []);

	const checkIndexedDBExists = async (dbName) => {
		const dbs = await window.indexedDB.databases();
		return dbs.some((db) => db.name === dbName);
	};

	const sinkronSiswa = async () => {
		const token = localStorage.getItem('token');
		setLoading(true);
		const response = await axios.post(`${endpoint}/api/siswa`, { nsm: localStorage.getItem('nsm') });
		const data = response.data.data;

		if (!Array.isArray(data)) throw new Error('Expected an array but got: ' + JSON.stringify(data));
		setGetSiswa(data);

		const db = await openDB('RDMSiswa', 1, {
			upgrade(db) {
				const store = db.createObjectStore('siswa', { keyPath: 'siswa_id_rdm' });
				store.createIndex('nisn', 'nisn', { unique: false });
				store.createIndex('nama', 'nama', { unique: false });
			}
		});
		const tx = db.transaction('siswa', 'readwrite');
		const store = tx.objectStore('siswa');
		for (const item of data) {
			if (!item.siswa_id_rdm) item.siswa_id_rdm = item.siswa_id_rdm;
			await store.put(item);
		}
		await tx.done;

		setLoading(false);
		Swal.fire({ title: 'Berhasil', text: 'Data berhasil disinkron', icon: 'success' });
		fetchSiswaFromIndexedDB();
	};

	const sinkronNilai = async () => {
		const token = localStorage.getItem('token');
		setLoadingNilai(true);
		const response = await axios.post(`${endpoint}/api/nilai`, { tahun, semester });
		const data = response.data.data;

		if (!Array.isArray(data)) throw new Error('Expected an array but got: ' + JSON.stringify(data));
		setNilai(data);

		const db = await openDB('RDMDatabase', 1, {
			upgrade(db) {
				const store = db.createObjectStore('nilai', { keyPath: 'id_rapor' });
				store.createIndex('nisn', 'nisn', { unique: false });
				store.createIndex('nama', 'nama', { unique: false });
			}
		});
		const tx = db.transaction('nilai', 'readwrite');
		const store = tx.objectStore('nilai');
		for (const item of data) {
			if (!item.id_rapor) item.id_rapor = item.id_rapor;
			if (item.nisn) item.nisn = item.nisn.replace(/\D/g, '').slice(0, 10); // Remove non-digit characters and ensure 10 digits
			await store.put(item);
		}
		await tx.done;

		setLoadingNilai(false);
		fetchNilaiFromIndexedDB();
		Swal.fire({
			title: response.data.length > 0 ? 'Berhasil' : 'Gagal',
			text: response.data.length > 0 ? 'Data berhasil disinkron' : 'Data tidak ditemukan',
			icon: response.data.length > 0 ? 'success' : 'error'
		});
	};

	const fetchNilaiFromIndexedDB = async () => {
		try {
			const db = await openDB('RDMDatabase', 1);
			const tx = db.transaction('nilai', 'readonly');
			const store = tx.objectStore('nilai');
			const allData = await store.getAll();
			setNilai(allData);
		} catch (error) {
			console.error('Error fetching data from IndexedDB:', error);
		}
	};

	const fetchSiswaFromIndexedDB = async () => {
		try {
			const db = await openDB('RDMSiswa', 1);
			const tx = db.transaction('siswa', 'readonly');
			const store = tx.objectStore('siswa');
			const allData = await store.getAll();
			setGetSiswa(allData);
		} catch (error) {
			console.error('Error fetching data from IndexedDB:', error);
		}
	};

	return (
		<div>
			<h3>Proses Sinkron RDM</h3>
			<BlockUIku blocking={loading} progress={progress} />

			<div className="btn-group">
				<button type="button" className="btn btn-warning" onClick={() => setShowModal(true)}>
					Setting
				</button>
				<button
					type="button"
					className={`btn ${status.message === 'successfully' ? 'btn-success' : 'btn-danger'} text-dark`}
				>
					Status RDM : {status.message === 'successfully' ? 'Online' : 'Offline'}
				</button>
			</div>

			<div className="col-md-12 mt-3">
				<div className="card">
					<div className="card-body">
						<table className="table table-bordered table-striped">
							<thead>
								<tr>
									<th>#</th>
									<th>Modules</th>
									<th>Jumlah Data</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1</td>
									<td>Siswa</td>
									<td>{getSiswa.length}</td>
									<td>
                    {status.message === 'successfully' ? (
                      <button className="btn btn-primary" onClick={sinkronSiswa}>
                        Sinkron
                      </button>
                    ) : (
                      <button className="btn btn-primary" onClick={sinkronSiswa} disabled>
                        Sinkron
                      </button>
                    )}
								
									</td>
								</tr>
								<tr>
									<td>2</td>
									<td>Data Nilai</td>
									<td>{nilai.length}</td>
									<td>
                    {status.message === 'successfully' ? (
                      <button className="btn btn-primary" onClick={() => setShowModalNilai(true)}>
                        Get Data
                      </button>
                    ) : (
                      <button className="btn btn-primary" onClick={() => setShowModalNilai(true)} disabled>
                        Get Data
                      </button>
                    )}
									
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Setting API RDM</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<label>URL Endpoint RDM</label>
					<input
						type="text"
						className="form-control"
						value={endpoint}
						onChange={(e) => setEndpoint(e.target.value)}
						placeholder="https://api-rdm.mtsxxxxx.sch.id"
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowModal(false)}>
						Close
					</Button>
					<Button variant="primary" onClick={() => SimpanEndpoint(endpoint)}>
						Simpan
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showModalNilai} onHide={() => setShowModalNilai(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Sinkron Nilai</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<label>Tahun</label>
					<select className="form-control" onChange={(e) => setTahun(e.target.value)}>
						<option value="">Pilih Tahun</option>
						<option value="2021">2021</option>
						<option value="2022">2022</option>
						<option value="2023">2023</option>
					</select>
					<label>Semester</label>
					<select className="form-control" onChange={(e) => setSemester(e.target.value)}>
						<option value="">Pilih Semester</option>
						<option value="1">1</option>
						<option value="2">2</option>
					</select>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowModalNilai(false)}>
						Close
					</Button>
					<Button variant="success" onClick={sinkronNilai}>
						Sinkron
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default ProsesSyncRdm;
