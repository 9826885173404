import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import jquery from "jquery";
import axios from "axios";

const Sidebar = () => {
  const url = "https://api.sakti.biz.id/api/";
  const pathname = window.location.pathname;
  const [maling, setMaling] = useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [admin, setAdmin] = useState("");
  const [paket, setPaket] = useState("");
  const [layananAktif, setLayananAktif] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const $ = jquery;

  const fetchUser = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(url + "me", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRole(response.data.user.role_id);
      setAdmin(response.data.user.is_admin);
      setPaket(response.data.user.package);
      setLayananAktif(response.data.user.layanan_active);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        console.log(message);
      }
    } finally {
      setLoading(false); // Set loading to false after fetch is complete
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (!loading) {
      const checkInstitution = setInterval(() => {
        const nsmlokal = localStorage.getItem("nsm");
        const localstrg = localStorage.getItem("institution");

        if (localstrg) {
          const institutionArray = JSON.parse(localstrg);
          const nsmEmis = institutionArray[0].statistic_num;

          if (nsmlokal !== nsmEmis) {
            setMaling(true);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Akun EMIS harus sama dengan akun BIMA",
            });
          }

          clearInterval(checkInstitution); // Stop checking once the condition is met
        }
      }, 1000); // Check every second

      return () => clearInterval(checkInstitution); // Cleanup interval on component unmount
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      var sideMenu = function () {
        this.$menuItem = $("#sidebar-menu a");
      };
      function init() {
        var $this = sideMenu;
        $("#sidebar-menu a").on("click", function (e) {
          if ($(this).parent().hasClass("submenu")) {
            e.preventDefault();
          }
          if (!$(this).hasClass("subdrop")) {
            $("ul", $(this).parents("ul:first")).slideUp(350);
            $("a", $(this).parents("ul:first")).removeClass("subdrop");
            $(this).next("ul").slideDown(350);
            $(this).addClass("subdrop");
          } else if ($(this).hasClass("subdrop")) {
            $(this).removeClass("subdrop");
            $(this).next("ul").slideUp(350);
          }
        });
        $("#sidebar-menu ul li.submenu a.active")
          .parents("li:last")
          .children("a:first")
          .addClass("active")
          .trigger("click");
      }
      init();

      // Cleanup function to remove event listeners when component unmounts
      return () => {
        $("#sidebar-menu a").off("click");
      };
    }
  }, [loading]);

  if (loading) {
    return <div>Loading...</div>; // Display loading message or spinner
  }

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll" style={{
          overflowY: "auto", // Enable vertical scrollbar
          maxHeight: "100vh", // Ensure it doesn't exceed viewport height
          position: "relative", // Ensure proper positioning
          display: "block" // Ensure it is displayed as a block element
        }}>
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Main Menu</span>
            </li>
            <li className={pathname === "/dashboard" ? "active" : ""}>
              <NavLink to="/dashboard">
                <i className="feather-grid"></i> <span> Dashboard</span>
              </NavLink>
            </li>
            <li className={pathname === "/package" ? "active" : ""}>
              <NavLink to="/package">
                <i className="feather-shopping-cart"></i>
                <span> List Paket</span>
              </NavLink>
            </li>

            <li className={pathname === "/tutorial" ? "active" : ""}>
              <NavLink to="/tutorial">
                <i className="feather-video"></i> <span> Demo / Tutorial</span>
              </NavLink>
            </li>

            {role === "1" && admin === "1" && (
              <li
                className={
                  pathname === "/system" ? "submenu active" : "submenu"
                }
              >
                <a href="#">
                  <i className="feather-settings"></i>{" "}
                  <span> System Settings </span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li className={pathname === "/system" ? "active" : ""}>
                    <NavLink to="/system">
                      <span> Setting Reference EMIS</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}

            {role === "1" && admin === "1" && (
              <li
                className={
                  pathname === "/listmember" || pathname === "/listtransaksi"
                    ? "submenu active"
                    : "submenu"
                }
              >
                <a href="#">
                  <i className="feather-lock"></i> <span> User Manager </span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li className={pathname === "/listmember" ? "active" : ""}>
                    <NavLink to="/listmember">
                      <span> List Member</span>
                    </NavLink>
                  </li>
                  <li className={pathname === "/listtransaksi" ? "active" : ""}>
                    <NavLink to="/listtransaksi">
                      <span> Transaksi</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}

            {!maling && layananAktif === "1" && (
              <>
                <li className={pathname === "/tools" ? "active" : ""}>
                  <NavLink to="/tools">
                    <i className="feather-codepen"></i>
                    <span> Tools</span>
                  </NavLink>
                </li>

                <li
                  className={
                    pathname === "/getsiswa" ||
                    pathname === "/getguru" ||
                    pathname === "/datasiswa"
                      ? "submenu active"
                      : "submenu"
                  }
                >
                  <a href="#">
                    <i className="feather-download-cloud"></i>{" "}
                    <span> Tarik Data</span>{" "}
                    <span className="menu-arrow"></span>
                  </a>
                  <ul>
                    <li className={pathname === "/getsiswa" ? "active" : ""}>
                      <NavLink to="/getsiswa">
                        <span> Data Siswa</span>
                      </NavLink>
                    </li>
                    <li className={pathname === "/getguru" ? "active" : ""}>
                      <NavLink to="/getguru">
                        <span> Data Guru</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li
                  className={
                    pathname === "/uploadsiswa" ||
                    pathname === "/uploadortu" ||
                    pathname === "/uploadalamat" ||
                    pathname === "/rombelauto"
                      ? "submenu active"
                      : "submenu"
                  }
                >
                  <a href="#">
                    <i className="feather-upload-cloud"></i>{" "}
                    <span> Upload Data</span>{" "}
                    <span className="menu-arrow"></span>
                  </a>
                  <ul>
                    <li className={pathname === "/uploadsiswa" ? "active" : ""}>
                      <NavLink to="/uploadsiswa">
                        <span> Upload Data Siswa</span>
                      </NavLink>
                    </li>
                    <li className={pathname === "/uploadortu" ? "active" : ""}>
                      <NavLink to="/uploadortu">
                        <span> Upload Data Ortu</span>
                      </NavLink>
                    </li>
                    <li
                      className={pathname === "/uploadalamat" ? "active" : ""}
                    >
                      <NavLink to="/uploadalamat">
                        <span> Upload Data Alamat </span>
                      </NavLink>
                    </li>
                    <li className={pathname === "/rombelauto" ? "active" : ""}>
                      <NavLink to="/rombelauto">
                        <span> Masukan Siswa Kedalam Rombel </span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {(paket === "2" || paket === "3") && (
                  <li
                    className={
                      pathname === "/kelolabuduk" ||
                      pathname === "/buduk" ||
                      pathname === "/sync-rdm" ||
                      pathname === "/buduk-rombel" ||
                      pathname === "/padankan" ||
                      pathname === "/mutasibuduk"
                        ? "submenu active"
                        : "submenu"
                    }
                  >
                    <a href="#">
                      <i className="feather-book"></i> <span> Buku Induk</span>{" "}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul>
                      <li
                        className={
                          pathname === "/kelolabuduk" || pathname.startsWith("/buduk-rombel/")
                          ? "active"
                          : ""
                        }
                      >
                        <NavLink to="/kelolabuduk">
                          <span> Kelola Buku Induk</span>
                        </NavLink>
                      </li>
                  
                      <li className={pathname === "/mutasibuduk" ? "active" : ""}>
                        <NavLink to="/mutasibuduk">
                          <span> Data Mutasi Siswa</span>
                        </NavLink>
                      </li>
                  
                      <li className={pathname === "/sync-rdm" ? "active" : ""}>
                        <NavLink to="/sync-rdm">
                          <span> Sinkron RDM</span>
                        </NavLink>
                      </li>
                      <li className={pathname === "/padankan" ? "active" : ""}>
                        <NavLink to="/padankan">
                          <span> Pemadanan Data</span>
                        </NavLink>
                      </li>
                  
                      <li className={pathname === "/buduk" ? "active" : ""}>
                        <NavLink to="/buduk">
                          <span> Buku Induk</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                )}

                {paket === "3" && (
                  <li
                    className={
                      pathname === "/ajuan" ||
                      pathname === "/listajuan" ||
                      pathname === "/file"
                        ? "submenu active"
                        : "submenu"
                    }
                  >
                    <a href="#">
                      <i className="feather-clock"></i>{" "}
                      <span>Ajuan Update Data</span>{" "}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul>
                      <li className={pathname === "/listajuan" ? "active" : ""}>
                        <NavLink to="/listajuan">
                          <span> List Ajuan</span>
                        </NavLink>
                      </li>
                      <li className={pathname === "/file" ? "active" : ""}>
                        <NavLink to="/file">
                          <span> File Manager </span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                )}

                <li className={pathname === "/kitab" ? "active" : ""}>
                  <NavLink to="/kitab">
                    <i className="feather-grid"></i>{" "}
                    <span> Kitab Template</span>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
