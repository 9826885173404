import React, { useState } from "react";
import axios from "axios";
import HasilAlumni from "../HasilAlumni";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BlockUIku from "../../BlockUI/BlockUIku";

const CariAlumniEmis = ({ url }) => {
  const URL = url && url;

  const [blok, setBlok] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tdkDitemukan, setTdkDitemukan] = useState(false);
  const [skeleton, setSkeleton] = useState("");
  const [alumnilembagalain, setAlumniLembagaLain] = useState("");
  const [dataalumnilembagalain, setDataAlumniLembagaLain] = useState([]);

  const getAlumniLembagaLain = async (e) => {
    setBlok(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    const bear = localStorage.getItem("bear");
    setLoading(true);

    if (bear === "undefined") {
      setError("Belum terkoneksi dengan EMIS");
      return;
    }

    const response = await axios.post(
      URL + "alumnilbg",
      {
        bear: bear,
        nsm: alumnilembagalain,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBlok(false);

    if (response.data && response.data) {
      setDataAlumniLembagaLain(response.data);
      setTdkDitemukan(false);
      setError("");
      if (response.data.error) {
        setTdkDitemukan(true);
        setError(response.data.error);
      }
    } else {
      setError("Data tidak ditemukan");
      setTdkDitemukan(false);
      setDataAlumniLembagaLain([]);
    }

    setLoading(false);
  };

  return (
    <div className="tab-pane" id="alumni">
      <BlockUIku blocking={blok} />
      Digunakan untuk melakukan pencarian data alumni EMIS di Lembaga Lain
      <div className="form-group mt-4 row">
        <label className="col-form-label col-md-2">Masukkan NSM</label>
        <div className="col-md-6">
          <form onSubmit={getAlumniLembagaLain}>
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                value={alumnilembagalain}
                onChange={(e) => setAlumniLembagaLain(e.target.value)}
                required
              />

              {loading === true ? (
                <button className="btn btn-danger me-2" type="button" disabled>
                  <span
                    className={`${
                      skeleton ? "spinner-border spinner-border-sm" : ""
                    }`}
                  ></span>
                  <span className="text-dark"> Loading...</span>
                </button>
              ) : (
                <button className="btn btn-primary me-2" type="submit">
                  Cari
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      {dataalumnilembagalain && dataalumnilembagalain ? (
        <HasilAlumni
          datanisnemis={dataalumnilembagalain && dataalumnilembagalain}
        />
      ) : (
        ""
      )}
      {error ? <div className="alert alert-danger">{error}</div> : ""}
    </div>
  );
};

export default CariAlumniEmis;
