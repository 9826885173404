import React from "react";
import Layouts from "./Layouts";
import Tutorial from "../components/Tutorial";

const Video = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <Tutorial url={url} />
      </Layouts>
    </div>
  );
};

export default Video;
