import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Navigate,
} from "react-router-dom";
import { useState, useEffect } from "react";
import Login from "./components/Login";
import Dashboard from "./pages/Dashboard";
import Package from "./pages/Package";
import "react-loading-skeleton/dist/skeleton.css";
import Tools from "./pages/Tools";
import TarikData from "./pages/TarikData";
import UploadData from "./pages/UploadData";
import ListMember from "./pages/ListMember";
import Kitab from "./pages/Kitab";
import KelolaBuduk from "./pages/KelolaBuduk";
import Buduk from "./pages/Buduk";
import Ajuan from "./pages/Ajuan";
import File from "./pages/File";
import Video from "./pages/Video";
import axios from "axios";
import Forbid from "./pages/Forbid";
import Notfound from "./pages/Notfound";
import Register from "./pages/Register";
import Invoice from "./pages/Invoice";
import Cetak from "./components/inv/Cetak";
import LoginMobile from "./components/Mobile/LoginMobile";
import Home from "./components/templatesMobile/pagesMobile/Home";
import DataSiswa from "./components/templatesMobile/pagesMobile/DataSiswa";
import System from "./pages/System";
import SyncRdm from "./pages/SyncRdm";
import DetailRombelBuduk from "./pages/DetailRombelBuduk";
import Pemadanan from "./pages/Pemadanan";
import MutasiBuduk from "./pages/MutasiBuduk";

function App() {
  // const url = "http://localhost:8000/api/";
  const url = "https://api.sakti.biz.id/api/";
  const { id } = useParams();
  const { order_id } = useParams();
  const [role, setRole] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [layananAktif, setLayananAktif] = useState("");
  const [paket, setPaket] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    const token = localStorage.getItem("token");
    try {
      setLoading(true);
      const response = await axios.get(url + "me", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRole(response.data.user.role_id);
      setAdmin(response.data.user.is_admin);
      setLayananAktif(response.data.user.layanan_aktif);
      setPaket(response.data.user.package);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        console.log(message);
      }
    } finally {
      setLoading(false); // Set loading to false after fetch is complete
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while fetching user data
  }

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register url={url} />} />
          <Route path="/dashboard" element={<Dashboard url={url} />} />
          <Route path="/package" element={<Package url={url} />} />
          <Route path="/forbidden" element={<Forbid url={url} />} />
          <Route path="/tutorial" element={<Video url={url} />} />
          <Route path="/invoice/:order_id" element={<Invoice url={url} />} />
          <Route path="/cetakinvoice/:order_id" element={<Cetak url={url} />} />

          {role === "1" && admin === "1" && (
            <Route path="/system" element={<System url={url} />} />
          )}
          {role === "1" && admin === "1" && (
            <Route path="/listmember" element={<ListMember url={url} />} />
          )}

          {layananAktif !== null && paket !== null ? (
            <>
              <Route path="/tools" element={<Tools url={url} />} />
              <Route path="/kelolabuduk" element={<KelolaBuduk url={url} />} />
              <Route path="/buduk" element={<Buduk url={url} />} />
              <Route path="/sync-rdm" element={<SyncRdm url={url} />} />
              <Route path="/padankan" element={<Pemadanan url={url} />} />
              <Route path="/mutasibuduk" element={<MutasiBuduk url={url} />} />
              <Route path="/listajuan" element={<Ajuan url={url} />} />
              <Route path="/file" element={<File url={url} />} />
              <Route
                path="/getsiswa"
                element={
                  <TarikData title="TARIK DATA" content="SISWA" url={url} />
                }
              />
              <Route
                path="/getguru"
                element={<TarikData content="GURU" url={url} />}
              />
              <Route
                path="/uploadsiswa"
                element={
                  <UploadData
                    content="SISWA"
                    title="UPLOAD DATA SISWA"
                    url={url}
                  />
                }
              />
              <Route
                path="/uploadortu"
                element={
                  <UploadData
                    content="ORTU"
                    title="UPLOAD DATA ORANG TUA"
                    url={url}
                  />
                }
              />
              <Route
                path="/uploadalamat"
                element={
                  <UploadData
                    content="ALAMAT"
                    title="UPLOAD DATA ALAMAT"
                    url={url}
                  />
                }
              />
              <Route
                path="/rombelauto"
                element={
                  <UploadData
                    content="Rombel"
                    title="MEMASUKKAN SISWA KEDALAM ROMBEL"
                    url={url}
                  />
                }
              />
              <Route
                path="/getsiswa/:id"
                element={<TarikData par={id} ket="detail" url={url} />}
              />
              <Route
                path="/buduk-rombel/:id"
                element={<DetailRombelBuduk url={url} />}
              />
              <Route path="/kitab" element={<Kitab url={url} />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/forbidden" />} />
          )}
          <Route path="*" element={<Notfound url={url} />} />
          <Route path="/mobile/auth" element={<LoginMobile url={url} />} />
          <Route path="/mobile/home" element={<Home url={url} />} />
          <Route path="/mobile/data-siswa" element={<DataSiswa url={url} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
