import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Register = ({ url }) => {
  const URL = url;
  const [nsm, setNsm] = useState("");
  const [data, setData] = useState([]);
  const [operator, setOperator] = useState("");
  const [kontak, setKontak] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const getNSM = async (e) => {
    e.preventDefault(e);
    try {
      const response = await axios.post(URL + "getnsm", {
        nsm: nsm,
      });
      setData(response.data.data);
    } catch (error) {
      console.error("There was an error fetching the NSM:", error);
    }
  };

  const register = async (e) => {
    e.preventDefault(e);
    try {
      const response = await axios.post(URL + "register", {
        nsm: nsm,
        name: operator,
        kontak: kontak,
        password: password,
      });
      console.log(response.data);
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Akun berhasil didaftarkan, Silahkan login",
      });
      navigate("/");
    } catch (error) {
      console.log(error.response.data.error);
      const errorData = error.response.data.error;
      let errorMessage = "";

      if (typeof errorData === "string") {
        errorMessage = errorData;
      } else if (typeof errorData === "object") {
        for (const [key, value] of Object.entries(errorData)) {
          errorMessage += `${key}: ${value}\n`;
        }
      } else {
        errorMessage = "An error occurred";
      }
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errorMessage,
      });
    }
  };

  return (
    <div class="main-wrapper login-body">
      <div class="login-wrapper">
        <div class="container">
          <div class="loginbox">
            <div class="login-left">
              <img class="img-fluid" src="assets/img/login.png" alt="Logo" />
            </div>
            <div class="login-right">
              <div class="login-right-wrap">
                <h1>Registrasi</h1>
                <p class="account-subtitle">
                  Silahkan lengkapi form di bawah ini
                </p>

                <form onSubmit={getNSM}>
                  <div className="form-group">
                    <label>
                      Masukkan NSM <span class="login-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      value={nsm}
                      onChange={(e) => setNsm(e.target.value)}
                      required
                    />
                    <span>
                      <button className="btn btn-primary mt-2" type="submit">
                        Cari
                      </button>
                    </span>
                  </div>
                </form>

                {data.nsm && (
                  <form onSubmit={register}>
                    <div class="form-group">
                      <label>
                        Username <span class="login-danger">*</span>
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        value={data.nsm}
                        readOnly
                      />
                      <span class="profile-views">
                        <i class="fas fa-lock"></i>
                      </span>
                    </div>
                    <div class="form-group">
                      <label>
                        Nama Madrasah <span class="login-danger">*</span>
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        value={data.name}
                        readOnly
                      />
                      <span class="profile-views">
                        <i class="fas fa-university"></i>
                      </span>
                    </div>
                    <div class="form-group">
                      <label>
                        Nama Operator <span class="login-danger">*</span>
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        value={operator}
                        onChange={(e) => setOperator(e.target.value)}
                        required
                      />
                      <span class="profile-views">
                        <i class="fas fa-user-circle"></i>
                      </span>
                    </div>
                    <div class="form-group">
                      <label>
                        No WA / Kontak <span class="login-danger">*</span>
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        value={kontak}
                        onChange={(e) => setKontak(e.target.value)}
                        required
                      />
                      <span class="profile-views">
                        <i class="fas fa-phone"></i>
                      </span>
                    </div>
                    <div class="form-group">
                      <label>
                        Password <span class="login-danger">*</span>
                      </label>
                      <input
                        class="form-control pass-input"
                        type="text"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span class="profile-views feather-eye toggle-password"></span>
                    </div>

                    <div class=" dont-have">
                      Sudah punya akun? <a href="/">Login</a>
                    </div>
                    <div class="form-group mb-0">
                      <button class="btn btn-primary btn-block" type="submit">
                        Register
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
