import React from "react";

const Top = () => {
  return (
    <div>
      <section className="wrapper dz-mode">
        <div className="menu">
          <button className="toggle-btn">
            <img
              src="/assets_mobile/svg/menu/burger-white.svg"
              alt=""
              className="icon"
            />
          </button>
          <div className="btn-grp d-flex align-items-center gap-16">
            <label
              htmlFor="mode-change"
              className="mode-change d-flex align-items-center justify-content-center"
            >
              <input type="checkbox" id="mode-change" />
              <img
                src="/assets_mobile/svg/menu/sun-white.svg"
                alt="icon"
                className="sun"
              />
              <img
                src="/assets_mobile/svg/menu/moon-white.svg"
                alt="icon"
                className="moon"
              />
            </label>
            <a href="../profile/user-profile.html">
              <img src="/assets_mobile/svg/menu/profile-white.svg" alt="icon" />
            </a>
          </div>
        </div>
        <div className="m-menu__overlay"></div>

        <div className="m-menu">
          <div className="m-menu__header">
            <button className="m-menu__close">
              <img src="/assets_mobile/svg/menu/close-white.svg" alt="icon" />
            </button>
            <div className="menu-user">
              <img
                src="/assets_mobile/images/profile/avatar.png"
                alt="avatar"
              />
              <div>
                <a href="#!">angela mayer</a>
                <h3>Verified user · Membership</h3>
              </div>
            </div>
          </div>
          <ul>
            <li>
              <h2 className="menu-title">menu</h2>
            </li>
            <li>
              <a href="../home.html">
                <div className="d-flex align-items-center gap-16">
                  <span className="icon">
                    <img src="/assets_mobile/svg/menu/pie-white.svg" alt="" />
                  </span>
                  overview
                </div>
                <img
                  src="/assets_mobile/svg/menu/chevron-right-black.svg"
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="../../page.html">
                <div className="d-flex align-items-center gap-16">
                  <span className="icon">
                    <img src="/assets_mobile/svg/menu/page-white.svg" alt="" />
                  </span>
                  pages
                </div>
                <img
                  src="/assets_mobile/svg/menu/chevron-right-black.svg"
                  alt=""
                />
              </a>
            </li>
            <li>
              <h2 className="menu-title">others</h2>
            </li>
            <li>
              <label className="a-label__chevron" htmlFor="item-4">
                <span className="d-flex align-items-center gap-16">
                  <span className="icon">
                    <img src="/assets_mobile/svg/menu/grid-white.svg" alt="" />
                  </span>
                  components
                </span>
                <img
                  src="/assets_mobile/svg/menu/chevron-right-black.svg"
                  alt=""
                />
              </label>
              <input
                type="checkbox"
                id="item-4"
                name="item-4"
                className="m-menu__checkbox"
              />
              <div className="m-menu">
                <div className="m-menu__header">
                  <label className="m-menu__toggle" htmlFor="item-4">
                    <img src="/assets_mobile/svg/menu/back-white.svg" alt="" />
                  </label>
                  <span className="m-menu__header-title">components</span>
                </div>
                <ul>
                  <li>
                    <a href="../../components/splash-screen.html">
                      <div className="d-flex align-items-center gap-16">
                        <span className="icon">
                          <img
                            src="/assets_mobile/svg/menu/box-white.svg"
                            alt="icon"
                          />
                        </span>
                        splash screen
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <label className="a-label__chevron" htmlFor="item-5">
                <span className="d-flex align-items-center gap-16">
                  <span className="icon">
                    <img src="/assets_mobile/svg/menu/gear-white.svg" alt="" />
                  </span>
                  settings
                </span>
                <img
                  src="/assets_mobile/svg/menu/chevron-right-black.svg"
                  alt=""
                />
              </label>
              <input
                type="checkbox"
                id="item-5"
                name="item-5"
                className="m-menu__checkbox"
              />
              <div className="m-menu">
                <div className="m-menu__header">
                  <label className="m-menu__toggle" htmlFor="item-5">
                    <img src="/assets_mobile/svg/menu/back-white.svg" alt="" />
                  </label>
                  <span className="m-menu__header-title">settings</span>
                </div>
                <ul>
                  <li>
                    <a href="../profile/user-address.html">
                      <div className="d-flex align-items-center gap-16">
                        <span className="icon">
                          <img
                            src="/assets_mobile/svg/menu/box-white.svg"
                            alt="icon"
                          />
                        </span>
                        My Address
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="../profile/user-payment.html">
                      <div className="d-flex align-items-center gap-16">
                        <span className="icon">
                          <img
                            src="/assets_mobile/svg/menu/box-white.svg"
                            alt="icon"
                          />
                        </span>
                        Payment Method
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="../profile/change-password.html">
                      <div className="d-flex align-items-center gap-16">
                        <span className="icon">
                          <img
                            src="/assets_mobile/svg/menu/box-white.svg"
                            alt="icon"
                          />
                        </span>
                        Change Password
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="../profile/forgot-password.html">
                      <div className="d-flex align-items-center gap-16">
                        <span className="icon">
                          <img
                            src="/assets_mobile/svg/menu/box-white.svg"
                            alt="icon"
                          />
                        </span>
                        Forgot Password
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="../profile/security.html">
                      <div className="d-flex align-items-center gap-16">
                        <span className="icon">
                          <img
                            src="/assets_mobile/svg/menu/box-white.svg"
                            alt="icon"
                          />
                        </span>
                        Security
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="../profile/user-language.html">
                      <div className="d-flex align-items-center gap-16">
                        <span className="icon">
                          <img
                            src="/assets_mobile/svg/menu/box-white.svg"
                            alt="icon"
                          />
                        </span>
                        Language
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="../profile/notifications.html">
                      <div className="d-flex align-items-center gap-16">
                        <span className="icon">
                          <img
                            src="/assets_mobile/svg/menu/box-white.svg"
                            alt="icon"
                          />
                        </span>
                        Notifications
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="dz-switch">
              <div className="a-label__chevron">
                <div className="d-flex align-items-center gap-16">
                  <span className="icon">
                    <img src="/assets_mobile/svg/menu/moon-white.svg" alt="" />
                  </span>
                  switch to dark mode
                </div>
                <label className="toggle-switch" htmlFor="enableMode">
                  <input
                    type="checkbox"
                    id="enableMode"
                    className="mode-switch"
                  />
                  <span className="slider"></span>
                </label>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Top;
