import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LogOut,
  LoginUserEmis,
  ValidasiToken,
  reset,
  getMeEmis,
} from "../../features/authSlice";
import Swal from "sweetalert2";
import jquery from "jquery";

const $ = jquery;

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [status, setStatus] = useState("");
  const [skeleton, setSkeleton] = useState("");
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
      dispatch(LoginUserEmis());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
      dispatch(ValidasiToken());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setSeconds((seconds) => seconds + 1);
  //     dispatch(getMeEmis());
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    setSkeleton(true);
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
      // teks.substring(0, 5);
      if (
        // localStorage.getItem("sso_token").substring(0, 3) === "ull" &&
        localStorage.getItem("bear") === "undefined"
      ) {
        setStatus(false);
        setSkeleton(true);
      } else {
        setSkeleton(false);
        setStatus(true);
      }
    }, 10);
    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    dispatch(LogOut());
    dispatch(reset());
    localStorage.removeItem("token");
    localStorage.removeItem("nsm");
    localStorage.removeItem("bear");
    localStorage.removeItem("sso_token");
    localStorage.removeItem("institution");
    navigate("/");
  };

  useEffect(() => {
    const checkInstitution = setInterval(() => {
      const nsmlokal = localStorage.getItem("nsm");
      const localstrg = localStorage.getItem("institution");

      if (localstrg) {
        const institutionArray = JSON.parse(localstrg);
        const nsmEmis = institutionArray[0].statistic_num;

        if (nsmlokal !== nsmEmis) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Akun EMIS harus sama dengan akun BIMA",
          });
        }

        clearInterval(checkInstitution); // Stop checking once the condition is met
      }
    }, 1000); // Check every second

    return () => clearInterval(checkInstitution); // Cleanup interval on component unmount
  }, []);

  const getRoleName = (role_id) => {
    switch (role_id) {
      case "1":
        return "Superadmin";
      case "2":
        return "Member";
      case "3":
        return "Admin";
      default:
        return "Unknown";
    }
  };

  const handleMobileMenuToggle = () => {
    $(".main-wrapper").toggleClass("slide-nav");
    $(".sidebar-overlay").toggleClass("opened");
    $("html").toggleClass("menu-opened");
  };

  return (
    <>
      <div className="header">
        <div className="header-left">
          <a href="" className="logo">
            <img src="/assets/img/logo.png" width="10" alt="Logo" />
          </a>
          <a href="" className="logo logo-small">
            <img
              src="/assets/img/icon_apk.png"
              alt="Logo"
              width="30"
              height="30"
            />
          </a>
        </div>

        <div className="menu-toggle">
          <a href="" id="toggle_btn">
            <i className="fas fa-bars"></i>
          </a>
        </div>

        <a
          className="mobile_btn"
          id="mobile_btn"
          href="#"
          onClick={handleMobileMenuToggle} // Add onClick event handler
        >
          <i className="fas fa-bars"></i>
        </a>

        <ul className="nav user-menu">
          <li className="nav-item">
            {status === true && (
              <button
                className="btn me-2"
                style={{
                  background: "linear-gradient(to right, #6ccc1d, #87ff25)", // Add gradient background
                  textAlign: "center", // Center text
                }}
                type="button"
              >
                <span
                  className={`${
                    skeleton ? "spinner-border spinner-border-sm" : ""
                  }`}
                ></span>
                <span>
                  {" "}
                  <strong> Connected </strong>{" "}
                </span>
              </button>
            )}
            {status === false && (
              <button className="btn btn-danger me-2" type="button">
                <span
                  className={`${
                    skeleton ? "spinner-border spinner-border-sm" : ""
                  }`}
                ></span>
                <span>
                  {" "}
                  <strong> Connecting... </strong>
                </span>
              </button>
            )}
          </li>

          <li className="nav-item dropdown noti-dropdown me-2">
            <a
              href="#"
              className="dropdown-toggle nav-link header-nav-list"
              data-bs-toggle="dropdown"
            >
              <img src="/assets/img/icons/header-icon-05.svg" alt />
            </a>
            <div className="dropdown-menu notifications">
              <div className="topnav-dropdown-header">
                <span className="notification-title">Notifications</span>
                <a href="" className="clear-noti">
                  {" "}
                  Clear All{" "}
                </a>
              </div>
              <div className="noti-content">
                <ul className="notification-list">
                  <li className="notification-message">
                    <a href="#">
                      <div className="media d-flex">
                        <span className="avatar avatar-sm flex-shrink-0">
                          <img
                            className="avatar-img rounded-circle"
                            alt="User Image"
                            src="/assets/img/profiles/avatar-02.jpg"
                          />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            <span className="noti-title">Uhuyy</span>{" "}
                            <span className="noti-title">
                              App Upgraded Successfully
                            </span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">
                              4 mins ago
                            </span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <a href="#">View all Notifications</a>
              </div>
            </div>
          </li>

          <li className="nav-item zoom-screen me-2">
            <a href="#" className="nav-link header-nav-list win-maximize">
              <img src="/assets/img/icons/header-icon-04.svg" alt />
            </a>
          </li>

          <li className="nav-item dropdown has-arrow new-user-menus">
            <a
              href="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <div className="user-img">
                <img
                  className="rounded-circle"
                  src="/assets/img/profiles/icon_apk.png"
                  width="31"
                  alt={user && user.user.name}
                />
                <div className="user-text">
                  <h6>{user && user.user.name}</h6>
                  <p className="text-muted mb-0">
                    {user && getRoleName(user.user.role_id)}
                  </p>
                </div>
              </div>
            </a>
            <div className="dropdown-menu">
              <div className="user-header">
                <div className="avatar avatar-sm">
                  <img
                    src="/assets/img/profiles/icon_apk.png"
                    alt="User Image"
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="user-text">
                  <h6>{user && user.user.name}</h6>
                  <p className="text-muted mb-0">
                    {user && getRoleName(user.user.role_id)}
                  </p>
                </div>
              </div>
              {/* <a className="dropdown-item" href="">
              My Profile
            </a>
            <a className="dropdown-item" href="">
              Inbox
            </a> */}
              <button onClick={handleLogout} className="dropdown-item">
                Logout
              </button>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
