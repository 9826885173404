import React, { useState, useEffect } from "react";
import axios from "axios";

const DataSiswaEdit = ({ url }) => {
  const URL = url;
  const [dataSiswa, setDataSiswa] = useState([]);

  const getDataSiswa = async () => {
    const response = await axios.post(`${URL}data_siswa_mobile`, {
      nsm: JSON.parse(localStorage.getItem("dataSiswa")).id_lembaga,
      id: JSON.parse(localStorage.getItem("dataSiswa")).id,
    });
    // console.log(response.data.data);
    setDataSiswa(response.data.data);
  };

  useEffect(() => {
    getDataSiswa();
  }, []);

  return (
    <div>
      <div className="page-title">
        <button
          type="button"
          className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
        >
          <img src="/assets_mobile/svg/arrow-left-black.svg" alt="arrow" />
        </button>
        <h3 className="main-title">Profile</h3>
      </div>

      <section className="profile-image">
        <div className="image">
          <img
            src="/assets_mobile/images/profile/avatar.png"
            alt="avatar"
            className="w-100 h-100 object-fit-cover img-fluid rounded-full"
          />
        </div>
      </section>

      <section className="user-info">
        <div className="mb-2">
          <label className="input-label">Nama Lengkap</label>
          <input
            type="text"
            placeholder="First Name"
            value={JSON.parse(localStorage.getItem("dataSiswa")).full_name}
            className="input"
            readOnly
          />
        </div>
        <div className="mb-2">
          <label className="input-label">NISN</label>
          <input
            type="text"
            placeholder="First Name"
            value={JSON.parse(localStorage.getItem("dataSiswa")).nisn}
            className="input"
            readOnly
          />
        </div>
        <div className="mb-2">
          <label className="input-label">NIS Lokal</label>
          <input
            type="text"
            value={dataSiswa[0].full_name}
            className="input"
            readOnly
          />
        </div>
        <div className="mb-2">
          <label className="input-label">Kewarganegaraan</label>
          <select className="input-field" value={dataSiswa[0].nationality}>
            <option value="wni">wni</option>
            <option value="wna">wna</option>
          </select>
        </div>

        <div className="mb-16">
          <label className="input-label">Gender</label>
          <div className="grid">
            <label
              for="male"
              className="custom-check-container gender-container"
            >
              <input type="radio" name="gender" id="male" checked />
              Male
              <span className="checkmark"></span>
            </label>
            <label
              for="female"
              className="custom-check-container gender-container"
            >
              <input type="radio" name="gender" id="female" />
              Female
              <span className="checkmark"></span>
            </label>
          </div>
        </div>

        <div>
          <label className="input-label">Location</label>
          <textarea
            id="myTextarea"
            placeholder="Location"
            className="input"
          ></textarea>
        </div>
      </section>

      <div className="save-btn mt-64 px-24 mb-32">
        <a href="user-profile.html" className="btn-primary">
          Save Changes
        </a>
      </div>
    </div>
  );
};

export default DataSiswaEdit;
