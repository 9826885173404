import React, { useState, useEffect } from "react";
import axios from "axios";
import BlockUIku from "../../../BlockUI/BlockUIku";
import { v4 as uuidv4 } from "uuid";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";

const Siswa = ({ url }) => {
  const [skeleton, setSkeleton] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [progress, setProgress] = useState(0); // New state for progress
  const [dataSiswa, setDataSiswa] = useState([]);
  const [error, setError] = useState("");
  const [ambilSiswa, setAmbilSiswa] = useState([]);
  const [search, setSearch] = useState("");
  const [paket, setPaket] = useState("");
  const [title, setTitle] = useState("");

  const navigate = useNavigate();

  const URL = url && url;

  const listSiswa = async (e) => {
    const token = localStorage.getItem("token");

    try {
      const getlistSiswa = await axios.post(
        URL + "listsiswa",
        {
          nsm: localStorage.getItem("nsm"),
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getlistSiswa.data.status === true) {
        setAmbilSiswa(JSON.stringify(getlistSiswa.data));
      } else {
        setError(getlistSiswa.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("Data tidak ditemukan, Silahkan tarik data terlebih dahulu !");
      } else {
        setError("Terjadi kesalahan saat mengambil data");
      }
    }
  };

  const fetchUser = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(URL + "me", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setPaket(response.data.user.package);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        console.log(message);
      }
    } finally {
      setLoading(false); // Set loading to false after fetch is complete
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      await fetchUser();
      await listSiswa();
      setLoad(false);
    };
    fetchData();
  }, []);

  let parsedSiswa = [];
  try {
    parsedSiswa = ambilSiswa && JSON.parse(ambilSiswa).data;
  } catch (error) {
    // console.error("Error parsing JSON:", error);
  }

  // Filter data berdasarkan nilai pencarian
  const filteredData = parsedSiswa.filter((item) => {
    const fullName = item.full_name ? item.full_name.toLowerCase() : "";
    const tingkat = item.tingkat ? item.tingkat.toLowerCase() : "";
    const nisn = item.nisn ? item.nisn.toLowerCase() : "";

    return (
      fullName.includes(search.toLowerCase()) ||
      tingkat.includes(search.toLowerCase()) ||
      nisn.includes(search.toLowerCase())
    );
  });

  const downloadCard = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios({
        url: URL + "download_card", // Adjust the URL as needed
        method: "POST",
        data: {
          nsm: localStorage.getItem("nsm"),
        },
        responseType: "blob", // Important for file download
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Create a link element, use it to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `card.pdf`); // Adjust the file name and extension as needed
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the card:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to download the card.",
      });
    }
    setLoading(false);
  };

  const columns = [
    {
      name: "NISN",
      selector: (row) => row.nisn,
      sortable: true,
      // minWidth: "80px",
    },
    {
      name: "NAMA",
      selector: (row) => row.full_name,
      sortable: true,
      // minWidth: "250px",
    },
    {
      name: "TEMPAT LAHIR",
      selector: (row) => row.birth_place,
      sortable: true,
      // minWidth: "100px",
    },
    {
      name: "TANGGAL LAHIR",
      selector: (row) => row.birth_date,
      sortable: true,
      // minWidth: "80px",
    },

    {
      name: "KELAS / ROMBEL",
      selector: (row) => row.tingkat,
      sortable: true,
      // minWidth: "80px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          {/* <button
            className="btn btn-warning btn-sm"
            onClick={() => handleEdit(row)}
          >
            Edit
          </button> */}
          <Link to={`/getsiswa/${row.uuid}`} className="btn btn-warning btn-sm">
            View
          </Link>
          {/* <button
            className="btn btn-danger btn-sm"
            onClick={() => handleDelete(row)}
            style={{ marginLeft: "10px" }}
          >
            Delete
          </button> */}
        </div>
      ),
      // minWidth: "80px",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // GET SISWA EMIS
  const getSiswa = async (e) => {
    setLoading(true);
    setProgress(0); // Reset progress

    e.preventDefault();
    const token = localStorage.getItem("token");
    const bear = localStorage.getItem("bear");

    if (bear === "undefined") {
      setError("Belum terkoneksi dengan EMIS");
      return;
    }
    const institutionArray = JSON.parse(localStorage.getItem("institution"));
    const insId = institutionArray[0].id;

    const response = await axios.post(
      URL + "getsiswalbg",
      {
        bear: bear,
        nsm: localStorage.getItem("nsm"),
        institutionid: insId,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(response);
    const length = response && response.data.length;
    setDataSiswa(JSON.stringify(response && response.data));

    const cryptoKey = "a2c36eb2w1em50d6665dc5d61a68b400";
    const Base64IVkeys = "emisBase64IVkeys";
    const fkey = CryptoJS.enc.Utf8.parse(cryptoKey);

    // security config
    const securityConfig = () => {
      const fiv = CryptoJS.enc.Utf8.parse(Base64IVkeys);
      return {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      };
    };

    for (var i = 0; i < length; i++) {
      setProgress(((i + 1) / length) * 100);

      const id = response.data[i].id;

      // Ensure the ID is a string before encryption
      const idString = id.toString();

      const encrypted = CryptoJS.AES.encrypt(
        idString, // Ensure this is a string
        fkey,
        securityConfig()
      ).toString();
      const base64encoded = CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(encrypted)
      );

      const post = await axios.post(
        URL + "postsiswa",
        {
          // id: response.data[i].id,
          uuid: uuidv4(),
          id_lembaga: localStorage.getItem("nsm"),
          id: response.data[i].id,
          full_name: response.data[i].full_name,
          nisn: response.data[i].nisn,
          birth_place: response.data[i].birth_place,
          birth_date: response.data[i].birth_date,
          nomor_kip: response.data[i].nomor_kip,
          learning_activity_id: response.data[i].learning_activity_id,
          institution_category_id: response.data[i].institution_category_id,
          institution_province_id: response.data[i].institution_province_id,
          student_status_id: response.data[i].student_status_id,
          tingkat: response.data[i].tingkat,
          age: response.data[i].age,
          level_name: response.data[i].level_name,
          study_group_name: response.data[i].study_group_name,
          academic_year_status: response.data[i].academic_year_status,
          is_draftable: response.data[i].is_draftable,
          kode_enkripsi: base64encoded,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );



      const postlearning = await axios.post(
        URL + "postlearning",
        {
          id: response.data[i].learning_activity.id,
          student_id: response.data[i].learning_activity.student_id,
          absent_number: response.data[i].learning_activity.absent_number,
          institution_id: response.data[i].learning_activity.institution_id,
          institution_category_id:
            response.data[i].learning_activity.institution_category_id,
          institution_province_id:
            response.data[i].learning_activity.institution_province_id,
          academic_year_id: response.data[i].learning_activity.academic_year_id,
          m_level_id: response.data[i].learning_activity.m_level_id,
          m_major_id: response.data[i].learning_activity.m_major_id,
          start_date: response.data[i].learning_activity.start_date,
          admission_date: response.data[i].learning_activity.admission_date,
          study_group_id: response.data[i].learning_activity.study_group_id,
          student_status_id:
            response.data[i].learning_activity.student_status_id,
          status_description_id:
            response.data[i].learning_activity.status_description_id,
          created_at: response.data[i].learning_activity.created_at,
          created_from_id: response.data[i].learning_activity.created_from_id,
          updated_from_id: response.data[i].learning_activity.updated_from_id,
          updated_at: response.data[i].learning_activity.updated_at,
          deleted_by: response.data[i].learning_activity.deleted_by,
          deleted_at: response.data[i].learning_activity.deleted_at,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data[i].learning_activity.study_group !== null) {
        const poststudy = await axios.post(
          URL + "poststudy",
          {
            id: response.data[i].learning_activity.study_group.id,
            academic_year_id:
              response.data[i].learning_activity.study_group.academic_year_id,
            room_id: response.data[i].learning_activity.study_group.room_id,
            room_name: response.data[i].learning_activity.study_group.room_name,
            student_capacity:
              response.data[i].learning_activity.study_group.student_capacity,
            m_curriculum_id:
              response.data[i].learning_activity.study_group.m_curriculum_id,
            major_id: response.data[i].learning_activity.study_group.major_id,
            rombel_type_id:
              response.data[i].learning_activity.study_group.rombel_type_id,
            institution_id:
              response.data[i].learning_activity.study_group.institution_id,
            pusdatin_sync_date:
              response.data[i].learning_activity.study_group.pusdatin_sync_date,
            integration_id:
              response.data[i].learning_activity.study_group.integration_id,
            name: response.data[i].learning_activity.study_group.name,
            level: response.data[i].learning_activity.study_group.level,
            teacher_id:
              response.data[i].learning_activity.study_group.teacher_id,
            teacher_name:
              response.data[i].learning_activity.study_group.teacher_name,
            total_student:
              response.data[i].learning_activity.study_group.total_student,
            is_processed:
              response.data[i].learning_activity.study_group.is_processed,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      const academic = await axios.post(
        URL + "postacademicyear",
        {
          id: response.data[i].learning_activity.academic_year.id,
          name: response.data[i].learning_activity.academic_year.name,
          year: response.data[i].learning_activity.academic_year.year,
          semester: response.data[i].learning_activity.academic_year.semester,
          acronym: response.data[i].learning_activity.academic_year.acronym,
          start_date:
            response.data[i].learning_activity.academic_year.start_date,
          end_date: response.data[i].learning_activity.academic_year.end_date,
          is_active: response.data[i].learning_activity.academic_year.is_active,
          has_notified:
            response.data[i].learning_activity.academic_year.has_notified,
          id_default_filter:
            response.data[i].learning_activity.academic_year.id_default_filter,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data[i].learning_activity.m_major !== null) {
        const major = await axios.post(
          URL + "postmajor",
          {
            id: response.data[i].learning_activity.m_major.id,
            code: response.data[i].learning_activity.m_major.code,
            area_id: response.data[i].learning_activity.m_major.area_id,
            name: response.data[i].learning_activity.m_major.name,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      const level = await axios.post(
        URL + "postmlevel",
        {
          id: response.data[i].learning_activity.m_level.id,
          name: response.data[i].learning_activity.m_level.name,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const studentstatus = await axios.post(
        URL + "poststudentstatus",
        {
          id: response.data[i].learning_activity.student_status.id,
          name: response.data[i].learning_activity.student_status.name,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const statusdesc = await axios.post(
        URL + "poststatusdescription",
        {
          id: response.data[i].learning_activity.status_description.id,
          name: response.data[i].learning_activity.status_description.name,
          student_status_id:
            response.data[i].learning_activity.status_description
              .student_status_id,
          created_at:
            response.data[i].learning_activity.status_description.created_at,
          updated_at:
            response.data[i].learning_activity.status_description.updated_at,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const parents = await axios.post(
        URL + "postparents",
        {
          id: response.data[i].parents.id,
          student_id: response.data[i].parents.student_id,
          father_full_name: response.data[i].parents.father_full_name,
          father_m_life_status_id:
            response.data[i].parents.father_m_life_status_id,
          father_nik: response.data[i].parents.father_nik,
          father_birth_place: response.data[i].parents.father_birth_place,
          father_birth_date: response.data[i].parents.father_birth_date,
          father_m_last_education_id:
            response.data[i].parents.father_m_last_education_id,
          father_m_job_id: response.data[i].parents.father_m_job_id,
          father_m_average_income_per_month_id:
            response.data[i].parents.father_m_average_income_per_month_id,
          father_phone_number: response.data[i].parents.father_phone_number,
          is_father_phone_null: response.data[i].parents.is_father_phone_null,
          father_domicile: response.data[i].parents.father_domicile,
          father_m_residence_status_id:
            response.data[i].parents.father_m_residence_status_id,
          father_m_province_id: response.data[i].parents.father_m_province_id,
          father_m_city_id: response.data[i].parents.father_m_city_id,
          father_m_district_id: response.data[i].parents.father_m_district_id,
          father_m_sub_district_id:
            response.data[i].parents.father_m_sub_district_id,
          father_address: response.data[i].parents.father_address,
          father_rt: response.data[i].parents.father_rt,
          father_rw: response.data[i].parents.father_rw,
          father_postal_code: response.data[i].parents.father_postal_code,
          father_kk_file: response.data[i].parents.father_kk_file,
          mother_full_name: response.data[i].parents.mother_full_name,
          mother_m_life_status_id:
            response.data[i].parents.mother_m_life_status_id,
          mother_nik: response.data[i].parents.mother_nik,
          mother_birth_place: response.data[i].parents.mother_birth_place,
          mother_birth_date: response.data[i].parents.mother_birth_date,
          mother_m_last_education_id:
            response.data[i].parents.mother_m_last_education_id,
          mother_m_job_id: response.data[i].parents.mother_m_job_id,
          mother_m_average_income_per_month_id:
            response.data[i].parents.mother_m_average_income_per_month_id,
          mother_phone_number: response.data[i].parents.mother_phone_number,
          is_mother_phone_null: response.data[i].parents.is_mother_phone_null,
          mother_residence: response.data[i].parents.mother_residence,
          mother_domicile: response.data[i].parents.mother_domicile,
          mother_m_residence_status_id:
            response.data[i].parents.mother_m_residence_status_id,
          mother_m_province_id: response.data[i].parents.mother_m_province_id,
          mother_m_city_id: response.data[i].parents.mother_m_city_id,
          mother_m_district_id: response.data[i].parents.mother_m_district_id,
          mother_m_sub_district_id:
            response.data[i].parents.mother_m_sub_district_id,
          mother_address: response.data[i].parents.mother_address,
          mother_rt: response.data[i].parents.mother_rt,
          mother_rw: response.data[i].parents.mother_rw,
          mother_postal_code: response.data[i].parents.mother_postal_code,
          mother_kk_file: response.data[i].parents.mother_kk_file,
          is_kk_same_father: response.data[i].parents.is_kk_same_father,
          wali: response.data[i].parents.wali,
          wali_full_name: response.data[i].parents.wali_full_name,
          wali_nik: response.data[i].parents.wali_nik,
          wali_birth_place: response.data[i].parents.wali_birth_place,
          wali_birth_date: response.data[i].parents.wali_birth_date,
          wali_m_last_education_id:
            response.data[i].parents.wali_m_last_education_id,
          wali_m_job_id: response.data[i].parents.wali_m_job_id,
          wali_m_average_income_per_month_id:
            response.data[i].parents.wali_m_average_income_per_month_id,
          wali_phone_number: response.data[i].parents.wali_phone_number,
          is_wali_phone_null: response.data[i].parents.is_wali_phone_null,
          wali_domicile: response.data[i].parents.wali_domicile,
          wali_m_residence_status_id:
            response.data[i].parents.wali_m_residence_status_id,
          wali_m_province_id: response.data[i].parents.wali_m_province_id,
          wali_m_city_id: response.data[i].parents.wali_m_city_id,
          wali_m_district_id: response.data[i].parents.wali_m_district_id,
          wali_m_sub_district_id:
            response.data[i].parents.wali_m_sub_district_id,
          wali_address: response.data[i].parents.wali_address,
          wali_rt: response.data[i].parents.wali_rt,
          wali_rw: response.data[i].parents.wali_rw,
          wali_postal_code: response.data[i].parents.wali_postal_code,
          wali_kk_file: response.data[i].parents.wali_kk_file,
          wali_kks_number: response.data[i].parents.wali_kks_number,
          wali_pkh_number: response.data[i].parents.wali_pkh_number,
          request_kemensos_date: response.data[i].parents.request_kemensos_date,
          wali_kks_file: response.data[i].parents.wali_kks_file,
          wali_pkh_file: response.data[i].parents.wali_pkh_file,
          created_by: response.data[i].parents.created_by,
          updated_by: response.data[i].parents.updated_by,
          draft_history_id: response.data[i].parents.draft_history_id,
          created_at: response.data[i].parents.created_at,
          updated_at: response.data[i].parents.updated_at,
          father_nationality: response.data[i].parents.father_nationality,
          mother_nationality: response.data[i].parents.mother_nationality,
          wali_nationality: response.data[i].parents.wali_nationality,
          father_country_of_origin:
            response.data[i].parents.father_country_of_origin,
          mother_country_of_origin:
            response.data[i].parents.mother_country_of_origin,
          wali_country_of_origin:
            response.data[i].parents.wali_country_of_origin,
          father_kitas: response.data[i].parents.father_kitas,
          mother_kitas: response.data[i].parents.mother_kitas,
          wali_kitas: response.data[i].parents.wali_kitas,
          father_have_phone_number:
            response.data[i].parents.father_have_phone_number,
          mother_have_phone_number:
            response.data[i].parents.mother_have_phone_number,
          wali_have_phone_number:
            response.data[i].parents.wali_have_phone_number,
          deleted_at: response.data[i].parents.deleted_at,
          if_draftable: response.data[i].parents.if_draftable,
          father_latest_education:
            response.data[i].parents.father_latest_education,
          mother_latest_education:
            response.data[i].parents.mother_latest_education,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const detailsiswa = await axios.post(
        URL + "getdetail",
        {
          bear: bear,
          enkrip: base64encoded,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const length = detailsiswa && detailsiswa.data.length;

      const postds = await axios.post(
        URL + "postsds",
        {
          id_emis: detailsiswa && detailsiswa.data.id,
          nik: detailsiswa && detailsiswa.data.nik,
          local_nis: detailsiswa && detailsiswa.data.local_nis,
          m_gender_id: detailsiswa && detailsiswa.data.m_gender_id,
          child_of_num: detailsiswa && detailsiswa.data.child_of_num,
          siblings_num: detailsiswa && detailsiswa.data.siblings_num,
          m_religion_id: detailsiswa && detailsiswa.data.m_religion_id,
          m_life_goal_id: detailsiswa && detailsiswa.data.m_life_goal_id,
          m_hobby_id: detailsiswa && detailsiswa.data.m_hobby_id,
          m_residence_status_id:
            detailsiswa && detailsiswa.data.m_residence_status_id,
          m_province_id: detailsiswa && detailsiswa.data.m_province_id,
          m_city_id: detailsiswa && detailsiswa.data.m_city_id,
          m_district_id: detailsiswa && detailsiswa.data.m_district_id,
          m_subdistrict_id: detailsiswa && detailsiswa.data.m_subdistrict_id,
          address: detailsiswa && detailsiswa.data.address,
          rt: detailsiswa && detailsiswa.data.rt,
          rw: detailsiswa && detailsiswa.data.rw,
          postal_code_num: detailsiswa && detailsiswa.data.postal_code_num,
          handphone: detailsiswa && detailsiswa.data.handphone,
          is_handphone_null: detailsiswa && detailsiswa.data.is_handphone_null,
          email: detailsiswa && detailsiswa.data.email,
          m_residence_distance_id:
            detailsiswa && detailsiswa.data.m_residence_distance_id,
          m_interval_time_id:
            detailsiswa && detailsiswa.data.m_interval_time_id,
          m_transportation_id:
            detailsiswa && detailsiswa.data.m_transportation_id,
          is_kip_sync: detailsiswa && detailsiswa.data.is_kip_sync,
          upload_kip: detailsiswa && detailsiswa.data.upload_kip,
          m_fund_source_id: detailsiswa && detailsiswa.data.m_fund_source_id,
          m_special_need_id: detailsiswa && detailsiswa.data.m_special_need_id,
          m_disability_id: detailsiswa && detailsiswa.data.m_disability_id,
          entered_tk_ra: detailsiswa && detailsiswa.data.entered_tk_ra,
          entered_paud: detailsiswa && detailsiswa.data.entered_paud,
          latitude: detailsiswa && detailsiswa.data.latitude,
          longitude: detailsiswa && detailsiswa.data.longitude,
          kk_num: detailsiswa && detailsiswa.data.kk_num,
          family_head_name: detailsiswa && detailsiswa.data.family_head_name,
          admission_date: detailsiswa && detailsiswa.data.admission_date,
          vaccine_type_ids: detailsiswa && detailsiswa.data.vaccine_type_ids,
          pusdatin_sync_date:
            detailsiswa && detailsiswa.data.pusdatin_sync_date,
          integration_id: detailsiswa && detailsiswa.data.integration_id,
          siswa_id: detailsiswa && detailsiswa.data.siswa_id,
          pusdatin_update_date:
            detailsiswa && detailsiswa.data.pusdatin_update_date,
          created_by: detailsiswa && detailsiswa.data.created_by,
          updated_by: detailsiswa && detailsiswa.data.updated_by,
          created_from_id: detailsiswa && detailsiswa.data.created_from_id,
          updated_from_id: detailsiswa && detailsiswa.data.updated_from_id,
          created_at: detailsiswa && detailsiswa.data.created_at,
          updated_at: detailsiswa && detailsiswa.data.updated_at,
          deleted_at: detailsiswa && detailsiswa.data.deleted_at,
          draft_history_id: detailsiswa && detailsiswa.data.draft_history_id,
          upload_kk: detailsiswa && detailsiswa.data.upload_kk,
          upload_photo: detailsiswa && detailsiswa.data.upload_photo,
          nationality: detailsiswa && detailsiswa.data.nationality,
          country_of_origin: detailsiswa && detailsiswa.data.country_of_origin,
          kitas: detailsiswa && detailsiswa.data.kitas,
          have_nik: detailsiswa && detailsiswa.data.have_nik,
          have_nisn: detailsiswa && detailsiswa.data.have_nisn,
          have_handphone: detailsiswa && detailsiswa.data.have_handphone,
          dukcapil_verification_status_id:
            detailsiswa && detailsiswa.data.dukcapil_verification_status_id,
          dukcapil_verification_date:
            detailsiswa && detailsiswa.data.dukcapil_verification_date,
          pkh_family: detailsiswa && detailsiswa.data.pkh_family,
          bpnt_family: detailsiswa && detailsiswa.data.bpnt_family,
          pbi_member: detailsiswa && detailsiswa.data.pbi_member,
          dtks_is_active: detailsiswa && detailsiswa.data.dtks_is_active,
          pkh_is_active: detailsiswa && detailsiswa.data.pkh_is_active,
          sk_period: detailsiswa && detailsiswa.data.sk_period,
          valid_nisn: detailsiswa && detailsiswa.data.valid_nisn,
          last_sync_date_kemensos:
            detailsiswa && detailsiswa.data.last_sync_date_kemensos,
          is_draftable: detailsiswa && detailsiswa.data.is_draftable,
          gender: detailsiswa && detailsiswa.data.gender,
          institution: detailsiswa && detailsiswa.data.institution,
          full_address: detailsiswa && detailsiswa.data.full_address,
          student_vaccine_types: [
            detailsiswa && detailsiswa.data.student_vaccine_types,
          ],
          student_disabilities: [
            detailsiswa && detailsiswa.data.student_disabilities.id,
            detailsiswa && detailsiswa.data.student_disabilities.name,
          ],
          scholarships: [detailsiswa && detailsiswa.data.scholarships],
          achievements: [detailsiswa && detailsiswa.data.achievements],
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }

    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Berhasil",
      text: "Data siswa berhasil ditarik!",
    });

    setLoad(true);

    await listSiswa();
    setLoad(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      await listSiswa();
      setLoad(false);
    };

    fetchData();
  }, []);

  return (
    <div className="card-body">
      <BlockUIku blocking={loading} progress={progress}  />
      <div class="btn-group">
        {loading === true ? (
          <button className="btn btn-danger" type="button" disabled>
            <span
              className={`${
                skeleton ? "spinner-border spinner-border-sm" : ""
              }`}
            ></span>
            <span className="text-dark"> Loading...</span>
          </button>
        ) : (
          <button className="btn btn-primary" onClick={getSiswa} type="button">
            Tarik Data SIswa
          </button>
        )}

        {loading === true ? (
          <button className="btn btn-danger" type="button" disabled>
            <span
              className={`${
                skeleton ? "spinner-border spinner-border-sm" : ""
              }`}
            ></span>
            <span className="text-dark"> Loading...</span>
          </button>
        ) : (
          paket === "3" && (
            <button
              className="btn btn-success"
              onClick={downloadCard}
              type="button"
            >
              Cetak Kartu Login
            </button>
          )
        )}
        {/* <button type="button" class="btn btn-warning">
          Download Data
        </button> */}
      </div>

      <div className="row">
        <div className="table-responsive table-striped">
          {load ? (
            <div className="text-center py-5">
              <p>Loading...</p>
            </div>
          ) : error ? (
            <div className="text-center py-5">
              <p className="text-danger">{error}</p>
            </div>
          ) : filteredData.length > 0 ? (
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={10}
              subHeader
              subHeaderComponent={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="form-control"
                    style={{ width: "200px" }} // Menyesuaikan ukuran input
                  />
                </div>
              }
            />
          ) : (
            <div>Data tidak ditemukan</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Siswa;
