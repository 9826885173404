import React from "react";
import KitabTemplates from "../components/kitab/KitabTemplates";
import Layouts from "./Layouts";

const Kitab = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <KitabTemplates url={url} />
      </Layouts>
    </div>
  );
};

export default Kitab;
