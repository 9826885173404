import React from "react";
import PengelolaanBuduk from "../components/buduk/PengelolaanBuduk";
import Layouts from "./Layouts";

const KelolaBuduk = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <PengelolaanBuduk url={url} />
      </Layouts>
    </div>
  );
};

export default KelolaBuduk;
