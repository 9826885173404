import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const DetailSiswa = ({ url }) => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const URL = url;
  const [loading, setLoading] = useState(false);

  const [agama, setAgama] = useState("");
  const [cita, setCita] = useState("");
  const [hobi, setHobi] = useState("");
  const [biaya, setBiaya] = useState("");
  const [statusAyah, setStatusAyah] = useState("");
  const [pendidikanAyah, setPendidikanAyah] = useState("");
  const [pekerjaanAyah, setPekerjaanAyah] = useState("");
  const [penghasilanAyah, setPenghasilanAyah] = useState("");
  const [statusIbu, setStatusIbu] = useState("");
  const [pendidikanIbu, setPendidikanIbu] = useState("");
  const [pekerjaanIbu, setPekerjaanIbu] = useState("");
  const [penghasilanIbu, setPenghasilanIbu] = useState("");

  const [alamatAyah, setAlamatAyah] = useState("");
  const [provinsiAyah, setProvinsiAyah] = useState("");
  const [kotaAyah, setKotaAyah] = useState("");
  const [kecamatanAyah, setKecAyah] = useState("");
  const [kelurahanAyah, setDesAyah] = useState("");

  const [alamatIbu, setAlamatIbu] = useState("");
  const [provinsiIbu, setProvinsiIbu] = useState("");
  const [kotaIbu, setKotaIbu] = useState("");
  const [kecamatanIbu, setKecIbu] = useState("");
  const [kelurahanIbu, setDesIbu] = useState("");

  const listSiswaById = async (e) => {
    const token = localStorage.getItem("token");

    try {
      const getSiswa = await axios.post(
        URL + "listsiswa_byid",
        {
          uuid: id,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (getSiswa.data.status === true) {
        setData(getSiswa.data.data[0]);
      } else {
        setError(getSiswa.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("Data tidak ditemukan, Silahkan tarik data terlebih dahulu !");
      } else {
        setError("Terjadi kesalahan saat mengambil data");
      }
    }
  };

  const getAgamaSiswa = async () => {
    const token = localStorage.getItem("token");
    try {
      const getAgama = await axios.get(
        URL + "agama_by_id/" + `${data.m_religion_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getAgama.data && getAgama.data.data) {
        setAgama(getAgama.data.data.deskripsi);
      } else {
        setAgama("");
      }
    } catch (error) {
      setAgama("");
    }
  };

  const getCitaSiswa = async () => {
    const token = localStorage.getItem("token");
    try {
      const getCita = await axios.get(
        URL + "cita_by_id/" + `${data.m_life_goal_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getCita.data && getCita.data.data) {
        setCita(getCita.data.data.cita_deskripsi);
      } else {
        setCita("");
      }
    } catch (error) {
      setCita("");
    }
  };

  const getHobiSiswa = async () => {
    const token = localStorage.getItem("token");
    try {
      const getHobi = await axios.get(
        URL + "hobi_by_id/" + `${data.m_hobby_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getHobi.data && getHobi.data.data) {
        setHobi(getHobi.data.data.hobi_deskripsi);
      } else {
        setHobi("");
      }
    } catch (error) {
      setHobi("");
    }
  };

  const getBiayaSiswa = async () => {
    const token = localStorage.getItem("token");
    try {
      const getBiaya = await axios.get(
        URL + "biaya_by_id/" + `${data.m_fund_source_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getBiaya.data && getBiaya.data.data) {
        setBiaya(getBiaya.data.data.deskripsi);
      } else {
        setBiaya("");
      }
    } catch (error) {
      setBiaya("");
    }
  };

  // AYAH

  const getStatusAyah = async () => {
    const token = localStorage.getItem("token");
    try {
      const getStatus = await axios.get(
        URL + "status_orang_tua_by_id/" + `${data.father_m_life_status_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getStatus.data && getStatus.data.data) {
        setStatusAyah(getStatus.data.data.deskripsi);
      } else {
        setStatusAyah("");
      }
    } catch (error) {
      setStatusAyah("");
    }
  };

  const getPendidikanAyah = async () => {
    const token = localStorage.getItem("token");
    try {
      const getPendidikan = await axios.get(
        URL + "pendidikan_by_id/" + `${data.father_m_last_education_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getPendidikan.data && getPendidikan.data.data) {
        setPendidikanAyah(getPendidikan.data.data.deskripsi);
      } else {
        setPendidikanAyah("");
      }
    } catch (error) {
      setPendidikanAyah("");
    }
  };

  const getPekerjaanAyah = async () => {
    const token = localStorage.getItem("token");
    try {
      const getPekerjaan = await axios.get(
        URL + "pekerjaan_by_id/" + `${data.father_m_job_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getPekerjaan.data && getPekerjaan.data.data) {
        setPekerjaanAyah(getPekerjaan.data.data.deskripsi);
      } else {
        setPekerjaanAyah("");
      }
    } catch (error) {
      setPekerjaanAyah("");
    }
  };

  const getPenghasilanAyah = async () => {
    const token = localStorage.getItem("token");
    try {
      const getPenghasilan = await axios.get(
        URL +
          "penghasilan_by_id/" +
          `${data.father_m_average_income_per_month_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getPenghasilan.data && getPenghasilan.data.data) {
        setPenghasilanAyah(getPenghasilan.data.data.deskripsi);
      } else {
        setPenghasilanAyah("");
      }
    } catch (error) {
      setPenghasilanAyah("");
    }
  };

  // IBU

  const getStatusIbu = async () => {
    const token = localStorage.getItem("token");
    try {
      const getStatus = await axios.get(
        URL + "status_orang_tua_by_id/" + `${data.mother_m_life_status_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getStatus.data && getStatus.data.data) {
        setStatusIbu(getStatus.data.data.deskripsi);
      } else {
        setStatusIbu("");
      }
    } catch (error) {
      setStatusIbu("");
    }
  };

  const getPendidikanIbu = async () => {
    const token = localStorage.getItem("token");
    try {
      const getPendidikan = await axios.get(
        URL + "pendidikan_by_id/" + `${data.mother_m_last_education_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getPendidikan.data && getPendidikan.data.data) {
        setPendidikanIbu(getPendidikan.data.data.deskripsi);
      } else {
        setPendidikanIbu("");
      }
    } catch (error) {
      setPendidikanIbu("");
    }
  };

  const getPekerjaanIbu = async () => {
    const token = localStorage.getItem("token");
    try {
      const getPekerjaan = await axios.get(
        URL + "pekerjaan_by_id/" + `${data.mother_m_job_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getPekerjaan.data && getPekerjaan.data.data) {
        setPekerjaanIbu(getPekerjaan.data.data.deskripsi);
      } else {
        setPekerjaanIbu("");
      }
    } catch (error) {
      setPekerjaanIbu("");
    }
  };

  const getPenghasilanIbu = async () => {
    const token = localStorage.getItem("token");
    try {
      const getPenghasilan = await axios.get(
        URL +
          "penghasilan_by_id/" +
          `${data.mother_m_average_income_per_month_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getPenghasilan.data && getPenghasilan.data.data) {
        setPenghasilanIbu(getPenghasilan.data.data.deskripsi);
      } else {
        setPenghasilanIbu("");
      }
    } catch (error) {
      setPenghasilanIbu("");
    }
  };

  // ALAMAT AYAH
  const getAlamatAyah = async () => {
    const token = localStorage.getItem("token");
    try {
      const getAlamat = await axios.get(
        URL + "residence_ortu_by_id/" + `${data.father_m_residence_status_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getAlamat.data && getAlamat.data.data) {
        setAlamatAyah(getAlamat.data.data.deskripsi);
      } else {
        setAlamatAyah("");
      }
    } catch (error) {
      setAlamatAyah("");
    }
  };

  const getProvinsiAyah = async () => {
    const token = localStorage.getItem("token");
    try {
      const getProvinsi = await axios.get(
        URL + "provinsi_by_id/" + `${data.father_m_province_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getProvinsi.data && getProvinsi.data.data) {
        setProvinsiAyah(getProvinsi.data.data.nama);
      } else {
        setProvinsiAyah("");
      }
    } catch (error) {
      setProvinsiAyah("");
    }
  };

  function formatCityId(cityId) {
    // return cityId.replace(/(.{2})/g, "$1.");
    return cityId
      .match(/.{1,2}/g)
      .join(".")
      .replace(/\.$/, "");
  }

  const getKotaAyah = async () => {
    const token = localStorage.getItem("token");
    try {
      const formattedCityId = formatCityId(data.father_m_city_id);
      const getKota = await axios.get(
        URL + "kabupaten_by_id/" + `${formattedCityId}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getKota.data && getKota.data.data) {
        setKotaAyah(getKota.data.data.nama);
      } else {
        setKotaAyah("");
      }
    } catch (error) {
      setKotaAyah("");
    }
  };

  function formatKecId(kecId) {
    return kecId
      .match(/.{1,2}/g)
      .join(".")
      .replace(/\.$/, "");
  }

  const getKecAyah = async () => {
    const token = localStorage.getItem("token");
    try {
      const formattedKecId = formatKecId(data.father_m_district_id);
      const getKec = await axios.get(
        URL + "kecamatan_by_id/" + `${formattedKecId}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getKec.data && getKec.data.data) {
        setKecAyah(getKec.data.data.nama);
      } else {
        setKecAyah("");
      }
    } catch (error) {
      setKecAyah("");
    }
  };

  function formatDesId(desId) {
    return (
      desId.slice(0, 2) +
      "." +
      desId.slice(2, 4) +
      "." +
      desId.slice(4, 6) +
      "." +
      desId.slice(6)
    );
  }

  const getDesAyah = async () => {
    const token = localStorage.getItem("token");
    try {
      const formattedDesId = formatDesId(data.father_m_sub_district_id);
      const getDes = await axios.get(
        URL + "desa_by_id/" + `${formattedDesId}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getDes.data && getDes.data.data) {
        setDesAyah(getDes.data.data.nama);
      } else {
        setDesAyah("");
      }
    } catch (error) {
      setDesAyah("");
    }
  };

  // ALAMAT IBU
  const getAlamatIbu = async () => {
    const token = localStorage.getItem("token");
    try {
      const getAlamat = await axios.get(
        URL + "residence_ortu_by_id/" + `${data.mother_m_residence_status_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getAlamat.data && getAlamat.data.data) {
        setAlamatIbu(getAlamat.data.data.deskripsi);
      } else {
        setAlamatIbu("");
      }
    } catch (error) {
      setAlamatIbu("");
    }
  };

  const getProvinsiIbu = async () => {
    const token = localStorage.getItem("token");
    try {
      const getProvinsi = await axios.get(
        URL + "provinsi_by_id/" + `${data.mother_m_province_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getProvinsi.data && getProvinsi.data.data) {
        setProvinsiIbu(getProvinsi.data.data.nama);
      } else {
        setProvinsiIbu("");
      }
    } catch (error) {
      setProvinsiIbu("");
    }
  };

  function formatCityIdIbu(cityId) {
    // return cityId.replace(/(.{2})/g, "$1.");
    return cityId
      .match(/.{1,2}/g)
      .join(".")
      .replace(/\.$/, "");
  }

  const getKotaIbu = async () => {
    const token = localStorage.getItem("token");
    try {
      const formattedCityIdIbu = formatCityIdIbu(data.mother_m_city_id);
      const getKota = await axios.get(
        URL + "kabupaten_by_id/" + `${formattedCityIdIbu}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getKota.data && getKota.data.data) {
        setKotaIbu(getKota.data.data.nama);
      } else {
        setKotaIbu("");
      }
    } catch (error) {
      setKotaIbu("");
    }
  };

  function formatKecIdIbu(kecId) {
    return kecId
      .match(/.{1,2}/g)
      .join(".")
      .replace(/\.$/, "");
  }

  const getKecIbu = async () => {
    const token = localStorage.getItem("token");
    try {
      const formattedKecIdIbu = formatKecIdIbu(data.mother_m_district_id);
      const getKec = await axios.get(
        URL + "kecamatan_by_id/" + `${formattedKecIdIbu}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getKec.data && getKec.data.data) {
        setKecIbu(getKec.data.data.nama);
      } else {
        setKecIbu("");
      }
    } catch (error) {
      setKecIbu("");
    }
  };

  function formatDesIdIbu(desId) {
    return (
      desId.slice(0, 2) +
      "." +
      desId.slice(2, 4) +
      "." +
      desId.slice(4, 6) +
      "." +
      desId.slice(6)
    );
  }

  const getDesIbu = async () => {
    const token = localStorage.getItem("token");
    try {
      const formattedDesIdIbu = formatDesIdIbu(data.mother_m_sub_district_id);
      const getDes = await axios.get(
        URL + "desa_by_id/" + `${formattedDesIdIbu}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getDes.data && getDes.data.data) {
        setDesIbu(getDes.data.data.nama);
      } else {
        setDesIbu("");
      }
    } catch (error) {
      setDesIbu("");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await listSiswaById();
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        getAgamaSiswa(),
        getCitaSiswa(),
        getHobiSiswa(),
        getBiayaSiswa(),
        getStatusAyah(),
        getPendidikanAyah(),
        getPekerjaanAyah(),
        getPenghasilanAyah(),
        getStatusIbu(),
        getPendidikanIbu(),
        getPekerjaanIbu(),
        getPenghasilanIbu(),
        getAlamatAyah(),
        getProvinsiAyah(),
        getKotaAyah(),
        getKecAyah(),
        getDesAyah(),
        getAlamatIbu(),
        getProvinsiIbu(),
        getKotaIbu(),
        getKecIbu(),
        getDesIbu(),
      ]);
      setLoading(false);
    };
    if (data) {
      fetchData();
    }
  }, [data]);

  return (
    <div>
      <h5>Detail Siswa</h5>

      <div className="card">
        <div className="card-body">
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  href="#data-umum"
                  data-bs-toggle="tab"
                >
                  Identitas Siswa
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#data-ortu" data-bs-toggle="tab">
                  Data Orang Tua
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#data-alamat"
                  data-bs-toggle="tab"
                >
                  Data Alamat
                </a>
              </li>
            </ul>
          )}

          {!loading && (
            <div className="tab-content">
              <div className="tab-pane show active" id="data-umum">
                <div className="row mt-5">
                  <div className="col-12">
                    <h5 className="form-title">
                      <span>Identitas Siswa</span>
                    </h5>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Nama Lengkap <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.full_name}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        NISN <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.nisn}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        NIS Lokal <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.local_nis}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Kewarganegaraan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.nationality}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        NIK <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.nik}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Tempat Lahir <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.birth_place}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Tanggal Lahir <span className="login-danger"></span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={data && data.birth_date}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Jenis Kelamin <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.gender}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Jumlah Saudara <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.siblings_num}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Anak Ke- <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.child_of_num}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Agama <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={agama && agama}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Cita-cita <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={cita && cita}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Hobi <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={hobi && hobi}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="input-group local-forms">
                      <span className="input-group-text">
                        <input
                          type="checkbox"
                          checked={data && data.is_handphone_null === "1"}
                        />
                      </span>
                      <span className="login-danger">
                        Tidak Memiliki handphone
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        No Handphone <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.handphone}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Yang Membiayai Sekolah{" "}
                        <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={biaya && biaya}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="input-group local-forms">
                      <span className="input-group-text">
                        <input
                          type="checkbox"
                          checked={data && data.entered_tk_ra === "1"}
                        />
                      </span>
                      <span>Pernah TK</span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="input-group local-forms">
                      <span className="input-group-text">
                        <input
                          type="checkbox"
                          checked={data && data.entered_paud === "1"}
                        />
                      </span>
                      <span>Pernah PAUD</span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        No KIP <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.nomor_kip}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        No KK <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.kk_num}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Nama KK <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.family_head_name}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="data-ortu">
                <div className="row mt-5">
                  <div className="col-12">
                    <h5 className="form-title">
                      <span>Ayah Kandung</span>
                    </h5>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Nama Ayah <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.father_full_name}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Status Ayah <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={statusAyah && statusAyah}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Kewarganegaraan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.father_nationality}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        NIK <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.father_nik}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Tempat Lahir <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.father_birth_place}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Tanggal Lahir <span className="login-danger"></span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={data && data.father_birth_date}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Pendidikan Terakhir{" "}
                        <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={pendidikanAyah && pendidikanAyah}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Pekerjaan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={pekerjaanAyah && pekerjaanAyah}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Penghasilan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={penghasilanAyah && penghasilanAyah}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="input-group local-forms">
                      <span className="input-group-text">
                        <input
                          type="checkbox"
                          checked={data && data.is_father_phone_null === "1"}
                        />
                      </span>
                      <span className="login-danger">
                        Tidak Memiliki handphone
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        No Handphone <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.father_phone_number}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row mt-5">
                  <div className="col-12">
                    <h5 className="form-title">
                      <span>Ibu Kandung</span>
                    </h5>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Nama Ibu <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.mother_full_name}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Status Ibu <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={statusIbu && statusIbu}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Kewarganegaraan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.mother_nationality}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        NIK <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.mother_nik}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Tempat Lahir <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.mother_birth_place}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Tanggal Lahir <span className="login-danger"></span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={data && data.mother_birth_date}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Pendidikan Terakhir{" "}
                        <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={pendidikanIbu && pendidikanIbu}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Pekerjaan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={pekerjaanIbu && pekerjaanIbu}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Penghasilan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={penghasilanIbu && penghasilanIbu}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="input-group local-forms">
                      <span className="input-group-text">
                        <input
                          type="checkbox"
                          checked={data && data.is_mother_phone_null === "1"}
                        />
                      </span>
                      <span className="login-danger">
                        Tidak Memiliki handphone
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        No Handphone <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.mother_phone_number}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="input-group local-forms">
                      <span className="input-group-text">
                        <input
                          type="checkbox"
                          checked={data && data.is_kk_same_father === "1"}
                        />
                      </span>
                      <span className="login-danger">
                        KK Sama dengan Ayah Kandung
                      </span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row mt-5">
                  <div className="col-12">
                    <h5 className="form-title">
                      <span>Wali</span>
                    </h5>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Wali <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.wali}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Nama Wali <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          data &&
                          (data.wali === "Sama dengan ayah kandung"
                            ? data.father_full_name
                            : data.mother_full_name)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Status Ibu <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          data &&
                          (data.wali === "Sama dengan ayah kandung"
                            ? data.father_status
                            : data.mother_status)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Kewarganegaraan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          data &&
                          (data.wali === "Sama dengan ayah kandung"
                            ? data.father_nationality
                            : data.mother_nationality)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        NIK <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          data &&
                          (data.wali === "Sama dengan ayah kandung"
                            ? data.father_nik
                            : data.mother_nik)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Tempat Lahir <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          data &&
                          (data.wali === "Sama dengan ayah kandung"
                            ? data.father_birth_place
                            : data.mother_birth_place)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Tanggal Lahir <span className="login-danger"></span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={
                          data &&
                          (data.wali === "Sama dengan ayah kandung"
                            ? data.father_birth_date
                            : data.mother_birth_date)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Pendidikan Terakhir{" "}
                        <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          data &&
                          (data.wali === "Sama dengan ayah kandung"
                            ? pendidikanAyah
                            : pendidikanIbu)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Pekerjaan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          data &&
                          (data.wali === "Sama dengan ayah kandung"
                            ? pekerjaanAyah
                            : pekerjaanIbu)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Penghasilan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          data &&
                          (data.wali === "Sama dengan ayah kandung"
                            ? penghasilanAyah
                            : penghasilanIbu)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="input-group local-forms">
                      <span className="input-group-text">
                        <input
                          type="checkbox"
                          checked={
                            data &&
                            (data.wali === "Sama dengan ayah kandung"
                              ? data.is_father_phone_null === "1"
                              : data.is_mother_phone_null === "1")
                          }
                        />
                      </span>
                      <span className="login-danger">
                        Tidak Memiliki handphone
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        No Handphone <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          data &&
                          (data.wali === "Sama dengan ayah kandung"
                            ? data.father_phone_number
                            : data.mother_phone_number)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="data-alamat">
                {/* AYAH KANDUNG */}
                <div className="row mt-3">
                  <div className="col-12">
                    <h5 className="form-title">
                      <span>Ayah Kandung</span>
                    </h5>
                  </div>

                  <div className="col-12 col-sm-4">
                    <div className="input-group local-forms">
                      <span className="input-group-text">
                        <input
                          type="checkbox"
                          checked={data && data.father_nationality === "wna"}
                        />
                      </span>
                      <span className="login-danger">
                        Tinggal diluar Negeri
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Status Kepemilikan Rumah{" "}
                        <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={alamatAyah && alamatAyah}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Provinsi <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={provinsiAyah && provinsiAyah}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Kabupaten / Kota <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={kotaAyah && kotaAyah}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Kecamatan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={kecamatanAyah && kecamatanAyah}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Desa / Kelurahan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={kelurahanAyah && kelurahanAyah}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        RT <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.father_rt}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        RW <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.father_rw}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Kodepos <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.father_postal_code}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12">
                    <div className="form-group local-forms">
                      <label>
                        Alamat <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ height: "50px" }}
                        value={data && data.father_address}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                {/* IBU KANDUNG */}
                <div className="row mt-3">
                  <div className="col-12">
                    <h5 className="form-title">
                      <span>Ibu Kandung</span>
                    </h5>
                  </div>

                  <div className="col-12 col-sm-4">
                    <div className="input-group local-forms">
                      <span className="input-group-text">
                        <input
                          type="checkbox"
                          checked={data && data.mother_nationality === "wna"}
                        />
                      </span>
                      <span className="login-danger">
                        Tinggal diluar Negeri
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Status Kepemilikan Rumah{" "}
                        <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={alamatIbu && alamatIbu}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Provinsi <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={provinsiIbu && provinsiIbu}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Kabupaten / Kota <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={kotaIbu && kotaIbu}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Kecamatan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={kecamatanIbu && kecamatanIbu}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Desa / Kelurahan <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={kelurahanIbu && kelurahanIbu}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        RT <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.mother_rt}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        RW <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.mother_rw}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group local-forms">
                      <label>
                        Kodepos <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data && data.mother_postal_code}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12">
                    <div className="form-group local-forms">
                      <label>
                        Alamat <span className="login-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ height: "50px" }}
                        value={data && data.mother_address}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailSiswa;
