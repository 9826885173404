import React from "react";

const Guru = () => {
  return (
    <div>
      <div class="btn-group">
        <button type="button" class="btn btn-danger">
          Left
        </button>
        <button type="button" class="btn btn-warning">
          Middle
        </button>
        <button type="button" class="btn btn-info">
          Right
        </button>
      </div>
      <h4 className="text-center mt-3">Comingsoon....</h4>
    </div>
  );
};

export default Guru;
