import React, { useEffect, useState } from "react";
import Layouts from "./Layouts";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe, LoginUserEmis, ValidasiToken } from "../features/authSlice";
import axios from "axios";
import Members from "../components/Members";

const ListMember = ({ url }) => {
  const URL = url.url;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  const [isiPackage, setIsiPackage] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LoginUserEmis());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ValidasiToken());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  return (
    <Layouts url={url}>
      <Members url={url} />
    </Layouts>
  );
};

export default ListMember;
