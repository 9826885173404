import React from "react";
import PriceList from "./Pricelist";

const Paket = ({ isiPaket, loading }) => {
  return (
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row">
          <div className="col">
            <h3 className="page-title">List Paket</h3>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <h4>Tersedia berbagai pilihan paket fitur sesuai kebutuhan Anda</h4>
          <div className="alert alert-warning">
            <i className="feather-info"></i> <strong>Informasi</strong>
            <p className="text-justify text-center">
              <b>
                PROMO UJI COBA
                <br />
                Segera order sekarang!!. <br />
                Masa aktif sampai 31 Desember 2024 Bulan Juli ( Masuk ajaran
                baru biaya naik 😜 ) <br />
                Silahkan melakukan pembayaran melalui transfer ke <br />
                BCA : 3660056881 a.n LUTFI JAYA HARIANTO <br />
                atau <br /> DANA 081222374440 a.n LUTFI JAYA HARIANTO <br />{" "}
                kirimkan bukti transfer melalui WA ke 081222374440{" "}
              </b>
            </p>
          </div>
          <div className="row">
            {isiPaket &&
              isiPaket.map((paket, index) => (
                <PriceList
                  key={index}
                  title={paket.title}
                  warna={paket.warna}
                  harga={paket.harga}
                  description={paket.description}
                  feature={paket.feature}
                  loading={loading}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paket;
