import React, { useEffect, useState } from "react";
import Layouts from "./Layouts";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe, LoginUserEmis, ValidasiToken } from "../features/authSlice";
import axios from "axios";
import Paket from "../components/paket/Paket";

const Package = ({ url }) => {
  const URL = url;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  const [isiPackage, setIsiPackage] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPaket = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const response = await axios.get(URL + "package", {
      headers: { Authorization: `Bearer ${token}` },
    });

    setIsiPackage(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getPaket();
  }, []);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LoginUserEmis());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ValidasiToken());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  return (
    <Layouts url={url}>
      <Paket isiPaket={isiPackage} loading={loading} />
    </Layouts>
  );
};

export default Package;
