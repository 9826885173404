import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Swal from 'sweetalert2';

const Mutasi = ({ url }) => {
	const [ cari, setCari ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ skeleton, setSkeleton ] = useState('');
	const [ data, setData ] = useState([]);
	const [ showDetail, setShowDetail ] = useState([]); // Change initial state to null
	const [ selectedItem, setSelectedItem ] = useState(null);
	const [ modalIsOpen, setModalIsOpen ] = useState(false);

	const [ tanggal, setTanggal ] = useState('');
	const [ nomor, setNomor ] = useState('');
	const [ kelas, setKelas ] = useState('');
	const [ alasan, setAlasan ] = useState('');
	const [ idsiswa, setIdSiswa ] = useState('');

	const search = async (e) => {
		e.preventDefault();
		setLoading(true);
		const token = localStorage.getItem('token');
		try {
			const response = await axios.post(
				`${url}searchsiswabuduk`,
				{
					nsm: localStorage.getItem('nsm'),
					query: cari
				},
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);
			console.log('Response data:', response.data); // Debugging line
			// Ensure response.data is an array
			setData(Array.isArray(response.data.data) ? response.data.data : []);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		setLoading(false);
	};

	const detail = (item) => {
		setShowDetail([]);
		console.log('Detail item:', item); // Debugging line
		setShowDetail(item.kelas ? item.kelas : []); // Set the selected item's kelas array
	};

	const mutasi = (item) => {
		console.log('Mutasi item:', item); // Debugging line
		setSelectedItem(item); // Set the selected item
		setKelas(item.name);
		setIdSiswa(item.student_id);
		setModalIsOpen(true); // Open the modal
	};

	const SimpanMutasi = () => {
		const token = localStorage.getItem('token');
		const response = axios.post(
			`${url}simpanmutasi`,
			{
				nsm: localStorage.getItem('nsm'),
				id_siswa: idsiswa,
				tgl_mutasi: tanggal,
				no_surat_mutasi: nomor,
				kelas: kelas,
				alasan: alasan
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);
		console.log('Response data:', response.data);
		setModalIsOpen(false);
		setTanggal('');
		setNomor('');
		setKelas('');
		setAlasan('');
		setSelectedItem(null);
		setShowDetail([]);
		setData([]);
		setCari('');
		setLoading(false);
		setSkeleton(false);
		Swal.fire({ title: 'Berhasil', text: 'Data berhasil disimpan', icon: 'success' });
	};

	return (
		<div>
			<h4>Mutasi</h4>
			<div className="row">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<div className="col-md-6">
								<form>
									<label>Masukkan Nama Siswa / NISN</label>
									<div className="input-group">
										<input
											className="form-control"
											type="text"
											required
											value={cari}
											onChange={(e) => setCari(e.target.value)}
										/>
										{loading === true ? (
											<button className="btn btn-danger me-2" type="button" disabled>
												<span
													className={`${skeleton ? 'spinner-border spinner-border-sm' : ''}`}
												/>
												<span className="text-dark"> Loading...</span>
											</button>
										) : (
											<button className="btn btn-primary me-2" type="submit" onClick={search}>
												Cari Siswa
											</button>
										)}
									</div>
								</form>
							</div>
							<div className="col-md-12 mt-3">
								<table className="table table-bordered table-striped">
									<thead>
										<tr>
											<th>No</th>
											<th>Nama Siswa</th>
											<th>NISN</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{data.map((item, index) => (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{item.siswa.full_name}</td>
												<td>{item.siswa.nisn}</td>
												<td>
													<button
														className="btn btn-warning btn-sm"
														onClick={() => detail(item)} // Pass the item to detail function
													>
														Pilih
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							{showDetail.length > 0 && ( // Check if showDetail has items
								<div className="col-md-12 mt-3">
									<div className="card">
										<div className="card-body">
											<table className="table table-bordered table-striped">
												<thead>
													<tr>
														<th>No</th>
														<th>Tahun Pelajaran / Semester</th>
														<th>Rombel</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{showDetail.map((item, index) => (
														<tr key={index}>
															<td>{index + 1}</td>
															<td>{item.academic_year.name}</td>
															<td>{item.name}</td>
															<td>
																<button
																	className="btn btn-danger btn-sm"
																	onClick={() => mutasi(item)}
																>
																	Proses Mutasi
																</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Mutasi</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						<div className="form-group">
							<label>Tanggal Surat Mutasi</label>
							<input
								type="date"
								className="form-control"
								placeholder=""
								value={tanggal}
								onChange={(e) => setTanggal(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label>Nomor Surat Mutasi</label>
							<input
								type="text"
								className="form-control"
								placeholder=""
								value={nomor}
								onChange={(e) => setNomor(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label>Kelas yang ditinggalkan</label>
							<input
								type="text"
								className="form-control"
								placeholder=""
								value={kelas}
								onChange={(e) => setKelas(e.target.value)}
								readOnly
							/>
						</div>
						<div className="form-group">
							<label>Alasan</label>
							<input
								type="text"
								className="form-control"
								placeholder=""
								value={alasan}
								onChange={(e) => setAlasan(e.target.value)}
							/>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setModalIsOpen(false)}>
						Close
					</Button>
					<Button variant="primary" onClick={() => SimpanMutasi()}>
						Simpan
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default Mutasi;
